import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorldNavigationTimerService {
  running: boolean = false;
  currentCount: number = 0;
  warnAt: number = 0;
  tickerInterval: any;
  warn: Subject<number> = new Subject();
  
  expired: Subject<boolean> = new Subject();
  countdown: Subject<number> = new Subject();
  initialCountValue: number = 0;

  constructor(){}

  start(countdownFrom: number = 30, warnAt: number = 10) {
      this.expired.next(false);
      this.running = true;
      this.currentCount = countdownFrom;
      this.countdown.next(this.currentCount);
      this.initialCountValue = countdownFrom;
      this.warnAt = warnAt;
      
      
      this.tickerInterval = setInterval(this.tick.bind(this), 1000);
  }

  clear() {
    clearInterval(this.tickerInterval);
    this.running = false;
    this.currentCount = this.initialCountValue;
  }

  restart() {
    this.expired.next(false);
    this.currentCount = this.initialCountValue;
  }

  tick() {
    if(this.currentCount <= this.warnAt)
    {
      this.warn.next(this.currentCount);
    }

    if(this.currentCount === 0)
    {
      this.expired.next(true);
      this.running = false;
      clearInterval(this.tickerInterval);
    }

    this.currentCount--;
    this.countdown.next(this.currentCount);
  }
}
