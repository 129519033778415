import { Task, DiagnosticTaskAdapter, InterventionTaskAdapter } from "./task.model";
import { Injectable } from "@angular/core";
import { Adapter } from "../adapter";

export class Curriculum {
  constructor(
    public unitNumber: number,
    public levels: any,
    public type: string,
    public tasks: Task[],
    public testType?: string,
    public destinationOrder?: string
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class CurriculumAdapter implements Adapter<Curriculum> {
  constructor(
    private diagnosticTaskAdapter: DiagnosticTaskAdapter,
    private interventionTaskAdapter: InterventionTaskAdapter
  ) {}

  adapt(item: any, fromRemote?: boolean): Curriculum {
    // We need to create our tasks array - this is a bit difficult currently as a curriculum is not specific to
    // either a Diagnostic or Intervention only (although in practice this seems to be the case)
    let tasks: Task[] = [] ;

    // OPTIMIZE: The incoming curriculum from the server specifies the curriculum tasks under the 'task' property name,
    //         : to me this makes more sense as an array property names 'tasks'. In fact, our Curriculum class here has
    //         : refactored it to be called 'tasks' on our Curriculum objects. However, there are multiple times in the
    //         : app that we need to 'adapt' a JSON curriculum object to a curriculum object, so we need to be able to
    //         : handle both cases where the incoming JSON curriculum object is from the server (with 'task'), and the
    //         : case where we are adapting from the session store (with 'tasks'). Ideally, we would optimize this by
    //         : updating all the curriculum to use 'tasks' instead of 'task'. MFS (BNI) - 07.28.21
    let origTasks = item['task'] || item['tasks'] ;
    if (item['type'] === 'intervention')
    {
      origTasks.forEach((taskObj: any) => {
        tasks.push(this.interventionTaskAdapter.adapt(taskObj, fromRemote)) ;
      }) ;
    }
    else if (item['type'] === 'diagnostic')
    {
      origTasks.forEach((taskObj: any) => {
        tasks.push(this.diagnosticTaskAdapter.adapt(taskObj, fromRemote)) ;
      }) ;
    }

    return new Curriculum(item['@number'], item['levels'], item['type'], tasks, item['@testType'], item['@destinationOrder']) ;
  }
}