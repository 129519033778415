import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'asset-loading-dialog',
  templateUrl: './asset-loading-dialog.component.html',
})
export class AssetLoadingDialogComponent implements OnInit {
  @Output() onLogoutButtonClick = new EventEmitter<null>() ;
  @Output() onRetryButtonClick = new EventEmitter<null>() ;

  constructor() {  }

  ngOnInit(): void {}

  logoutBtnDialogClick() {
    this.onLogoutButtonClick.emit() ;
  }

  retryBtnDialogClick() {
    this.onRetryButtonClick.emit() ;
  }
}
