<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element fill-in-blank-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div *ngIf="!isUnit" class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'" (click)="replayVideo()"
        [disabled]="disableAVButtons"></button>
    </div>
    <div [ngClass]="(isUnit) ? ['target-area-ftb-unit'] : ['word-to-complete']">
      <!-- tiles that spell the incomplete word will go in here
      Probably use ng-repeat to loop over letters in the target word-->
      <button class="speaker" (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons"></button>
      <div class="incorrect-row" *ngIf="isUnit">
        <div class="target-incorrect unit-fill"
            style="left: 1.3%;"
            [ngClass]="{ 'slash' : ($index===blankTile || $index===responseSilentEIndex), 'first-target-block-left-fill': (showTopRowSilentE) }"
            *ngFor="let letter of incorrectTop; index as $index" [hidden]="!showIncorrectTop">
          <div class="target-incorrect-text font-24">{{letter}}</div>
        </div>
      </div>
      <div class="incorrect-row" *ngIf="isUnit">
        <div class="target-incorrect unit-fill"
            [ngClass]="{ 'slash' : ($index===blankTile || $index===responseSilentEIndex), 'float-incorrect-top-up': (floatIncorrectUp && showIncorrectBottom), 'first-target-block-left-fill': (showBottomRowSilentE) }"
            *ngFor="let letter of incorrectBottom; index as $index" [hidden]="!showIncorrectBottom">
          <div class="target-incorrect-text font-24">{{letter}}</div>
        </div>
      </div>
      <div [ngClass]="{ 'target-row': isUnit, 'target-row-prepost': !isUnit }">
        <div [ngClass]="{'target-block' : isUnit, 'target-block-prepost' : !isUnit, 'first-target-block-left-fill' : (showTargetSilentE), 'prepost-fill': !isUnit, 'unit-fill': isUnit,
            'hover': (highlightCorrectResponse && ($index==blankTile || $index==targetSilentEIndex))}"
          *ngFor="let letter of targetWord; index as $index"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}">
          <button class="fib" [ngClass] = "{'target-letter-audio' : showIncorrectBottom, 'target-letter-audio-hidden' : !showIncorrectBottom}"
            *ngIf="showIncorrectBottom" (click)="playTargetLetterAudio($index)" [disabled]="disableAVButtons"></button>
          <button class="target-text font-30"
            [ngClass]="{'slash-target': showTargetSlash && ($index===blankTile || $index===responseSilentEIndex), 'float-incorrect-up': floatIncorrectUp}">
            {{letter}}
          </button>
        </div>
      </div>
    </div>
    <!-- response options on tiles will go in here (will need to separate into two sets for the different rows)
    Probably ng-repeat looping through the response options-->
    <div class="response-row">
      <div class="button-div" *ngFor="let response of responseOptions1; index as $index">
        <button class = "letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "letter-audio" *ngIf="showResponseAudioButtons" (click)="playLetterAudio($index)"
          [disabled]="disableAVButtons"></button>
        <button #respButton1 class="font-30"
          [ngClass]="{ 'hover': toggleHover($index), 'response-block-grayed': responsesDisabled,  'response-block': !responsesDisabled }"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="responsesDisabled"
          (click)="submitResponse($index); removeHoverClass(respButton1);"
          (touchstart)="addHoverClass(respButton1)" (touchend)="removeHoverClass(respButton1)"
          (mousemove)="addHoverClass(respButton1)" (mouseenter)="addHoverClass(respButton1)" (mouseleave)="removeHoverClass(respButton1)">
          {{response}}
        </button>
        <button class="font-24"
          [ngClass]="{ 'hover': toggleHover($index), 'silent-e-grayed': responsesDisabled, 'silent-e': !responsesDisabled }"
          *ngIf="silentTiles[$index]"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="responsesDisabled"
          (click)="submitResponse($index); removeHoverClass(respButton1);"
          (touchstart)="addHoverClass(respButton1)" (touchend)="removeHoverClass(respButton1)"
          (mousemove)="addHoverClass(respButton1)" (mouseenter)="addHoverClass(respButton1)" (mouseleave)="removeHoverClass(respButton1)">
          e
        </button>
        <button class="silent-e-hidden" *ngIf="!silentTiles[$index]"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"></button>
      </div>
    </div>
    <div class="response-row">
      <!-- use $index + 4 so we can keep the index returned unique because $index resets when we start a new ng-repeat -->
      <div class="button-div" *ngFor="let response of responseOptions2; index as $index">
        <button class = "letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "letter-audio" *ngIf="showResponseAudioButtons" (click)="playLetterAudio($index+4)"
          [disabled]="disableAVButtons"></button>
        <button #respButton2 class="font-30"
          [ngClass]="{ 'hover': toggleHover($index + 4), 'response-block-grayed': responsesDisabled,  'response-block': !responsesDisabled }"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="responsesDisabled"
          (click)="submitResponse($index+4); removeHoverClass(respButton2);"
          (touchstart)="addHoverClass(respButton2)" (touchend)="removeHoverClass(respButton2)"
          (mousemove)="addHoverClass(respButton2)" (mouseenter)="addHoverClass(respButton2)" (mouseleave)="removeHoverClass(respButton2)">
          {{response}}
        </button>
        <button class="font-24"
          [ngClass]="{ 'hover': toggleHover($index + 4), 'silent-e-grayed': responsesDisabled, 'silent-e': !responsesDisabled }"
          *ngIf="silentTiles[$index + 4]"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="responsesDisabled"
          (click)="submitResponse($index+4); removeHoverClass(respButton2);"
          (touchstart)="addHoverClass(respButton2)" (touchend)="removeHoverClass(respButton2)"
          (mousemove)="addHoverClass(respButton2)" (mouseenter)="addHoverClass(respButton2)" (mouseleave)="removeHoverClass(respButton2)">
          e
        </button>
        <button class="silent-e-hidden" *ngIf="!silentTiles[$index + 4]"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"></button>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>