import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms' ;
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login.service';
import { SharedModule } from '../shared/shared.module';
import { CleverComponent } from './clever/clever.component';
import { ClasslinkComponent } from './classlink/classlink.component';

@NgModule({
  declarations: [
    LoginComponent,
    CleverComponent,
    ClasslinkComponent,
  ],
  exports: [
    LoginComponent,
  ],
  providers: [
    LoginService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class LoginModule { }
