import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AudioPlayerService } from '../core/services/audio-player.service';
import { LoginService } from '../login/login.service';

const TIMEUP_AUDIO = 'Audio/Help/instructions_timeisup.mp3';
const ANIMATION_MILLIS = 2000;

@Component({
  selector: 'app-timeup',
  templateUrl: './timeup.component.html',
  animations: [
    trigger('hideTimeupText', [
      state('show', style({ opacity: 1, display: 'block' })),
      state('hide', style({ opacity: 0, display: 'none' })),
      transition('show => hide', [
        animate(ANIMATION_MILLIS)
      ]),
    ])
]
})
export class TimeupComponent implements OnInit, OnDestroy {
  hideTimeupText = false;

  private audioSubscription?: Subscription;
  private logoutSubscription?: Subscription;

  constructor(
    private audioPlayerService: AudioPlayerService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.audioSubscription = this.audioPlayerService.play(TIMEUP_AUDIO).subscribe({
      next: () => this.hideTimeupText = true,
      error: () => this.hideTimeupText = true
    });
  }

  ngOnDestroy(): void {
    this.audioSubscription?.unsubscribe();
    this.logoutSubscription?.unsubscribe();
  }

  hideTimeupTextDoneHandler(event: AnimationEvent): void {
    if (event.fromState !== 'show' || event.toState !== 'hide') return;
    this.logoutSubscription = this.loginService.studentLogout().subscribe();
  }
}
