import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms' ;
import { Router } from '@angular/router';
import { Capacitor } from "@capacitor/core";
import { environment } from 'src/environments/environment'
import { StudentData } from 'src/app/core/models/student-data.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { CurriculumService } from 'src/app/core/services/curriculum.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { SessionTimerService } from 'src/app/core/services/session-timer.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { ThemeParseService } from 'src/app/core/services/theme-parse.service';
import { BackgroundComponent } from 'src/app/shared/components/background/background.component';
import { LoginService } from '../login.service';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

enum AlertStatus {
  ERROR = 'error',
  INFO = 'info',
  HIDDEN = 'hidden',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  readonly AlertStatus = AlertStatus;

  @ViewChild('background') backgroundComponent!: BackgroundComponent;
  alertStatus: AlertStatus = AlertStatus.HIDDEN;
  loginButtonDisabled: boolean = false ;
  capsOn: boolean = false;
  result: string | null = null;
  studentData: StudentData | null = null;
  showUsage: boolean = false;
  showUsageLogout: boolean = false;
  showUsageLogin: boolean = false;
  loggedIn: boolean = false;
  showProviderButtons: boolean = false;
  restrictLogin: boolean = false ;
  appStoreLink: string = '' ;
  cleverOauth = 'https://clever.com/oauth/authorize?response_type=code&redirect_uri='
    + encodeURIComponent(environment.CLEVER_REDIRECT_URI) + '&client_id=' + environment.CLEVER_CLIENT_ID;
  classlinkOauth = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile,oneroster,full&redirect_uri=${encodeURIComponent(environment.CLASSLINK_REDIRECT_URI)}&client_id=${environment.CLASSLINK_CLIENT_ID}&response_type=code` ;

  private alreadyCompleteTimeout: any = null;
  private keyPressListener$: Observable<KeyboardEvent> ;
  private keyPressSubscription: Subscription | null = null ;

  // Toggled to true when we want to start the animation to go to the start screen
  goToStartAnimation: boolean = false;

  // Form elements
  loginForm = new FormGroup({
    username: new FormControl('', [ Validators.required ]),
    password: new FormControl('', [ Validators.required ]),
  }) ;


  constructor(
    private router: Router,
    private loginService: LoginService,
    private audioPlayerService: AudioPlayerService,
    private curriculumService: CurriculumService,
    private interventionTaskService: InterventionTaskService,
    private studentDataService: StudentDataService,
    private applicationStateService: ApplicationStateService,
    private sessionTimerService: SessionTimerService,
    private themeParseService: ThemeParseService,
  ) {
    this.keyPressListener$ = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
      filter((e: KeyboardEvent) => e.code === 'CapsLock'),
      distinctUntilChanged()
    ) ;
  }

  ngOnInit() {
    // Clear session storage
    this.applicationStateService.clear();

    // cancel Timers
    this.sessionTimerService.cancelTimers();
    if (!this.isBrowserSupported())
    {
      this.alertStatus = AlertStatus.ERROR;
      this.loginButtonDisabled = true ;
      this.result = "Your browser is not compatible. Please try a different browser.";
    }

    this.showProviderButtons = (Capacitor.getPlatform() === 'ios') ;
    this.keyPressSubscription = this.keyPressListener$.subscribe(() => {
      this.capsOn = (Capacitor.getPlatform() !== 'ios') && !this.capsOn ;
    }) ;

    /**
     * if determine this is a mac/ios - cannot rely on Safari b/c chrome os reports that
     * if determine this is mobile - multitouchpoints
     * if determine this is web - capacitor getplatform === 'web'
     * then do not show web login
     */
    this.restrictLogin = Capacitor.getPlatform() === 'web' && (window.navigator.maxTouchPoints > 1) && (window.navigator.userAgent.toLowerCase().indexOf('mac') > -1) ;
    this.appStoreLink = environment.appStoreLink ;
  };

  ngOnDestroy() {
    this.keyPressSubscription?.unsubscribe() ;
  }

  login() {
    if (this.alreadyCompleteTimeout)
    {
      clearTimeout(this.alreadyCompleteTimeout);
      this.alreadyCompleteTimeout = null;
    }
    this.loginButtonDisabled = true;
    let username: string = this.loginForm.controls['username'].value as string ;
    let password: string = this.loginForm.controls['password'].value as string;

    this.applicationStateService.setUsername(username);
    this.applicationStateService.setPassword(password);

    this.loginService.checkForUpdate().pipe(
      switchMap(updateRequired => {
        if (Capacitor.getPlatform() === 'ios') {
          console.debug('Skipping version check for iOS');
          // Skip the version check for iOS, return false so that we can make it through
          // the updateRequired filter in the next step and continue with the login process
          return of(false);
        }

        if (updateRequired)
        {
          // Reload the page to get the new version
          this.result = "A new version is available. Please login again...";
          this.alertStatus = AlertStatus.ERROR;
          setTimeout(() => {
              window.location.reload();
          }, 1500);
        }
        return of(updateRequired);
      }),
      filter(updateRequired => !updateRequired),
      switchMap(() => this.loginService.studentLogin(username, password, undefined))
    ).subscribe({
      next: (response) => {
        this.onLoginSuccess(response);
      },
      error: (error) => {
        console.error(error)
        this.onLoginError(error);
      },
    });
  }

  continueLogin() {
    this.showUsage = false;
    this.showUsageLogin = true;
    setTimeout(() => {
      this.goToStartScreen();
    }, 10000);

    this.loginService.studentUsageAlert().subscribe();
  }

  cancelLogin() {
    this.showUsage = false;
    this.showUsageLogout = true;

    setTimeout(() => {
        this.sessionTimerService.cancelTimers();
        this.reloadComponent();
    }, 10000);
  }

  private onLoginSuccess(response: any)
  {
    this.studentData = response.data;

    if (response.isLoggedIn)
    {
      this.interventionTaskService.resetData();
      this.studentDataService.loadAvailableAvatars();
      const studentCompletionResult = this.loginService.studentCompletionStatus(this.studentData);

      if (this.studentDataService.isDemoUser())
      {
        // Have the demo user select the curriculum
        this.goToDemoSelectScreen();
      }
      else if (studentCompletionResult.status === 'complete')
      {
        // All tasks are complete so don't allow student to use again
        this.alertStatus = AlertStatus.INFO;
        this.loginButtonDisabled = false;
        this.result = studentCompletionResult.message;
        this.applicationStateService.clear();
        this.sessionTimerService.cancelTimers();
        // Make the  message go away after 60 seconds.
        this.alreadyCompleteTimeout = setTimeout(() => {
          this.alertStatus = AlertStatus.HIDDEN;
        }, 60000);
      }
      else
      {
        // Load curriculum
        this.audioPlayerService.play('Audio/Other/login.mp3').subscribe({
          error(event) {
            console.error('Error playing audio: ', event);
          }
        });

        this.curriculumService.loadCurriculum().subscribe(() => {
          if (this.studentData?.overWeeklyUsageLimit)
          {
            //
            // Initiates the pop-out animation for the login box and
            // waits 1.5 seconds before showing the excessive usage dialog
            this.loggedIn = true;
            setTimeout(() => {
              this.showUsage = true;
            }, 1500);
          }
          else
          {
            this.goToStartScreen();
          }
        });
      }
    }
    else
    {
      this.onLoginError(response);
    }
  }

  private onLoginError(errorResponse: any) {
    this.alertStatus = AlertStatus.ERROR;
    this.loginButtonDisabled = false;

    let errorCode = errorResponse?.status || -1;
    switch (errorCode) {
      case 401:
          this.result = "The username or password you entered is incorrect.";
          break;
      case 402:
          this.result = "Your subscription has expired.";
          break;
      case 403:
          this.result = "You do not have the correct permissions to access this site."
          break;
      case 406:
          this.showProviderButtons = true;
          this.result = "Looks like that didn't work. Would you like to try logging in with another provider?";
          break;
      default:
          Sentry.captureException(errorResponse, {
            tags: {
              section: 'wf-login',
              enteredUsername: this.loginForm.get('username')?.value,
            }
          }) ;
          this.result = "The application is temporarily unavailable. Please wait a few moments and try again.";
          break;
    }
  }

  private goToStartScreen() {
    // TODO: Replace these class addings to animations https://angular.io/guide/architecture-services
    this.themeParseService.loadThemeProperties().subscribe(() => {
      this.backgroundComponent.startFadeOut();
      this.loggedIn = true;
      this.goToStartAnimation = true;
      setTimeout(() => {
        this.studentDataService.setSideMenuOpen(true);
        if (this.studentDataService.isFullProductSubscription()) {
          this.router.navigateByUrl('/world');
        } else {
          this.router.navigateByUrl('/levelSelect', { state: { previous: this.router.url } });
        }
      }, 2000);
    });
  }

  private goToDemoSelectScreen() {
    this.router.navigateByUrl('/demo');
  }

  private reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/login']);
  }

  private isBrowserSupported() {
    let ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    let msie = ua.indexOf('MSIE ');
    let trident = ua.indexOf('Trident/');
    let edge = ua.indexOf('Edge/');
    if (edge > 0 || trident > 0 || msie > 0) {
        return false;
    }

    return true;
  }

}
