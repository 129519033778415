import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { CurriculumService } from 'src/app/core/services/curriculum.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { SessionTimerService } from 'src/app/core/services/session-timer.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { ThemeParseService } from 'src/app/core/services/theme-parse.service';
import { LoginService } from '../login.service';
import { SSOError, Credentials, AlertDetails } from '../../core/models/credentials.model';
import { CleverService } from './clever.service';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-clever',
  templateUrl: './clever.component.html',
  styleUrls: ['./clever.component.css']
})
export class CleverComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private reusableTimeout?: number;

  token: string = "";
  appStoreLink: string = '' ;
  invalidToken: boolean = false;
  loadingToken: boolean = false;
  showWelcome: boolean = false;
  showUsage: boolean = false;
  showUsageLogout: boolean = false;
  showUsageLogin: boolean = false;
  restrictLogin: boolean = true ;
  error?: SSOError;
  alert?: AlertDetails;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private audioPlayerService: AudioPlayerService,
    private cleverService: CleverService,
    private curriculumService: CurriculumService,
    private interventionTaskService: InterventionTaskService,
    private loginService: LoginService,
    private applicationStateService: ApplicationStateService,
    private sessionTimerService: SessionTimerService,
    private studentDataService: StudentDataService,
    private themeParseService: ThemeParseService,
  ) { }

  // Lifecycle Hooks
  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams["token"];
    this.loadingToken = true;
    if (this.invalidToken)
    {
      this.error = {
        message: 'Could not communicate with Clever',
        details: 'We could not communicate with Clever to get a valid login and retrieve your user info'
      }
      this.loadingToken = false;

      return;
    }

    this.appStoreLink = environment.appStoreLink ;

    this.getUserInfo();
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.reusableTimeout);
    this.subscriptions.unsubscribe();
  }
  // End of lifecycle hooks

  private onLoginSuccess() {
    this.interventionTaskService.resetData();
    this.studentDataService.loadAvailableAvatars();
    const studentCompletionResult = this.loginService.studentCompletionStatus(this.studentDataService.getStudentData());

    if (this.studentDataService.isDemoUser())
    {
      // Have the demo user select the curriculum
      this.goToDemoSelectScreen();
    }
    else if (studentCompletionResult.status === 'complete')
    {
      // All tasks are complete so don't allow student to use again
      this.applicationStateService.clear();
      this.alert = {
        'message': 'All Done!',
        'details' : studentCompletionResult.message,
      } ;

      // Logout the user after showing the already completed text for a minute
      window.clearTimeout(this.reusableTimeout);
      this.reusableTimeout = window.setTimeout(() => {
        this.sessionTimerService.cancelTimers();
        this.router.navigateByUrl('/login')
      }, 60000);
    }
    else
    {
      // Load curriculum
      let curriculumSubscription = this.curriculumService.loadCurriculum().subscribe(() => {
        // If we are logging in via SSO, we need to have the user interact with the site
        // otherwise any media played will be considered 'auto-played' and be blocked in the browser -- so display our
        // simple welcome dialog that requires the user to click the button to continue
        this.showWelcome = true ;
        document.getElementById("login-wait")?.classList.add('pop-out', 'hidden');
      });

      this.subscriptions.add(curriculumSubscription);
    }
  }

  private onLoginError(err?: any) {
    this.error = {
      'message' : 'Login failed',
      'details' : 'An error occurred while trying to login to WordFlight'
    } ;
    this.loadingToken = false;

    if (err)
    {
      Sentry.captureException(err, {
        tags: {
          section: 'login',
          from: 'clever',
        }
      })
    }
  }

  private login(credentials: Credentials) {
    let loginSubscription = this.loginService.checkForUpdate().pipe(
      switchMap(updateRequired => {
        if (updateRequired)
        {
          // Display a message and reload the page to update if required.
          window.setTimeout(() => {
            window.location.reload();
          }, 1500);
          this.error = {
            'message' : 'A new version is available',
            'details' : 'A new version of WordFlight is available.'
          };
        }
        return of(updateRequired)
      }),
      // Only login if an update is not required.
      filter(updateRequired => !updateRequired),
      switchMap(() => this.loginService.studentLogin(credentials.wf.username, '', credentials.sso))
    ).subscribe({
      next: (response) => {
        if (response.isLoggedIn)
        {
          this.onLoginSuccess();
        }
        else
        {
          this.onLoginError();
        }
      },
      error: (err: any) => {
        this.onLoginError(err);
      }
    });

    this.subscriptions.add(loginSubscription);
  }

  private goToDemoSelectScreen() {
    this.router.navigateByUrl('/demo');
  }

  private goToStartScreen() {
    let themeSubscription = this.themeParseService.loadThemeProperties().subscribe(() => {
      // TODO: Get rid of document and run animations in a more 'Angular' way
      let audioSubscription = this.audioPlayerService.play('Audio/Other/login.mp3').subscribe();
      this.subscriptions.add(audioSubscription);
      document.getElementById("background-overlay")?.classList.add('fade-out');
      document.getElementById("fil-logo")?.classList.add('fade-out');
      document.getElementById("version")?.classList.add('fade-out');

      window.clearTimeout(this.reusableTimeout);
      this.reusableTimeout = window.setTimeout(() => {
        this.studentDataService.setSideMenuOpen(true);

        if (this.studentDataService.isFullProductSubscription())
        {
          this.router.navigateByUrl('/world');
        }
        else
        {
          this.router.navigateByUrl('/levelSelect', { state: { previous: this.router.url } });
        }
      }, 2000)
    });

    this.subscriptions.add(themeSubscription);
  }

  /**
   * When coming to this component, ngInit calls the getUserInfo method to ask the dataservices for both the Clever and WF
   * student user information. At this point, we expect the OAuth grant flow to have happened from Clever and we should have
   * a valid bearer token (this will be checked on requests to dataservices). If our token is not valid, Clever will reject
   * our call and we cannot get any User Info from the dataservices. If the token is valid, dataservices will provide Clever
   * user info, and if a matching WF account is found for the Clever student, it will be returned as well. If no WF user is
   * found, we will attempt to create one if the our Student is authorized via Clever SecureSync. If that is not possible,
   * we will have to give up and ask the Student to get help from school resources.
   * @private
   */
  getUserInfo() {
    // Before we even attempt to get our user info and login, determine if we are on iOS in a browser window,
    // if so abort any login and display our App message
    /**
     * if determine this is a mac/ios - cannot rely on Safari b/c chrome os reports that
     * if determine this is mobile - multitouchpoints
     * if determine this is web - capacitor getplatform === 'web'
     * then do not show web login
     */
    this.restrictLogin = Capacitor.getPlatform() === 'web' && (window.navigator.maxTouchPoints > 1) && (window.navigator.userAgent.toLowerCase().indexOf('mac') > -1) ;
    if (this.restrictLogin)
    {
      // No longer loading, but we do not want to continue login process so return
      this.loadingToken = false ;

      return ;
    }

    let userInfoSubscription = this.cleverService.getUserInfo(this.token).pipe(
      switchMap((userInfoObj: any) => {
        if (userInfoObj.wf.username) {
          return of({ userObj : userInfoObj }) ;
        }
        else if (!userInfoObj.sso || userInfoObj.sso.authorizedBy !== 'district') {
          return of({
            error: {
              message: 'WordFlight Account Not Found',
              details: 'It doesn\'t appear that your WordFlight account has been linked to your Clever account'
            }
          }) ;
        }
        else {
          // If we made it here and we are authorized via District SecureSync, our Clever login was valid but
          // we do not have a WF account for the student, set our SSO info and attempt to communicate with Clever and get teacher info
          this.applicationStateService.setSSOInfo(userInfoObj.sso) ;
          return this.cleverService.rosterStudent(userInfoObj.sso) ;
        }
      })
    ).subscribe({
      next: (value : { userObj?: any, teachers?: any, error?: SSOError }) => {
        this.loadingToken = false;

        if (value.userObj) {
          this.sessionTimerService.cancelTimers();
          return this.login(value.userObj);
        }
        else if (value.error) {
          this.studentDataService.setIsLoggedIn(false);
          this.error = value.error ;
        }
        else {
          this.error = {
            message: 'Uh oh!',
            details: 'Something went wrong when trying to log you in with Clever, please try again.'
          }
        }
      },
      error: (error) => {
        if (error.status && error.status === 403)
        {
          this.error = {
            message: 'We could not log you in with Clever',
            details: 'Please make sure your Clever account is logged in and try again'
          }
        }
        else if (error.error && error.error.message)
        {
          this.error = error.error ;
        }
        else
        {
          this.error = {
            message: 'Cannot reach Clever',
            details: 'It looks like we are having trouble communicating with Clever. Check your internet connection and try again.'
          };

          // Other error cases would be handled and reported correctly, this catch all needs to go to Sentry
          Sentry.captureException(error, {
            tags: {
              section: 'clever-login',
            }
          }) ;
        }
        this.studentDataService.setIsLoggedIn(false);
        this.loadingToken = false;
      }
    });

    this.subscriptions.add(userInfoSubscription);
  }

  acceptWelcome() {
    document.getElementById('welcome')?.classList.add('pop-out');
    this.goToStartScreen();
  }

  cancelLogin() {
    this.showUsage = false;
    this.showUsageLogout = true;
    this.applicationStateService.clear();

    window.clearTimeout(this.reusableTimeout);
    this.reusableTimeout = window.setTimeout(() => {
      this.sessionTimerService.cancelTimers();
      this.router.navigateByUrl('/login');
    }, 10000);
  }

  continueLogin() {
    this.showUsage = false;
    this.showUsageLogin = true;

    this.loginService.studentUsageAlert();

    window.clearTimeout(this.reusableTimeout);
    this.reusableTimeout = window.setTimeout(() => {
      this.goToStartScreen();
    }, 10000);
  }
}
