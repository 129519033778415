<div class="viewport-1" [ngStyle]="{'background-image':'url(' + taskBackgroundImage + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="totalTaskPoints"></task-bar>

  <div class="task-screen-element find-the-rhyme-container"
    [ngStyle]="{'background-image': ' ' + taskContainerColor + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <button class="replayVideo-no-timerbar" (click)="replayVideo()" [disabled]="disableVideo()"></button>
    <div class="word-to">
      <div class="word font-42">
        {{targetWord}}
      </div>
    </div>
    <div class="response-row">
      <button *ngIf="!unmaskedTask"
        [ngClass]="{'next-button-grayed' : disableNextButton, 'next-button' : !disableNextButton, 'toggle-next-on' : toggleNextButton, 'next-button-hidden': hideNextButton}"
        [disabled]="disableNextButton" (click)="displayTrial()">
      </button>
    </div>
    <div class="full-response-row" *ngIf="!hideResponses">
      <div class="button-div" *ngFor="let response of responseOptions1; index as $index">
        <button [ngClass]="(disableResponseButtons) ? ['rhyme-response-block-grayed font-30'] : ['rhyme-response-block font-30']"
          (click)="submitAnswer($index)"
          [ngStyle]="{'border-color':' ' + highlightColor + ' '}" [disabled]="disableResponseButtons">
          {{response}}
        </button>
      </div>
    </div>
    <div class="full-response-row" *ngIf="!hideResponses">
      <div class="button-div" *ngFor="let response of responseOptions2; index as $index">
        <!--TODO: adding to the index hardcoded could make for an easy mistake later, should probably 
          update that, or at least make a constant. This happens in some other tasks too-->
        <button [ngClass]="(disableResponseButtons) ? ['rhyme-response-block-grayed font-30'] : ['rhyme-response-block font-30']"
          (click)="submitAnswer($index + 3)"
          [ngStyle]="{'border-color':' ' + highlightColor + ' '}" [disabled]="disableResponseButtons">
          {{response}}
        </button>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="false" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTrialData()"></save-data-dialog>
</div>