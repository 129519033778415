import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SSOCredentials, SSOError } from '../../core/models/credentials.model';
import {catchError, retry, switchMap} from 'rxjs/operators';
import { of } from 'rxjs';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class ClasslinkService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getUserInfo(bearerToken: string) {
    let reqOptions = {
      headers: { 'Access-Control-Allow-Origin' : '*' },
    }

    return this.httpClient.post(environment.EC2URL + 'classlink/info', { bearerToken }, reqOptions).pipe(
      retry(2),
    ) ;
  }

  rosterStudent(student: SSOCredentials) {
    const reqOptions = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Content-Type': 'application/json',
      }
    } ;

    return this.httpClient.post(`${environment.EC2URL}classlink/student/roster`, { student: student }, reqOptions).pipe(
      switchMap((userInfo) => {
        return of({ userObj: userInfo }) ;
      }),
      catchError((error) => {
        console.log(error, error.error.message) ;
        let err: SSOError ;
        if (error.status === 403)
        {
          err = {
            message: 'We could not log you in with Classlink',
            details: error.error?.message ? error.error.message : 'Please make sure your Classlink account is logged in and try again'
          }
        }
        else if (error.status === 400)
        {
          err = {
            message: 'We could not create your account',
            details: error.error?.message ? error.error.message : 'We were unable to find your Teacher so we could not create your account, please ask for help!'
          }
        }
        else
        {
          err = {
            message: 'We could not create your account',
            details: error.error?.message ? error.error.message : 'Something happened while trying to create your account, please ask your teacher to let us know!'
          }
        }

        Sentry.captureException(error, {
          tags: {
            section: 'classlink',
            action: 'roster-student',
          },
          contexts: {
            roster: {
              ssoId: student.ssoId,
              account: student.account,
              authorizedBy: student.authorizedBy,
              provider: student.provider,
            },
          },
        }) ;

        return of({ error: err }) ;
      })
    ) ;
  }
}
