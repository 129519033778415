import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  'name' : 'formatString'
})
export class FormatStringPipe implements PipeTransform {
  transform(value: string | null, lineLength: number = 14): string {
    if (value === null || value.trim() === '') return '' ;

    let lines: string[] = [] ;
    // Go through the string, breaking it at the line length (or nearest space character)
    while (value.length > lineLength)
    {
      // Determine the appropriate place to break the string (the last space)
      let endOfLineIndex = value.substring(0, lineLength).lastIndexOf(' ') ;
      if (endOfLineIndex <= 0) endOfLineIndex = lineLength ;

      // Add this split line to our running formatted string
      lines.push(value.substring(0, endOfLineIndex)) ;

      // Determine where to pick up the next line from
      let startOfLineIndex = value.indexOf(' ', endOfLineIndex) + 1 ;
      if (startOfLineIndex < endOfLineIndex || startOfLineIndex > endOfLineIndex + 1)
      {
        startOfLineIndex = endOfLineIndex ;
      }

      value = value.substring(startOfLineIndex) ;
    }
    lines.push(value) ;

    return lines.join('\n') ;
  }
}
