import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { InterventionTask } from 'src/app/core/models/task.model';

import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';

@Component({
  selector: 'task-bar',
  templateUrl: './task-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskBarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() totalPoints?: number;
  @Input() indicatorTimer?: number; // optional indicator timer input
  @Output() taskAnimationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  // TEMP for initial compilation
  taskName: string = '';
  taskSubtext: string = '';
  taskIcon: string = '';
  backButtonVisible: boolean = false;
  taskBarTaskDetailsVisible: boolean = false;
  animationDelay: number = 3000;
  waitForAnimationTimer: number | null = null;
  backButtonTimeout: number | null = null;

  animationComplete: boolean = false;
  title: string;
  taskBarColor: string;
  disableBackButton: boolean = false;
  parentTaskId: string = '' ;
  isPulse: boolean = false; // control variable for pulsing effect

  constructor(
    private interventionTaskService: InterventionTaskService,
    private studentDataService: StudentDataService,
    private applicationStateService: ApplicationStateService,
    private audioService: AudioPlayerService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.title = studentDataService.getTaskBarTitleBasedOnCurriculumPlacement();
    this.taskBarColor = studentDataService.getTaskBarColor();
    let navigationExtras = this.router.getCurrentNavigation()?.extras.state;
    this.parentTaskId = navigationExtras?.parentTaskId || '' ;
  }

  ngOnInit(): void {
    let destinationValue = this.applicationStateService.getCurrentDestination();
    let selectedTask = this.studentDataService.getSelectedTask();

    if (this.studentDataService.areAllTasksCompleteForDestination(destinationValue) || (selectedTask !== null && this.studentDataService.isInterventionUnit())) {
      this.backButtonVisible = true;
    }else{
      this.backButtonVisible = false;
    }

    this.taskBarTaskDetailsVisible = (selectedTask != null);

    this.disableBackButton = true;
    this.backButtonTimeout = window.setTimeout(() => {
      this.disableBackButton = false;
      this.changeDetector.markForCheck() ;
    }, 4300);

    if (selectedTask !== null) {
      this.taskIcon = "/assets/images/destination/" + selectedTask.taskIcon + ".svg";
      this.taskSubtext = selectedTask.taskSubtext ;

      let taskName = selectedTask.taskType.replace('...',' ') + selectedTask.taskName;
      taskName = taskName.toUpperCase().replace('PASSAGES ','');
      this.taskName = taskName;
    }

    if (selectedTask === null) {
      //No animation occurs when we aren't in a task
      this.animationDelay = 0;
      this.backButtonVisible = true;
    }

    this.waitForAnimationTimer = window.setTimeout(() => {
      this.taskAnimationComplete.emit(true);
      this.animationComplete = true;
      this.changeDetector.markForCheck();
      this.startPulseTimer
    }, this.animationDelay);
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check if `indicatorTimer` changes
    if (changes.indicatorTimer) {
      this.startPulseTimer();
    }
  }

  startPulseTimer() {
    // Clear any existing timeout
    if (this.indicatorTimer) {
      clearTimeout(this.indicatorTimer);
    }
    // Start a new timeout if `indicatorTimer` is defined
    if (this.indicatorTimer) {
      setTimeout(() => {
        this.isPulse = true;
        this.changeDetector.markForCheck();
      }, this.indicatorTimer);
    }
  }

  ngOnDestroy(): void {
    if (this.waitForAnimationTimer !== null) {
      window.clearTimeout(this.waitForAnimationTimer);
    }
    if (this.backButtonTimeout !== null) {
      window.clearTimeout(this.backButtonTimeout);
    }
    if (this.indicatorTimer !== undefined) {
      window.clearTimeout(this.indicatorTimer);
    }
  }

  goBack(): void {
    // Firs thing to do when navigating away, stop any audio that is playing
    this.audioService.stopAll() ;

    // Returning from a task page
    if (this.parentTaskId) {
      this.router.navigateByUrl('/subtaskSelect', {
        state: {
          canGoToTaskSelect: true,
          previousTaskScoreData: this.interventionTaskService.getPreviousTaskScoreData(),
          mainTaskId: this.parentTaskId,
        }
      });
    }
    else {
      this.router.navigateByUrl('/taskSelect',
        {state: {canGoToTaskSelect: true, previousTaskScoreData: this.interventionTaskService.getPreviousTaskScoreData()}});
    }
  }
}
