<div class="viewport-1" [ngStyle]="{'background-image':'url(' + taskBackgroundImage + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>
  <div class="task-screen-element fill-in-blank-container" *ngIf="animationComplete" [ngStyle]="{'background-image': ' ' + taskContainerColor + ' '}">
    <div class="testType font-18">{{ testType }}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    </div>
    <div [ngClass]="{'find-the-word-syllables-target-area' : findTheSyllableBreaksTask,'find-the-word-syllables-target-area-no-border' : !findTheSyllableBreaksTask}">
      <button
          [ngClass]="{'speaker find-the-syllables-speaker-adjust': findTheSyllableBreaksTask, 'speaker-no-target-area': !findTheSyllableBreaksTask}"
          (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons"
      >
      </button>
      <div class="find-the-word-syllables-target-text font-42" *ngIf="findTheSyllableBreaksTask">{{targetWord}}</div>
    </div>
    <div class="find-the-word-syllables-response-row" *ngIf="responseOptions1.length > 0">
      <div class="big-button-div" *ngFor="let response of responseOptions1; index as $index">
        <button class = "big-div-letter-audio-hidden" [hidden]="showResponseAudioButtons"></button>
        <button class = "big-div-letter-audio" [hidden]="!showResponseAudioButtons" (click)="playResponseAudio(response.index)" [disabled]="disableAVButtons"></button>
        <button 
            class = "syllable-breaks-response-block font-30"
            [ngClass]="{'hover': response.highlight}"
            [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
            [disabled]="responsesDisabled"
            (click)="submitResponse(response.index);"
        >
          {{response.text}}
          <span class="find-the-word-family-text-color">{{response.wordFamily}}</span>
        </button>
      </div>
    </div>
    <div class="find-the-word-syllables-response-row" *ngIf="responseOptions2.length > 0">
      <div class="big-button-div" *ngFor="let response of responseOptions2; index as $index">
        <button class = "big-div-letter-audio-hidden" [hidden]="showResponseAudioButtons"></button>
        <button class = "big-div-letter-audio" [hidden]="!showResponseAudioButtons" (click)="playResponseAudio(response.index)" [disabled]="disableAVButtons"></button>
        <button
            class = "syllable-breaks-response-block font-30"
            [ngClass]="{'hover': response.highlight}"
            [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
            [disabled]="responsesDisabled"
            (click)="submitResponse(response.index);"
        >
          {{response.text}}
          <span class="find-the-word-family-text-color">{{response.wordFamily}}</span>
        </button>
      </div>
    </div>
    <div class="find-the-word-syllables-response-row" *ngIf="responseOptions3.length > 0">
      <div class="big-button-div" *ngFor="let response of responseOptions3; index as $index">
        <button class="big-div-letter-audio-hidden" [hidden]="showResponseAudioButtons"></button>
        <button class="big-div-letter-audio" [hidden]="!showResponseAudioButtons" (click)="playResponseAudio(response.index)" [disabled]="disableAVButtons"></button>
        <button 
            class="syllable-breaks-response-block font-30"
            [ngClass]="{'hover': response.highlight}"
            [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
            [disabled]="responsesDisabled"
            (click)="submitResponse(response.index)"
        >
          {{response.text}}
          <span class="find-the-word-family-text-color">{{response.wordFamily}}</span>
        </button>
      </div>
    </div>
  </div>

  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>

