import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  beatDuration = 150; // ms

  constructor() { }

  startTimer(): number {
    return new Date().getTime();
  }

  stopTimer(): number {
    return new Date().getTime();
  }
  
  computeTime(start: number, end: number): number {
    return end - start;
  }

  /**
   * Calculates the duration in milliseconds based on the given number of beats and the beat duration.
   * @param beats The number of beats.
   * @returns The duration in milliseconds.
   */
  getDurationInBeats(beats: number): number {
    return beats * this.beatDuration;
  }
}
