import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'save-data-dialog',
  templateUrl: './save-data-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveDataDialogComponent implements OnInit {

  @Output() onSaveButtonClick = new EventEmitter<null>();

  public ngshowSaveDataDialog: boolean = false;
  public dialogButtonsDisabled: boolean = false;

  constructor(
    private loginService: LoginService,
    private changeDetector: ChangeDetectorRef,
  ) {  }

  ngOnInit(): void {
  }

  showSaveDataDialog() {
    this.dialogButtonsDisabled = false;
    this.ngshowSaveDataDialog = true;
    this.changeDetector.markForCheck() ;
  }

  hideSaveDataDialog() {
    this.ngshowSaveDataDialog = false;
    this.changeDetector.markForCheck() ;
  }

  logoutBtnDialogClick() {
    this.ngshowSaveDataDialog = false;
    this.loginService.studentLogout().subscribe();
  }

  saveBtnDialogClick() {
    this.dialogButtonsDisabled = true;
    this.onSaveButtonClick.emit();
  }
}
