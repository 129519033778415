<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element verify-the-blank-container" [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    </div>
    <div class="word-to-verify">
      <button class="speaker" (click)="repeatWord()" [disabled]="disableAVButtons"></button>
      <div class="verify-word font-42">
        {{targetWord}}
      </div>
    </div>
    <div class="response-row">
      <!--TODO: Check that these buttons are correctly match intervention task behavior-->
      <button [ngClass]="(disableResponseButtons) ? ['verify-response-correct-grayed'] : ['verify-response-correct']"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}" [disabled]="disableResponseButtons" (click)="submitAnswer(true)">
      </button>
      <button  class="next-trial-base"
          [ngClass]="{'next-trial-hidden': hideNextButton || unmaskedTask, 'next-trial-grayed' : disableNextButton, 'next-trial' : !disableNextButton, 'toggle-next-on' : toggleNextButton}"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' ', 'position': 'relative ', 'top': '-40% '}"
          [disabled]="disableNextButton"
          (click)="nextTrial()">
      </button>
      <button [ngClass]="(disableResponseButtons) ? ['verify-response-incorrect-grayed'] : ['verify-response-incorrect']"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}" [disabled]="disableResponseButtons" (click)="submitAnswer(false)">
      </button>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>