import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StudentData } from 'src/app/core/models/student-data.model';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { DiagnosticTask, InterventionTask } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { environment } from '../../../../environments/environment';
import { InstructionsComponent } from '../instructions/instructions.component';

@Component({
  selector: 'student-header',
  templateUrl: './student-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentHeaderComponent implements OnInit {

  studentData: StudentData;
  studentID: string = '';
  videoSource: string = '' ;
  studentDailyPoints: number = 0;
  hideTeam: boolean = false;
  hideTeamName: boolean = false;
  teamName: string = '';
  animateTeamName: boolean = false;
  showNewTeam: boolean = false;
  avatarIcon: string = '';

  headerBackButtonVisible: boolean = false;
  hidePoints: boolean = false;
  pulseHeaderGoBackButton: boolean = false;
  disableGoBackButton: boolean = false;

  currentDailyPoints: number = 0;
  lastSeenDailyPoints: number | null = null;

  // navigation extras
  parentTaskId: string = '' ;
  alreadyCompleted: boolean;

  @ViewChild('instructions') instructionsComponent!: InstructionsComponent;

  constructor(
    private router: Router,
    private audioPlayerService: AudioPlayerService,
    private applicationStateService: ApplicationStateService,
    private studentDataService: StudentDataService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.studentData = this.studentDataService.getStudentData();
    this.parentTaskId = this.router.getCurrentNavigation()?.extras?.state?.parentTaskId;
    this.alreadyCompleted = this.router.getCurrentNavigation()?.extras?.state?.alreadyCompleted;
  }

  ngOnInit(): void {
    this.studentID = this.applicationStateService.getUsername() || '' ;
    this.currentDailyPoints = (this.studentData.leaderboard != null ? this.studentData.leaderboard.studentDailyPoints : 0);
    this.lastSeenDailyPoints = this.applicationStateService.getLastSeenDailyPoints();
    let destinationValue: number = this.studentDataService.getCurrentDestination();

    this.hideTeam = this.studentData.team == null || this.studentData.team.teamName == null;
    this.hideTeamName = this.router.url === '/levelSelect';
    this.teamName = this.hideTeam ? '' : this.studentData.team.teamName;

    this.avatarIcon = this.studentDataService.getCurrentStudentAvatar().avatar;
    this.headerBackButtonVisible = (this.router.url === '/taskSelect') && this.studentDataService.areAllTasksCompleteForDestination(destinationValue);
    this.hidePoints = !(this.router.url === '/taskSelect' || this.router.url === '/levelSelect' || this.router.url === '/subtaskSelect') ;

    // Only pulse the back button if we are coming back from a task that was not already been completed before
    this.pulseHeaderGoBackButton = this.headerBackButtonVisible && (!this.alreadyCompleted || this.alreadyCompleted === null);
  }

  headerGoBack(): void {
    this.disableGoBackButton = true;
    if (this.studentDataService.isFullProductObjectiveOrDiagnosticComplete()){
      if (this.studentDataService.isPreDiagnostic())
      {
        this.videoSource = environment.VideoAssetServerURL + '/assets/video/theme' + this.studentDataService.getStudentThemeNumber() + '/video_end-of-prediag.mp4';
        this.changeDetector.detectChanges();

        this.instructionsComponent.playInstructionalVideo().subscribe({
          complete: () => {
            this.router.navigateByUrl('/world');
          }
        });
      }
      else
      {
        this.router.navigateByUrl('/world');
      }
    } else {
      this.router.navigateByUrl('/levelSelect', { state: { previous: this.router.url } });
    }
  }

  checkForNewTeam(): Observable<null> {
    return new Observable((observer) => {
      let animationTimeout: any;
      if (this.studentData.team != null && !this.studentData.team.hasBeenNotifiedOfNewTeam && !this.studentDataService.isDemoUser()){
        this.hideTeamName = false;
        let duration = 6500;
        if (this.studentData.team.hasSeenInstructionalVideo){
          this.audioPlayerService.play('Audio/Help/newteam_short.mp3').subscribe();
          duration = 3000;
        } else {
          this.audioPlayerService.play('Audio/Help/newteam.mp3').subscribe();
        }

        this.animateTeamName = true;
        this.showNewTeam = true;
        this.changeDetector.detectChanges() ;
        animationTimeout = window.setTimeout(() => {
          // hide text and play the rest of the animation before resolving
          this.showNewTeam = false;
          this.changeDetector.detectChanges() ;

          animationTimeout = window.setTimeout(() => {
            this.applicationStateService.setHasBeenNotifiedOfNewTeam(true);
            this.applicationStateService.setHasSeenInstructionalVideo(true);
            this.changeDetector.detectChanges() ;
            observer.next();
            observer.complete();
          }, duration)
        }, 3000)
      } else {
        this.hideTeamName = false;
        this.changeDetector.detectChanges() ;
        observer.next();
        observer.complete();
      }

      return {
        unsubscribe() {
          window.clearTimeout(animationTimeout);
        }
      }
    });
  }

  updateAvatarIcon(): void {
    this.avatarIcon = this.studentDataService.getCurrentStudentAvatar().avatar;
    this.changeDetector.markForCheck() ;
  }

  checkForPointsAnimation(): Observable<null> {
    return new Observable((observer) => {
      let dailyPointsAnimationInterval: any;
      // NOTE: If just logging in, the lastSeenDailyPoints is set to null
      //     :  so we won't display the animation but we will instead set
      //     :  it to the currentDailyPoints.
      if (this.lastSeenDailyPoints != null && this.currentDailyPoints > this.lastSeenDailyPoints)
      {
        this.audioPlayerService.play('Audio/Other/point_counter.mp3').subscribe();
        this.studentDailyPoints = this.lastSeenDailyPoints;
        this.applicationStateService.setLastSeenDailyPoints(this.currentDailyPoints)

        // Initialization of timing variables in ms.
        // NOTE: IOS can't seem to go much faster than the 110 ms.
        let interval = 110;
        let duration = 1500;

        // Calculate the amount the points should increment based on the interval and the points difference
        let increment =  ((this.currentDailyPoints - this.studentDailyPoints)/(duration/interval))
        let incrementedPoints = this.studentDailyPoints;

        dailyPointsAnimationInterval = window.setInterval(() => {
          if (this.studentDailyPoints < this.currentDailyPoints)
          {
            if (this.studentDailyPoints + increment > this.currentDailyPoints)
            {
              this.studentDailyPoints = this.currentDailyPoints;
            }
            else
            {
              incrementedPoints += increment;

              // Assign only an integer value to the displayed score.
              this.studentDailyPoints = Math.floor(incrementedPoints);
            }
            this.changeDetector.detectChanges() ;
          }
          else
          {
            window.clearInterval(dailyPointsAnimationInterval);
            dailyPointsAnimationInterval = null;
            this.changeDetector.detectChanges() ;
            observer.next();
            observer.complete();
          }
        },interval);
      }
      else
      {
        this.studentDailyPoints = this.currentDailyPoints;
        this.applicationStateService.setLastSeenDailyPoints(this.currentDailyPoints);
        observer.next();
        observer.complete();
        this.changeDetector.detectChanges() ;
      }

      return {
        unsubscribe() {
          clearInterval(dailyPointsAnimationInterval);
        }
      }
    });
  }

  moveBackOneLevel($event: any): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.router.url === '/levelSelect') {
      if (this.studentDataService.isDemoUser()) {
        this.router.navigateByUrl('/demo');
      }
    }
    else if (this.studentDataService.isUserSuperUser()) {
      if (this.router.url === '/taskSelect') {
        this.router.navigateByUrl('/levelSelect', { state: { previous: this.router.url } });
      } else {
        // We are returning from a task screen
        if (this.parentTaskId) {
          this.router.navigateByUrl('/subtaskSelect', {
            state: {
              mainTaskId: this.parentTaskId,
            }
          });
        }
        else {
          // Going back one level from a task screen gets us to the Task Select screen
          this.router.navigateByUrl('/taskSelect');
        }
      }
    }
  }
}
