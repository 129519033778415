import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wf-trial-counter-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialChipComponent implements OnInit {
  @Input() trialStatus: { [key: string]: string } = {} ;

  constructor() { }

  ngOnInit(): void {
  }
}
