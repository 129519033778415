<div class="viewport-1" [ngStyle]="{'background-image':'url(' + taskBackgroundImage + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="totalTaskPoints"></task-bar>

  <div class="task-screen-element find-the-picture-container"
    [ngStyle]="{'background-image': ' ' + taskContainerColor + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <button class="replayVideo-no-timerbar" (click)="replayVideo()" [disabled]="disableVideo()"></button>
    <div class="word-to">
      <div class="word font-42">
        {{targetWord}}
      </div>
    </div>
    <div class="full-response-row">
      <button *ngIf="!unmaskedTask" [ngClass]="{'next-button-grayed' : disableNextButton, 'next-button' : !disableNextButton, 'toggle-next-on' : toggleNextButton, 'next-button-hidden': hideNextButton}"
        [disabled]="disableNextButton" (click)="displayTrial()">
      </button>
    </div>
    <div class="full-response-row-picture" *ngIf="!hideResponses">
      <div class="picture-button-div" *ngFor="let response of responseOptions; index as $index">
        <button [ngClass]="(disableResponseButtons) ? ['picture-response-block-grayed'] : ['picture-response-block']"
          (click)="submitAnswer($index)"
          [ngStyle]="{'border-color':' ' + highlightColor + ' ', 'background-image':'url(/assets/' + response.imageUrl + ')' }"
          [disabled]="disableResponseButtons">
        </button>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="false" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTrialData()"></save-data-dialog>
</div>
