import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VerifyTheBlankComponent } from './verify-the-blank/verify-the-blank.component';
import { TrialCounterComponent } from './trial-counter/trial-counter.component';
import { SaveDataDialogComponent } from './save-data-dialog/save-data-dialog.component';
import { TaskBarComponent } from './task-bar/task-bar.component';
import { TotalPointsComponent } from './total-points/total-points.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { VerifyTheBlankInterventionComponent } from './verify-the-blank-intervention/verify-the-blank-intervention.component';
import { TryAgainDialogComponent } from './try-again-dialog/try-again-dialog.component';
import { TrialTimerBarComponent } from './trial-timer-bar/trial-timer-bar.component';
import { ChangeTheWordInterventionComponent } from './change-the-word-intervention/change-the-word-intervention.component';
import { ChangeTheBlankComponent } from './change-the-blank/change-the-blank.component';
import { FillInBlankComponent } from './fill-in-blank/fill-in-blank.component';
import { FindThePictureComponent } from './find-the-picture/find-the-picture.component';
import { FindTheRhymeComponent } from './find-the-rhyme/find-the-rhyme.component';
import { PassagesReadAndListenComponent } from './passages-read-and-listen/passages-read-and-listen.component';
import { FindThePictureInterventionComponent } from './find-the-picture-intervention/find-the-picture-intervention.component';
import { SpellTheWordInterventionComponent } from './spell-the-word-intervention/spell-the-word-intervention.component';
import { SpellingInterventionComponent } from './spelling-intervention/spelling-intervention.component';
import { AnswerTheQuestionInterventionComponent } from './answer-the-question-intervention/answer-the-question-intervention.component';
import { MakeTheInterventionComponent } from './make-the-intervention/make-the-intervention.component';
import { FindThePhraseInterventionComponent } from './find-the-phrase-intervention/find-the-phrase-intervention.component';
import { FillInBlankInterventionComponent } from './fill-in-blank-intervention/fill-in-blank-intervention.component';
import { FindTheRhymeInterventionComponent } from './find-the-rhyme-intervention/find-the-rhyme-intervention.component';
import { FindTheWordSyllablesInterventionComponent } from './find-the-word-syllables-intervention/find-the-word-syllables-intervention.component';
import { FindTheSyllablesInterventionComponent } from './find-the-syllables-intervention/find-the-syllables-intervention.component';
import { ReadTheWordInterventionComponent } from './read-the-word-intervention/read-the-word-intervention.component';
import { TrialChipComponent } from './trial-counter/chip/chip.component';
import { ChangeTheMeaningInterventionComponent } from './change-the-meaning-intervention/change-the-meaning-intervention.component';
import { ReadTheSentenceInterventionComponent } from './read-the-sentence-intervention/read-the-sentence-intervention.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    VerifyTheBlankComponent,
    TrialCounterComponent,
    SaveDataDialogComponent,
    TaskBarComponent,
    TotalPointsComponent,
    VerifyTheBlankInterventionComponent,
    TryAgainDialogComponent,
    TrialTimerBarComponent,
    ChangeTheWordInterventionComponent,
    ChangeTheBlankComponent,
    FillInBlankComponent,
    FindThePictureComponent,
    FindTheRhymeComponent,
    PassagesReadAndListenComponent,
    FindThePictureInterventionComponent,
    SpellTheWordInterventionComponent,
    SpellingInterventionComponent,
    AnswerTheQuestionInterventionComponent,
    MakeTheInterventionComponent,
    FindThePhraseInterventionComponent,
    FillInBlankInterventionComponent,
    FindTheRhymeInterventionComponent,
    FindTheWordSyllablesInterventionComponent,
    FindTheSyllablesInterventionComponent,
    TrialChipComponent,
    ChangeTheMeaningInterventionComponent,
    ReadTheWordInterventionComponent,
    ReadTheSentenceInterventionComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule
    ]
})
export class TaskModule { }
