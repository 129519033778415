import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import * as Sentry from '@sentry/angular';

import { AudioPlayerService } from './audio-player.service';
import { StudentDataService } from './student-data.service';

const SLEEP_INTERVAL_MILLIS: number = 3 * 1000;
const INACTIVITY_TIMEOUT_MILLIS: number = 2 * 60 * 1000;
const SLEEP_TIMEOUT_MILLIS: number = 4 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class SessionTimerService {
  triggerLogout$: Subject<boolean> = new Subject();

  private logoutTimer: any = null;
  private inactivityTimer: any = null;
  private sleepInterval: any = null;
  private lastTime: number = Date.now();

  constructor(
    private audioPlayerService: AudioPlayerService,
    private studentDataService: StudentDataService,
  ) {}

  private triggerLogout() {
    this.triggerLogout$.next(true);
  }

  private startSleepTimer() {
    if (this.sleepInterval == null)
    {
      this.lastTime = Date.now();
    }

    let currentTime: number = Date.now();
    let sessionExpiration = this.studentDataService.getSessionExpirationTime();
    if (sessionExpiration != null && currentTime > sessionExpiration)
    {
      this.triggerLogout();
    }
    else
    {
      this.sleepInterval = setInterval(() => {
        currentTime = Date.now();
        if (currentTime > (this.lastTime + SLEEP_TIMEOUT_MILLIS))
        {
          this.studentDataService.clearUnsavedData();
          console.log('Logout triggered by sleep timer at: ' + new Date());
          this.triggerLogout();
        }
        this.lastTime = currentTime;
      }, SLEEP_INTERVAL_MILLIS);
    }
  }

  private cancelSleepTimer() {
    if (this.sleepInterval != null)
    {
      clearInterval(this.sleepInterval);
      this.sleepInterval = null;
    }
  }

  private cancelInactivityTimers() {
    if (this.inactivityTimer != null) {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = null;
    }
    if (this.logoutTimer != null) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
  }

  private resetInactivityTimer() {
    this.cancelInactivityTimers();
    this.inactivityTimer = setTimeout(() => {
        // Play the audio to tell the user to do something
        this.audioPlayerService.play('Audio/Help/inactivity.mp3')
          .subscribe({
            error: (err) => {
              console.error('Unable to play audio for inactivity');

              Sentry.captureException(err, {
                tags: {
                  section: 'session-timer',
                  action: 'inactivity-audio'
                }
              })
            }
          });
        // Start the second timer that will log the user out
        this.startInactivityLogoutTimer();
    }, INACTIVITY_TIMEOUT_MILLIS);
  }

  private startInactivityLogoutTimer() {
    this.logoutTimer = setTimeout(() => {
      console.log('Logout triggered from inactivity at: ' + new Date());
      this.triggerLogout();
    }, INACTIVITY_TIMEOUT_MILLIS);
  }

  //
  // Start of public methods

  /**
   * Start the inactivity timer
   */
  startInactivityTimer() {
    this.resetInactivityTimer();
  }

  /**
   * Start the sleep and the session timer services.
   */
  startSleepAndInactivityTimers() {
    // Start up Inactivity Timer for non-demo user
    if (!this.studentDataService.isDemoUser())
    {
      this.startInactivityTimer();
    }

    // Start up Sleep Timer
    this.cancelSleepTimer();
    this.startSleepTimer();
  }

  /**
   * Sets the session expiration time into session storage and
   * starts the inactivity timer and the sleep timer services.
   * @param sessionLength the student session length in minutes
   */
  startTimers(sessionLength: number) {
    // Set session expiration time users that need it.
    if (sessionLength > 0)
    {
      // Convert minutes to milliseconds
      let expired = new Date(Date.now() + sessionLength * 60000);
      this.studentDataService.setSessionExpirationTime(expired.getTime());
    }
    else
    {
      this.studentDataService.setSessionExpirationTime(null);
    }

    this.startSleepAndInactivityTimers();
  }

  hasTimerExpired(): boolean {
    if (this.isSessionTimerRunning())
    {
      let now = Date.now();
      return now > this.studentDataService.getSessionExpirationTime()!;
    }
    else
    {
      return false;
    }
  }

  isSessionTimerRunning(): boolean {
    return this.studentDataService.getSessionExpirationTime() != null;
  }

  /**
   * Cancel the sleep and inactivity timers.
   */
  cancelTimers() {
    this.cancelInactivityTimers();
    this.cancelSleepTimer();
  }
}
