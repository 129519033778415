import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial, Tile } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { WordSpaces, ResponseTile } from './make-the-intervention.model';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'make-the-intervention',
  templateUrl: './make-the-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MakeTheInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
    public router: Router,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    // Get starting points for the total points cloud\
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
  }

  ngAfterViewInit(): void {
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe(() => {
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
      .pipe(first(),
        map(() => {
          let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
          timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
        }),
        concatMap(() => {
          if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
            this.playInstructionalAudio = false;
            return this.instructions.playInstructionalVideo();
          }
          else {
            return of({});
          }
        }),
        // NOTE: we always play the instructional audio on the first trial, so only check for video here
      )
      .subscribe({
        complete: () => this.defaultAudioCompleteFunc(),
        error: () => this.defaultAudioCompleteFunc(),
      });
    })

    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  NO_BLANK_TILE: number = 99;
  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  incorrectFirstResponse: boolean = false;
  showResponseOptions3: boolean = true;

  oneIncorrectAnswer: boolean = false;
  audioList: string[] = [];
  targetWord: WordSpaces[] = [];
  blankTile: number = 0;
  hideSubmitButton: boolean = true;
  disableTile: boolean = true;
  disableSubmitButton: boolean = true;
  makeThePhrase: boolean = false;
  targetAnswer: string = '';
  responseOptions: ResponseTile[] = [];
  responseOptions1: ResponseTile[] = [];
  responseOptions2: ResponseTile[] = [];
  responseOptions3: ResponseTile[] = [];

  // Variable to prevent multiple responses from being clicked which would change the student's answer
  responsesDisabled: boolean = true;

  // Update the total points on $scope (generally used as a callback to the interventionTaskService)
  updateTotalPoints(points: number) {
    this.taskTotalPoints += points;
  }

  removeHoverClass(el: HTMLElement) {
    el.classList.remove('hover');
  }

  addHoverClass = function(el: HTMLElement) {
    el.classList.add('hover');
  }

  enableIfFull() {
    this.reusableTimer = window.setTimeout(() => {
      this.responsesDisabled = this.isTargetAreaFull();
      this.disableAVButtons =  this.isTargetAreaFull();
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  enableButtons() {
    this.reusableTimer = window.setTimeout(() => {
      this.responsesDisabled = this.isTargetAreaFull();
      this.disableAVButtons =  false;
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  // Set up audio for the speaker button
  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.playWordAudio();
  }

  playWordAudio() {
    let audio;

    if (this.trials[this.trialIndex].display){
      audio = this.trials[this.trialIndex].word['@audio'];
    }
    else {
      audio = this.trials[this.trialIndex].phrase!['@audio'];
    }
    this.audioPlayerService.play(audio).subscribe({
      complete: () => this.enableButtons(),
      error: (err: any) => {
        this.enableButtons() ;
        this.logAudioPlaybackSentryError('make-the-intervention', 'play-word-audio', audio, err) ;
      }
    });
  }

  playCorrectWordAudio() {
    let audio;

    if (this.trials[this.trialIndex].display){
      audio = this.trials[this.trialIndex].word['@audio'];
    }
    else{
      audio = this.trials[this.trialIndex].phrase!['@audio'];
    }
    this.audioPlayerService.play(audio).subscribe({
      complete: () => this.enableIfFull(),
      error: (err: any) => {
        this.enableIfFull() ;
        this.logAudioPlaybackSentryError('make-the-intervention', 'play-correct-word-audio', audio, err) ;
      }
    });
  }

  // Set up audio for the speaker button
  playLetterAudio(audioFile: string) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(audioFile).subscribe({
      complete: () => this.enableButtons(),
      error: (err: any) => {
        this.enableButtons() ;
        this.logAudioPlaybackSentryError('make-the-intervention', 'play-letter-audio', audioFile, err) ;
      }
    });
  }

  // Set up audio for the speaker button
  playTargetLetterAudio(index: number) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.targetWord[index].audio).subscribe({
      complete: () => this.enableButtons(),
      error: (err: any) => {
        this.enableButtons() ;
        this.logAudioPlaybackSentryError('make-the-intervention', 'play-target-letter-audio', this.targetWord[index].audio, err) ;
      }
    });
  }

  defaultAudioCompleteFunc() {
    this.reusableTimer = window.setTimeout(() => {
      this.hideSubmitButton = false;
      this.displayTask(this.trialIndex);
    }, 0);
  }

  runFirstIncorrectSequence() {
    this.removeIncorrectTiles(this.trials[this.trialIndex]);
    this.oneIncorrectAnswer = true;
    this.showResponseAudioButtons = true;
    this.changeDetector.markForCheck() ;
    this.playWordAudio();
    this.startTime = this.timerService.startTimer();
  }

  runSecondIncorrectSequence() {
    // Setting timeout function around displayCorrectTargetWord because otherwise the DOM is
    // not updating at the correct time, there is a slight pause before updating.
    this.displayCorrectTargetWord();
    this.playCorrectWordAudio();
    // Perform expected animations and move on to the next trial
    this.reusableTimer = window.setTimeout(() => {
      this.disableAVButtons =  true;
      let responseObject = this.trialList[this.trialList.length - 1];
      this.updateTotalPoints(responseObject.points);
      this.afterUpdate();
    }, this.interventionTaskService.moveToNextTrialDelay);
  }

  initializeTargetWord(correctResponse: any[]) {
    this.targetWord = [];
    // Create a shallow copy of the response list
    let responseList = this.trials[this.trialIndex]['resp-list']!.resp.slice();
    for (let i = 0; i < correctResponse.length; i++) {
      let audio = responseList[i]['@audio'] ?? '';
      this.targetWord.push(new WordSpaces("",true, null, audio));
    }
  }

  // Displays the correct answer in the target area
  displayCorrectTargetWord() {
    let correctResponse;

    if (this.trials[this.trialIndex].display){
      correctResponse = <Tile[]>this.trials[this.trialIndex].display.tile;
    }
    else{
      correctResponse = this.trials[this.trialIndex].phrase!['#text'].split(" ");
    }

    this.initializeTargetWord(correctResponse);
    for(var i = 0; i < correctResponse.length; i++){
      if(this.trials[this.trialIndex].display){
        let response = <Tile>correctResponse[i];
        this.targetWord[i]['text'] = response['#text'];
      }
      else{
        let response = <string>correctResponse[i];
        this.targetWord[i].text = response;
      }
      this.targetWord[i].disabled = true;
    }
    this.changeDetector.markForCheck() ;
  }

  // Loop through the trial list and build the target word and responses for each item, and go to the next one after a click even updates the counter
  displayTask(newIndex: number) {
    this.incorrectFirstResponse = false;
    this.oneIncorrectAnswer = false;
    this.showResponseAudioButtons = false;
    this.disableSubmitButton = true;
    this.trialIndex = newIndex;
    this.changeDetector.markForCheck() ;

    // Plays the trial instructional audio followed by playWordAudio() if on the first trial, otherwise just calls playWordAudio()
    if (this.trialIndex === 0){
      this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
        complete: () => this.playWordAudio(),
        error: () => this.playWordAudio(),
      });
    } else {
      this.playWordAudio();
    }

    this.targetAnswer = '';
    this.responseOptions = [];

    let toMake;
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    if (this.trials[this.trialIndex].display){
      toMake = <Tile[]>this.trials[this.trialIndex].display.tile;
    }
    else
    {
      this.makeThePhrase = true;
      toMake = this.trials[this.trialIndex].phrase!['#text'].split(" ");
    }

    this.initializeTargetWord(toMake);
    this.blankTile = 0;
    this.createResponseList(this.trials[this.trialIndex]);
    this.changeDetector.markForCheck() ;

    this.startTime = this.timerService.startTimer();
  }

  updateTargetTile(index: number, text: string, disabled: boolean, targetIndex: number | null) {
    this.targetWord[index].text = text;
    this.targetWord[index].disabled = disabled;
    this.targetWord[index].targetIndex = targetIndex;
  }

  // Function for what happens when user removes a target tile
  removeLetter(selectedResponse: number) {
    this.disableSubmitButton = true;
    let targetIndex = this.targetWord[selectedResponse].targetIndex!;

    // Remove the data on this tile in the target word area
    this.updateTargetTile(targetIndex, "", true, null);

    this.blankTile = this.blankTileCheck();
  }

  // Function for what happens when user clicks on response tile
  addLetter(selectedResponse: number) {
    // If no blank tiles exist, ignore
    if (this.blankTile === this.NO_BLANK_TILE) {
      return;
    }

    // Put the data on this tile in the empty tile location in the target word area
    let responseOption = this.responseOptions[selectedResponse];
    this.updateTargetTile(this.blankTile, responseOption.text, false, this.blankTile);

    this.blankTile = this.blankTileCheck();
    if (this.blankTile === this.NO_BLANK_TILE){
      this.disableSubmitButton = false;
    }
  }

  submitResponse() {
    // Save the student's response
    if (this.trials[this.trialIndex].display){
      this.targetAnswer = this.trials[this.trialIndex].word['#text'];
    }
    else{
      this.targetAnswer = this.trials[this.trialIndex].phrase!['#text'];
    }
    this.dataTracker.targetAnswer = this.targetAnswer;
    this.responsesDisabled = true;
    this.disableSubmitButton = true;
    this.disableAVButtons = true;
    let response = "";
    // To get integer representation of the student's answer
    let responseBinary = "";
    this.targetWord.forEach((element) => {
      if (this.makeThePhrase && response.length !== 0){response += " ";}
      response += element.text;
      element['disabled'] = true;
      responseBinary += element.text.charCodeAt(0);
    });

    let isCorrect = (this.targetAnswer == response);
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.playSoundEffect(isCorrect);
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, response);
    // NOTE: responseTimes aren't recorded for this task, so use zeros for creating reponse objects
    if (isCorrect){
      this.responsesDisabled = true;
      // If the student missed their first try -- count the trial as incorrect
      let isTrialCorrect = isCorrect && !this.incorrectFirstResponse;
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          0, 0, trialPoints, this.dataTracker, Number(responseBinary));
      if (isTrialCorrect) this.numberOfCorrectTrials++;

      this.trialList.push(responseObject);

      // Perform expected animations and move on to the next trial
      this.taskService.answerTrial(!this.incorrectFirstResponse) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.afterUpdate();
        }
      });
    }
    // 2 incorrect responses
    else if(this.oneIncorrectAnswer){
      this.interventionTaskService.trackResponseTrends(isCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex,
          0, 0, trialPoints, this.dataTracker, Number(responseBinary));
      this.trialList.push(responseObject);

      this.taskService.answerTrial(isCorrect) ;
      // Play "The correct answer is..." followed by resetting the target word, playing word audio, highlighting, etc
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
          complete: () => this.runSecondIncorrectSequence(),
          error: () => this.runSecondIncorrectSequence()
        })
      }, this.interventionTaskService.secondIncorrectDelay);
    }
    // 1 incorrect response
    else{
      this.incorrectFirstResponse = true;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
          complete: () => this.runFirstIncorrectSequence(),
          error: () => this.runFirstIncorrectSequence()
        })
      }, this.interventionTaskService.firstIncorrectDelay);
    }
  }

  blankTileCheck() {
    let blankTileIndex = this.NO_BLANK_TILE;
    for(let i = 0; i < this.targetWord.length; i++){
      if (this.targetWord[i].text == ""){
        blankTileIndex = i;
        break;
      }
    }

    this.responsesDisabled = (blankTileIndex === this.NO_BLANK_TILE);
    return blankTileIndex;
  }

  removeIncorrectTiles(trial: InterventionTrial) {
    let correctResponseArray;

    if (this.trials[this.trialIndex].display){
      correctResponseArray = trial.display.tile.slice();
    }
    else{
      correctResponseArray = trial.phrase!['#text'].split(" ").slice();
    }

    for(let i = 0; i < this.targetWord.length; i++) {
      let correctLetter;
      let responsePart = correctResponseArray[i] as Tile;
      if (responsePart['#text']){
        correctLetter = responsePart['#text'];
      }
      else{
        correctLetter = correctResponseArray[i];
      }

      if (correctLetter !== this.targetWord[i].text) {
        this.removeLetter(i);
      }
      else{
        this.targetWord[i].disabled = false;
      }
    }
  }

  createResponseList(trial: InterventionTrial){
    this.responseOptions = [];
    let index = 0;

    // Create a shallow copy of the response list
    let responseList = trial['resp-list']!.resp.slice();

    // Reduce the number of responses, if needed
    responseList = this.interventionTaskService.reduceResponsesIfNecessary(responseList);

    // Shuffle responses
    responseList = this.interventionTaskService.shuffleResponses(responseList, true);

    // Build the response lists
    for (let i = 0; i < responseList.length; i++) {
      let responseText = responseList[i]['#text'];
      // TODO: may want to consider setting default to be silence audio file?
      // That way there wouldn't be an error if it isn't set, it would just play silence?
      let responseAudio = responseList[i]['@audio'] ?? "";

      this.responseOptions.push(new ResponseTile(index, responseText, responseAudio));
      ++index;
    }

    if (this.responseOptions.length == 4){
      this.responseOptions1 = this.responseOptions.slice(0,2);
      this.responseOptions2 = this.responseOptions.slice(2,4);
      this.showResponseOptions3 = false;
    }
    else if (this.responseOptions.length == 8){
      this.responseOptions1 = this.responseOptions.slice(0,3);
      this.responseOptions2 = this.responseOptions.slice(3,5);
      this.responseOptions3 = this.responseOptions.slice(5,8);
      this.showResponseOptions3 = true;
    }
    else if (this.responseOptions.length == 6){
      this.responseOptions1 = this.responseOptions.slice(0,3);
      this.responseOptions2 = this.responseOptions.slice(3,6);
      this.showResponseOptions3 = false;
    }
    else{
      this.responseOptions1 = this.responseOptions.slice(0,4);
      this.responseOptions2 = this.responseOptions.slice(4,8);
      this.responseOptions3 = this.responseOptions.slice(8,12);
      this.showResponseOptions3 = true;
    }

    this.showResponseAudioButtons = this.interventionTaskService.hasInitialAudioSupport();
  }

  isTargetAreaFull() {
    return (this.blankTile === this.NO_BLANK_TILE);
  }

  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  afterUpdate() {
    this.reusableTimer = window.setTimeout(() => {
    this.trialTimerBar.resetTrialTimer();

    let newIndex = this.trialIndex + 1;
    if (newIndex < this.trials.length) {
      this.displayTask(newIndex);
    } else {
      this.saveTaskData();
    }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error: (err: any) => {
        this.saveDataDialog.showSaveDataDialog() ;
        this.logSaveTaskSentryError('make-the-intervention', err) ;
      }
    });
  }
}
