import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassageBrowserBackGuard  {
  constructor(
    private router: Router,
  ) {}
  canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    // NOTE: This seems like a hack and it would be nice if we  could
    //  reorganize our routes some way to check this better.  We also 
    //  want to disable the back button completely for students in the future
    //  so we might be able to rethink this then.

    // Don't allow navigation if our passage is undefined and we are
    //  coming from passagesReadAndListen and going to one of the sub 
    //  tasks passages tasks.
    let navigationExtras = this.router.getCurrentNavigation()?.extras;
    if ((nextState.url === '/findThePictureIntervention' || nextState.url === '/answerTheQuestionIntervention') 
        && !navigationExtras?.state?.passage && state.url === '/passagesReadAndListen')
    {
      return false;
    }

    return true;
  }
  
}
