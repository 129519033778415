<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element change-the-blank-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? ['replayVideo-no-timerbar'] : ['replayVideo']" (click)="replayVideo()"
        [disabled]="disableAVButtons"></button>
    </div>
    <div class="read-the-word-target-container">
      <button class="speaker speaker-rtw-adjust" [disabled]="isSpeakerButtonDisabled" (click)="playTargetAudioViaSpeakerClick()"></button>
      <div *ngIf="!submitting; else loading" class="target-word-row">
        <div class="target-word-rtw-text">
          {{targetWord}}
        </div>
      </div>
      <div class="recording-button-row-rts">
        <button [ngClass]="(secondRecordingAttempt) ? ['no-button-rts'] : ['retry-button']" [disabled]="isSubmitDisabled" (click)="retryAudioRecording()"></button>
        <button class="submit-rts" [disabled]="isSubmitDisabled" (click)="submitResponse(true)"></button>
      </div>
    </div>
    <div class="recording-playback-container">
      <audio id="player" #player [src]="audioRecordingURL">
        Your browser does not support the audio element.
      </audio>
      <div class="progress-bar-container">
        <div id="progress-bar" class="progress-bar-rtw">
          <div id="progress" #progress class="playback-progress"></div>
        </div>
        <div class="playback-timestamps">
          <span id="current-time" class="time-display">{{ currentTime }}</span>
          <span id="total-time" #totalTimeDisplay class="time-display">{{ durationDisplay }}</span>
        </div>
      </div>
      <div class="recording-playback-button-container inset-container">
        <button [disabled]="isRecordingButtonDisabled" [ngClass]="recordButtonStyle" (click)="startRecording()"></button>
        <button [disabled]="isStopButtonDisabled" [ngClass]="stopButtonStyle" (click)="stopRecording()"></button>
        <button [disabled]="isPlaybackButtonDisabled" [ngClass]="playButtonStyle" (click)="togglePlayback()"></button>
      </div>
    </div>
  </div>

  <trial-counter [showCorrectIncorrect]="false" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>

<ng-template #loading>
  <div class="bouncing-dots-row">
    <div class="bouncing-dots">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</ng-template>
