<div class="viewport-1">
  <student-header #studentHeader></student-header>
  <side-menu #sideMenu (selectedAvatar)="updateSelectedAvatar($event)" [disabled]="disableSideButton"></side-menu>
  <div *ngIf="showAwardedAvatar" class="awarded-avatar-container">
    <img class="winning-team-avatar" [src]="awardedAvatar?.avatar" />
  </div>
  <div id="first-screen" [ngClass]="{ 'screen-hide' : levelTwo }" class="level-select-content-div" [style.background-image]="'url(' + levelSelectBackgroundImageA + ')'">
    <div class="level-div">
        <button
            *ngFor="let level of levelButtonList1; index as i"
            [attr.id]="level.id"
            class="level-btn"
            [ngStyle]="level.style"
            [disabled]="!enableAllButtons || level.disabled" (click)="goToTaskSelect(i)"
            (touchstart)="highlightLevelButton(i)" (touchend)="handleTouchEnd(level, i, $event)"
            (mouseover)="highlightLevelButton(i)" (mouseleave)="unhighlightLevelButton(i)">
        </button>
    </div>
    <button *ngIf="!isDemoUser && !playTransition" id="avatar" class="avatar-div" [ngStyle]="avatarPosition" [disabled]="!enableAllButtons" (click)="goToTaskSelect()"></button>
  </div>
  <div *ngIf="isIntervention" id="second-screen" class="level-select-content-div"
    [ngClass]="{'screen-show' : levelTwo, 'screen-up' : transitionDirection === 'Up', 'screen-down' : transitionDirection === 'Down', 'screen-left' : transitionDirection === 'Left', 'screen-right' : transitionDirection === 'Right'}"
    [style.background-image]="'url(' + levelSelectBackgroundImageB + ')'">
    <div class="level-div">
        <button
            *ngFor="let level of levelButtonList2; index as i"
            [attr.id]="level.id"
            class="level-btn"
            [ngStyle]="level.style"
            [disabled]="!enableAllButtons || level.disabled"
            (click)="goToTaskSelect(i)"
            (touchstart)="highlightLevelButton(i)" (touchend)="handleTouchEnd(level, i, $event)"
            (mouseover)="highlightLevelButton(i)" (mouseleave)="unhighlightLevelButton(i)">
        </button>
    </div>
    <button *ngIf="isIntervention && !isDemoUser" id="avatar" class="avatar-div" [ngStyle]="avatarPosition" [disabled]="!enableAllButtons" (click)="goToTaskSelect()"></button>
  </div>
  <div class="fireworks-panel"></div>
  <congratulations *ngIf="displayCompletedMessage"></congratulations>
  <instructions [videoSource]="videoSource" #instructions></instructions>
  <student-footer #studentFooter timerBackgroundImage="/assets/images/timer/timerLabel-level.svg"></student-footer>
</div>