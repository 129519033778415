import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core';
import { InterventionTaskComponent } from '../intervention-task.component';
import { StudentDataService } from '../../core/services/student-data.service';
import { InterventionTaskService } from '../../core/services/intervention-task.service';
import { ShuffleService } from '../../core/services/shuffle.service';
import { TimerService } from '../../core/services/timer.service';
import { AudioPlayerService } from '../../core/services/audio-player.service';
import { TaskService } from '../../core/services/task.service';
import { Router } from '@angular/router';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { of, Observable, from, timer, Subscription } from 'rxjs';
import { ApplicationStateService } from '../../core/services/application-state.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InterventionTrial } from '../../core/models/task.model';
import { environment } from '../../../environments/environment';
import { THEMES } from '../../core/models/theme.model';
import * as Sentry from '@sentry/angular';
import {Capacitor} from "@capacitor/core";

interface Word {
    word: string;
    punct?: string;
}

@Component({
    selector: 'read-the-sentence-intervention',
    templateUrl: './read-the-sentence-intervention.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadTheSentenceInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('player') player!: ElementRef;
    @ViewChild('progress') progress!: ElementRef;

    submitting: boolean = false;
    aiVendor: string = 'deepgram';
    status: string = '';
    sentenceTypeSrc: string = '' ;
    transcription: string = '';
    confidence: number = 0.0;
    words: any[] = [];
    mediaRecorder: any;
    mimeType: string = '' ;
    recordingExtension: string = '' ;
    chunks: BlobPart[] = [];
    recordedAudio: Blob = new Blob();
    isRecording: boolean = false;
    isPlayingRecording: boolean = false;
    retriedRecordingCount: number = 0;
    audioRecordingURL: SafeResourceUrl = '';
    maxRecordTime: number = (environment.RTS_MAX_RECORD_SEC || 30) * 1000 ;
    maxRecordTimeoutId: number | undefined ;
    mediaStream: MediaStream | null = null ;

    trials: InterventionTrial[] = this.task.trial;
    numberOfCorrectTrials: number = 0;
    firstResponseTime: number = 0;
    secondResponseTime: number = 0;
    originalStartTime: number = 0;
    durationDisplay: string = '0:00';

    trialRecordingStartTime: number = 0;
    trialRecordingEndTime: number = 0;
    currentTargetSentence: Word[] = [];
    transcriptionCorrectness: string[] = [];
    trialAssessments: { matchesTarget: boolean }[] = [] ;

    // button flags
    isReviewingRecording: boolean = true;
    playbackButtonsDisabled: boolean = false;
    secondRecordingAttempt: boolean = false;
    isPlayingSentenceTypeAudio: boolean = false;

    // debugging flags
    showDebug: boolean = false;

    // support flags
    extraSupportNeeded: boolean = false;
    sentenceTypeSupportCount: number = 0; // tracks number of times user used sentence type audio support per trial

    private audioPlayerSubscription: Subscription = new Subscription();

    constructor(
        public studentDataService: StudentDataService,
        public interventionTaskService: InterventionTaskService,
        public shuffleService: ShuffleService,
        public timerService: TimerService,
        public audioPlayerService: AudioPlayerService,
        public taskService: TaskService,
        public router: Router,
        public changeDetector: ChangeDetectorRef,
        public applicationStateService: ApplicationStateService,
        private sanitizer: DomSanitizer,
        private ngZone: NgZone,
    ) {
        super(studentDataService, interventionTaskService, timerService, audioPlayerService, router, changeDetector, applicationStateService);

        // Set our instructional video for the beta tasks
        // FIXME: After the beta period, these instructional audio and video files should be set in the task in the curriculum
        this.instructionalVideoFile = environment.VideoAssetServerURL + '/assets/video/WFVideo_readthesentence.mp4';
    }

    ngOnInit(): void {
        this.currentDestination = this.studentDataService.getCurrentDestination();
        this.extraSupportNeeded = this.studentDataService.getStudentData().extraSupportNeeded;
        this.disableAVButtons = true;

        const MIN_DECIBELS = (Capacitor.getPlatform() === 'ios') ? -100 : -55 ; // minimum decibels to consider as sound activity
        const SILENCE_THRESHOLD = 2000; // stop recording after 2 second of silence

        let soundDetected = false;
        let lastSoundTime = Date.now();
        let recording = false;
        let detectSound: FrameRequestCallback;

        // Shuffle the trials if randomTrials is set
        if (this.task.randomTrials) {
            this.trials = this.shuffleService.shuffleArray(this.trials);
        }

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
                  this.mimeType = 'audio/webm;codecs=opus' ;
                  this.recordingExtension = 'webm' ;
                }
                else if (MediaRecorder.isTypeSupported('audio/mp4;codecs=mp4a')) {
                  this.mimeType = 'audio/mp4;codecs=mp4a' ;
                  this.recordingExtension = 'mp4' ;
                }
                else {
                  // Try a default
                  this.mimeType = 'audio/ogg' ;
                  this.recordingExtension = 'ogg' ;
                }

                this.mediaRecorder = new MediaRecorder(stream, { mimeType: this.mimeType });
                this.mediaStream = stream ;

                const audioContext = new AudioContext();
                const audioStreamSource = audioContext.createMediaStreamSource(stream);
                const analyser = audioContext.createAnalyser();
                analyser.minDecibels = MIN_DECIBELS;
                audioStreamSource.connect(analyser);

                const bufferLength = analyser.frequencyBinCount;
                const domainData = new Uint8Array(bufferLength);

                detectSound = () => {
                    if (!recording) {
                        return;
                    }

                    analyser.getByteFrequencyData(domainData);

                    soundDetected = false;
                    for (let i = 0; i < bufferLength; i++) {
                        if (domainData[i] > 0) {
                            soundDetected = true;
                            break;
                        }
                    }

                    if (soundDetected) {
                        lastSoundTime = Date.now();
                    } else if (Date.now() - lastSoundTime > SILENCE_THRESHOLD) {
                        console.log('Silence detected');
                        this.stopRecording();
                        return;
                    }

                    window.requestAnimationFrame(detectSound);
                };

                this.mediaRecorder.ondataavailable = (e: any) => {
                    // push chunks to chunks array
                    this.chunks.push(e.data);
                };

                this.mediaRecorder.onstart = () => {
                    this.trialRecordingStartTime = this.timerService.startTimer();
                    recording = true;
                    lastSoundTime = Date.now(); // set lastSoundTime when the recording starts
                    window.requestAnimationFrame(detectSound);
                }

                this.mediaRecorder.onstop = () => {
                    recording = false;
                    this.trialRecordingEndTime = this.timerService.stopTimer();
                    this.recordedAudio = new Blob(this.chunks, { type: this.mimeType });
                    this.chunks = [];
                    let audioURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.recordedAudio));
                    this.audioRecordingURL = audioURL;
                    this.player.nativeElement.src = this.audioRecordingURL;
                    this.changeDetector.detectChanges();

                    // update the duration display
                    this.updateDurationDisplay();

                    // Cancel our max record timeout
                    window.clearTimeout(this.maxRecordTimeoutId) ;

                    // begin submission review
                    this.reviewAudioRecording();

                    // Capture this audio trial for later processing
                    from(this.storeAudioRecordingSubmission()).subscribe({
                      error: (err: any) => {
                        console.log('Error saving audio recording submission', err);
                        Sentry.captureException(err);
                      }
                    }) ;
                };
            })
            .catch(err => console.log('Error instantiating media recorder:', err));
    }

    ngAfterViewInit() {
        // After view is initialized wait for task animation to complete and then initialize everything else
        this.taskBar.taskAnimationComplete.pipe(first())
            .subscribe(() => {
                // set this to tell the trial-counter that animation is complete
                this.animationComplete = true;
                this.audioPlayerSubscription = this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt, this.stateParams?.parentTaskId)
                    .pipe(first(),
                        map(() => {
                            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
                            // TODO: OPTIMIZE could probably find a better way to set this.hideTimer and handle timerBarSettings
                            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
                            this.hideTimer = !timerBarSettings.timerBarEnabled
                        }),
                        concatMap(() => {
                          if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
                            this.playInstructionalAudio = false;
                            return this.instructions.playInstructionalVideo();
                          }
                          else {
                            return of({});
                          }
                        }),
                        concatMap(() => {
                            // Hardcode our instructional audio here, traditionally this is set in the curriculum itself but set here for prototyping
                            // elementary easy word list type has phrases with sentences, so play a different instructional audio
                            // NOTE: This is a bit of a hack by testing the current loaded theme, but it is useful in this case. The real solution
                            //     : is to move the instructional audio for RTS/RTW back to the curriculum, where it can be controlled at a fine-grained
                            //     : level like we are doing here
                            if (this.task.wordlistType === 'easy' && (this.studentDataService.getStudentThemeNumber() === THEMES.elementary)) {
                              this.interventionTaskService.instructionalAudioFile = 'Audio/Help/initial_readthesentenceorphrase.mp3';
                            }
                            else {
                              this.interventionTaskService.instructionalAudioFile = 'Audio/Help/initial_readthesentence.mp3';
                            }

                            if (this.playInstructionalAudio) {
                                return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
                            } else {
                                return of({});
                            }
                        }),
                    )
                    .subscribe({
                        complete: () => this.instructionsCompleted(),
                        error: () => this.instructionsCompleted(),
                    });
            });

        if (this.player && this.progress) {
            this.player.nativeElement.addEventListener('timeupdate', () => {
                const progressPercent = this.player.nativeElement.currentTime / this.player.nativeElement.duration;
                this.progress.nativeElement.style.width = `${progressPercent * 100}%`;
            });

            this.player.nativeElement.addEventListener('ended', () => {
                this.isPlayingRecording = false;
                this.changeDetector.markForCheck() ;
            });

            // listen for loading of the metadata to update the duration
            this.player.nativeElement.onloadedmetadata = () => {
                this.updateDurationDisplay();
            };

            this.progress.nativeElement.parentElement.addEventListener('click', (event: MouseEvent) => {
                const rect = this.progress.nativeElement.parentElement.getBoundingClientRect();
                const clickX = event.pageX - rect.left;
                const progressPercent = clickX / this.progress.nativeElement.parentElement.offsetWidth;
                this.player.nativeElement.currentTime = progressPercent * this.player.nativeElement.duration;
            });
        }

        // Display the focus dialog if needs focus is set (from intervention task)
        if (this.needsFocus) {
            this.focusDialog.showDialog();
        }
    }

    ngOnDestroy() {
      super.ngOnDestroy() ;
      this.mediaStream?.getTracks().forEach(track => track.stop()) ;
      this.mediaStream = null ;

      if (this.audioPlayerSubscription) {
        this.audioPlayerSubscription.unsubscribe();
      }
    }

    togglePlayback() {
        if (this.player.nativeElement.paused) {
            this.startPlayback();
        } else {
            this.stopPlayback();
        }
    }

    startPlayback() {
        this.player.nativeElement.play();
        this.isPlayingRecording = true;
        this.changeDetector.markForCheck() ;
    }

    stopPlayback() {
        this.player.nativeElement.pause();
        this.isPlayingRecording = false;
        this.changeDetector.markForCheck() ;
    }

    saveTaskData() {
        this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints, this.numberOfTrials, this.numberOfCorrectTrials, this.attempt, true)
            .pipe(
                mergeMap(() => {
                    let params = this.interventionTaskService.getTaskDataParams();
                    if (params.taskData.length) {
                        return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
                    } else {
                        return of({});
                    }
                })
            ).subscribe({
                next: () => {
                    this.saveDataDialog.hideSaveDataDialog();
                    this.completeTask(this.attempt);
                },
                error: (err: any) => {
                  this.saveDataDialog.showSaveDataDialog() ;
                  this.logSaveTaskSentryError('read-the-sentence-intervention', err) ;
                }
            });
    }

    async storeAudioRecordingSubmission() {
        // Do NOT store audio for demo users
        if (this.studentDataService.isDemoUser()) return ;

        const formData = new FormData();
        const curAudioBlob = this.recordedAudio ;

        formData.append('file', curAudioBlob, `audio_${this.task.id}.${this.recordingExtension}`);
        formData.append('word', this.trials[this.trialIndex].sentence['#text']);
        formData.append('task', this.task.id);
        formData.append('trial', this.trialIndex.toString());
        formData.append('wordlistType', this.task.wordlistType);
        formData.append('attempt', this.secondRecordingAttempt ? '2' : '1') ;
        formData.append('aiVendor', this.aiVendor) ;

        this.interventionTaskService.saveAudioTrialRecording(formData, true).subscribe({
            next: () => {
                console.debug('Saved audio recording submission');
            },
            error: (err: any) => {
                console.error('Error saving audio recording submission', err);
            }
        });
    }

    instructionsCompleted() {
        console.debug('instructions completed, building task');
        this.displayWordTarget(0);
    }

    displayWordTarget(newIndex: number) {
        this.trialIndex = newIndex;

        const currentTrialSentence = this.trials[this.trialIndex].sentence['#text']
        this.currentTargetSentence = currentTrialSentence.split(' ').map((word: string) => {
            // check if the word has punctuation
            const lastChar = word[word.length - 1];
            const hasPunctuation = lastChar.match(/[.,!?]/);
            return {
                word: hasPunctuation ? word.slice(0, -1) : word,
                punct: hasPunctuation ? lastChar : '',
            };
        });
        this.transcriptionCorrectness = new Array(this.currentTargetSentence.length).fill('');

        // set the sentence type of the trial for styling
        if (this.trials[this.trialIndex].sentenceType) {
          switch (this.trials[this.trialIndex].sentenceType!.toLowerCase())
          {
            case 'question':
              this.sentenceTypeSrc = '/assets/images/tasks/sentenceType_Q.svg' ;
              break ;
            case 'phrase':
              this.sentenceTypeSrc = '/assets/images/tasks/sentenceType_C.svg' ;
              break ;
            case 'exclamatory':
              this.sentenceTypeSrc = '/assets/images/tasks/sentenceType_E.svg' ;
              break ;
            case 'declarative':
            case 'declaratory':
              this.sentenceTypeSrc = '/assets/images/tasks/sentenceType_D.svg' ;
              break ;
            default:
              this.sentenceTypeSrc = '/assets/images/tasks/sentenceType_D.svg' ;
          }
        }
        else
        {
          this.sentenceTypeSrc = '/assets/images/tasks/sentenceType_D.svg' ;
        }

        const audio = this.trials[this.trialIndex].sentence['@audio'];
        // create a new data tracker object
        this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();
        this.changeDetector.detectChanges();

        // Initial audio given for "easy" wordlists or if the student needs extra support
        if ((this.task.wordlistType === 'easy' || this.extraSupportNeeded) && !this.secondRecordingAttempt) {
            this.audioPlayerSubscription = this.audioPlayerService.play(audio).subscribe({
                complete: () => this.startTrial(),
                error: () => this.startTrial(),
            });
        } else {
            this.startTrial();
        }

        this.changeDetector.detectChanges();
    }

    startTrial() {
        // Allow user to submit a response to the trial
        this.reusableTimer = window.setTimeout(() => {
            this.disableAVButtons = false;
            this.isReviewingRecording = false;
            this.changeDetector.detectChanges();
        }, 0);

        this.originalStartTime = this.timerService.startTimer();
        this.startTime = this.timerService.startTimer();
        let timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
        if (timerBarTaskSettings.timerBarEnabled) {
            let initialDelay = this.interventionTaskService.trialBarBaseDelay + timerBarTaskSettings.timerBarDelay;
            this.trialTimerBar.startTrialTimer(timerBarTaskSettings.timerBarSpeed, initialDelay);
        }
    }

    startRecording() {
        this.mediaRecorder.start();
        this.isRecording = true;
        this.progress.nativeElement.style.width = '0%';

        this.maxRecordTimeoutId = window.setTimeout(() => {
          this.stopRecording() ;
        }, this.maxRecordTime) ;
    }

    stopRecording() {
        this.isRecording = false;
        setTimeout(() => {
          this.mediaRecorder.stop();
        }, 250);

      this.isReviewingRecording = true;
      this.changeDetector.markForCheck();
    }

    submitResponse(matchesTarget: boolean) {
      this.submitting = true ;
      this.playbackButtonsDisabled = false;
      this.changeDetector.markForCheck() ;

      this.trialAssessments.push({ matchesTarget }) ;

      // Capture our data for this trial
      this.endTime = this.timerService.stopTimer();
      if (this.secondRecordingAttempt) {
        this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      }
      else {
        this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
        this.secondResponseTime = 0;
      }

      // Set data tracker info, with ASR specific data
      this.dataTracker.targetAnswer = this.trials[this.trialIndex].sentence['#text'] ;
      this.dataTracker.asrTrialData = {
        sentenceType: this.trials[this.trialIndex].sentenceType,
        sentenceStructure: this.trials[this.trialIndex].sentenceStructure,
        numWords: this.trials[this.trialIndex].numWords,
        sentenceTypeSupportCount: this.sentenceTypeSupportCount,
        studentAssessment: this.trialAssessments,
      } ;

      // Provide feedback to the student - neutral sound and trial indicator, all points
      let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(true);
      let trialPoints = this.trialTimerBar.getPoints();
      this.audioPlayerService.play('Audio/Help/SNDneutral.mp3').subscribe();
      this.taskService.answerTrial(false);
      this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, (matchesTarget) ? 'agree' : 'disagree');
      this.interventionTaskService.trackResponseTrends(true);
      let responseObject = this.interventionTaskService.createTrialResponseObject(
        !this.secondRecordingAttempt,
        this.trialIndex,
        this.firstResponseTime,
        this.secondResponseTime,
        trialPoints,
        this.dataTracker
      );
      this.numberOfCorrectTrials++;
      this.trialList.push(responseObject) ;

      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.afterUpdate();
        }
      });

      this.audioRecordingURL = '' ;
      this.submitting = false ;
      this.playbackButtonsDisabled = false ;
      this.sentenceTypeSupportCount = 0 ;
      this.changeDetector.detectChanges() ;
    }

    retryAudioRecording() {
      this.retriedRecordingCount++ ;
      this.isReviewingRecording = false;
      this.playbackButtonsDisabled = false;
      this.resetPlayer() ;
      this.changeDetector.detectChanges();
      this.audioRecordingURL = '';

      // The student can only retry a recording once, so if we are already on the second recording attempt, submit
      if (this.secondRecordingAttempt) {
        this.submitResponse(false) ;
      }
      else {
        this.secondRecordingAttempt = true;
        this.trialAssessments.push({ matchesTarget: false }) ;
        this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, 'disagree');
        this.startTrial();
      }
    }

    reviewAudioRecording() {
        this.audioPlayerSubscription = this.audioPlayerService.play('Audio/Help/initial_yourecorded.mp3')
            .pipe(
                // this.player.nativeElement.play();
                concatMap(() => new Observable(observer => {
                    this.player.nativeElement.onended = () => {
                        observer.next();
                        observer.complete();
                    };
                    this.player.nativeElement.play();
                })),
                concatMap(() => this.audioPlayerService.play('Audio/Help/initial_doesitsoundthesameas.mp3')),
                concatMap(() => timer(500)),
                concatMap(() => this.audioPlayerService.play(this.trials[this.trialIndex].sentence['@audio'])),
                concatMap(() => timer(150)),
                concatMap(() => {
                  if (this.trialIndex === 0 && !this.secondRecordingAttempt)
                  {
                    return this.audioPlayerService.play('Audio/Help/initial_tryagain.mp3') ;
                  }
                  else if (this.secondRecordingAttempt && this.retriedRecordingCount === 1)
                  {
                    return this.audioPlayerService.play('Audio/Help/initial_readsecondattempt.mp3') ;
                  }
                  else
                  {
                    return of(null) ;
                  }
                }),
            )
            .subscribe({
                complete: () => {
                    setTimeout(() => {
                        this.isReviewingRecording = false;
                        this.playbackButtonsDisabled = true;
                        this.changeDetector.detectChanges();
                    });
                },
                error: (err: any) => {
                  this.logAudioPlaybackSentryError('read-the-sentence-intervention', 'review-audio-recording', err) ;
                  setTimeout(() => {
                    this.isReviewingRecording = false;
                    this.playbackButtonsDisabled = true;
                    this.changeDetector.detectChanges();
                  });
                }
            });
    }

    afterUpdate() {
        this.reusableTimer = window.setTimeout(() => {
            this.trialTimerBar.resetTrialTimer();

            this.audioRecordingURL = '';
            let newIndex = this.trialIndex + 1;
            if (newIndex < this.trials.length) {
                this.trialIndex = newIndex;
                this.secondRecordingAttempt = false;
                this.trialAssessments = [] ;
                this.resetPlayer() ;
                this.displayWordTarget(newIndex);
            } else {
                this.saveTaskData();
            }
        }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
    }

    playTargetAudioViaSpeakerClick() {
        this.dataTracker.requestSupport++;
        this.audioPlayerService.play(this.trials[this.trialIndex].sentence['@audio']).subscribe({
          error: (err: any) => this.logAudioPlaybackSentryError('read-the-sentence-intervention', 'play-target-audio', this.trials[this.trialIndex].sentence['@audio'], err),
        });
    }

    playSentenceTypeAudio() {
      let sentenceTypeAudio: string | undefined;
      if (this.trials[this.trialIndex].sentenceType) {

        this.isPlayingSentenceTypeAudio = true;
        switch (this.trials[this.trialIndex].sentenceType!.toLowerCase())
        {
          case 'question':
            sentenceTypeAudio = 'Audio/Help/sentenceType_question.mp3' ;
            break ;
          case 'exclamatory':
            sentenceTypeAudio = 'Audio/Help/sentenceType_exclamatory.mp3' ;
            break ;
          case 'declarative':
          case 'declaratory':
            sentenceTypeAudio = 'Audio/Help/sentenceType_declarative.mp3' ;
            break ;
        }
      }

      this.sentenceTypeSupportCount++;

      if (sentenceTypeAudio) {
        this.audioPlayerService.play(sentenceTypeAudio).subscribe({
            complete: () => {
                this.ngZone.run(() => {
                    this.isPlayingSentenceTypeAudio = false;
                    this.changeDetector.markForCheck();
                });
            },
            error: (err: any) => {
                this.ngZone.run(() => {
                    this.isPlayingSentenceTypeAudio = false;
                    this.logAudioPlaybackSentryError('read-the-sentence-intervention', 'play-sentence-type-audio', sentenceTypeAudio, err);
                    this.changeDetector.markForCheck();
                });
            }
        });
      }
    }

    formatTime(seconds: number): string {
        if (!isFinite(seconds)) {
            return '0:00';
        }

        const minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    updateDurationDisplay(): void {
        this.durationDisplay = this.trialRecordingStartTime === 0 ? '0:00' : this.formatTime(Math.floor((this.trialRecordingEndTime - this.trialRecordingStartTime) / 1000));
        this.changeDetector.markForCheck();
    }

    private resetPlayer() {
      this.player.nativeElement.currentTime = 0;
      this.progress.nativeElement.style.width = '0%';
      this.durationDisplay = '0:00';
    }

    get currentTime(): string {
        return this.formatTime(this.player ? Math.floor(this.player.nativeElement.currentTime) : 0);
    }

    get isSpeakerButtonDisabled(): boolean {
        return this.isReviewingRecording || this.submitting || this.isPlayingSentenceTypeAudio || this.isRecording || this.isPlayingRecording;
    }

    get isSentenceTypeButtonDisabled(): boolean {
        return this.isRecording || this.isPlayingRecording || this.isPlayingSentenceTypeAudio || this.isReviewingRecording || this.submitting;
    }

    get isRecordingButtonDisabled(): boolean {
        return this.isReviewingRecording || this.playbackButtonsDisabled || this.submitting || this.isPlayingSentenceTypeAudio;
    }

    get isSubmitDisabled(): boolean {
        return this.isReviewingRecording || this.submitting || !this.audioRecordingURL || this.isPlayingSentenceTypeAudio;
    }

    get isPlaybackButtonDisabled(): boolean {
        return this.isReviewingRecording || this.submitting || !this.audioRecordingURL || this.isPlayingSentenceTypeAudio;
    }

    get isStopButtonDisabled(): boolean {
        return this.isReviewingRecording || this.playbackButtonsDisabled || this.submitting || this.isPlayingSentenceTypeAudio;
    }

    get recordButtonStyle(): string {
        if (this.isRecordingButtonDisabled) {
            return 'record-button-disabled';
        } else {
            return this.isRecording ? 'record-button-stop' : 'record-button';
        }
    }

    get stopButtonStyle(): string {
        return this.isRecording ? 'recording-playback-button-stop' : 'recording-playback-button-stop-disabled';
    }

    get playButtonStyle(): string {
        return this.isPlayingRecording
            ? (this.isPlaybackButtonDisabled ? 'recording-playback-button-pause-disabled' : 'recording-playback-button-pause')
            : (this.isPlaybackButtonDisabled ? 'recording-playback-button-disabled' : 'recording-playback-button');
    }
}
