<div class="diagnosticproduct-student-header-taskbar" [ngClass]="{'hide-overflow' : animationComplete}" [ngStyle]="{'background': ' ' + taskBarColor + ' '}">
  <div class='task_bar_title font-24'>{{title}}</div>
  <total-points [totalPoints]="totalPoints"></total-points>
  <button class='task_bar_button' [ngClass]="{'back_button_animate': isPulse}" (click)="goBack()" [disabled]="disableBackButton"
    *ngIf="backButtonVisible"></button>
  <div class='task_bar_right_container' *ngIf="taskBarTaskDetailsVisible">
    <img [src]=taskIcon class="task_bar_task_icon task_bar_task_icon_height">
    <div class='vertical_text_container'>
      <p class="task_bar_task_name font-task-bar-name" [ngClass]="{ 'long' : taskName.length >= 20 }">{{taskName}}</p>
      <p class="task_bar_task_subtext font-13">{{taskSubtext}}</p>
    </div>
  </div>
</div>
