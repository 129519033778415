import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { StudentData } from 'src/app/core/models/student-data.model';
import { AssetPreloaderService } from 'src/app/core/services/asset-preloader.service';
import { CurriculumService } from 'src/app/core/services/curriculum.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { ThemeParseService } from 'src/app/core/services/theme-parse.service';
import { LoginService } from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})

export class WorldResolver  {
  constructor(
    private assetPreloaderService: AssetPreloaderService,
    private curriculumService: CurriculumService,
    private loginService: LoginService,
    private studentDataService: StudentDataService,
    private themeService: ThemeParseService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let allTasksWereJustCompleted = this.studentDataService.areAllTasksComplete();
    let midOrPostJustCompleted = allTasksWereJustCompleted && this.studentDataService.isFullProductSubscription() &&
        (this.studentDataService.isMidDiagnostic() || this.studentDataService.isPostDiagnostic());
    let nextAssessmentComplete = false;

    let worldResolveData$;
    if (allTasksWereJustCompleted)
    {
      worldResolveData$ = this.studentDataService.fetchStudentData().pipe(
        catchError((err => {
          // An error was happened fetching data, log out then
          //  rethrow the error to stop the chain.
          return this.loginService.studentLogout().pipe(
            switchMap(() => { throw(err); })
          );
        })),
        mergeMap((res: any) => {
          this.onStudentDataSuccess(res);

          let loadTheme$ = this.themeService.loadThemeProperties();
          let loadCurriculum$ = this.curriculumService.loadCurriculum();
          let preloadImages$ = this.resolveImages();

          nextAssessmentComplete = res?.studentAssessment?.isComplete || false;
          let worldData$ = of({
            allTasksWereJustCompleted,
            midOrPostJustCompleted,
            nextAssessmentComplete,
          });

          return forkJoin({
            theme: loadTheme$,
            curriculum: loadCurriculum$,
            worldData: worldData$,
            images: preloadImages$
          });
        }),
      );
    }
    else
    {
      worldResolveData$ = this.resolveImages().pipe(
        switchMap(() => {
          return of({
            worldData: {
              allTasksWereJustCompleted,
              midOrPostJustCompleted,
              nextAssessmentComplete,
            }
          })
        })
      );
    }

    return worldResolveData$;
  }

  resolveImages() {
    let imageList = [];
    imageList.push(this.studentDataService.getWorldViewBackground());
    imageList.push(this.studentDataService.getWorldViewActiveLocationBackground());
    if (this.studentDataService.isPreDiagnosticCompleted())
    {
      imageList.push(this.studentDataService.getWorldViewPreDiagnosticOverlay());
    }
    if (this.studentDataService.isMidDiagnosticCompleted())
    {
      imageList.push(this.studentDataService.getWorldViewMidDiagnosticOverlay());
    }
    if (this.studentDataService.isPostDiagnosticCompleted())
    {
      imageList.push(this.studentDataService.getWorldViewPostDiagnosticOverlay());
    }

    return this.assetPreloaderService.preloadImages(imageList);
  }

  private onStudentDataSuccess(studentData: StudentData) {
    this.studentDataService.setStudentData(studentData);
    this.studentDataService.setCurrentDestination(0);
    this.curriculumService.clearCurriculum();
  }
}
