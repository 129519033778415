<div class="viewport-1" [ngStyle]="{ 'background-image': 'url(' + interventionTaskService.taskBackgroundImage() + ')' }">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <!-- MAIN CONTAINER -->
  <div class="task-screen-element ctm-main-container">
    <!-- RESPONSE CONTAINER -->
    <div class="task-screen-element ctm-response-container"
         [ngStyle]="{ 'background-image':' ' + interventionTaskService.taskContainerColor() + ' ' }"
         [ngClass]="animationComplete ? ['taskShow'] : ['taskHide']"
    >
      <div class="testType font-18">{{ interventionTaskService.testType() }}</div>
      <div class="timer-bar-container">
        <trial-timer-bar></trial-timer-bar>
        <button [ngClass]="hideTimer ? ['replayVideo-no-timerbar'] : ['replayVideo']" (click)="replayVideo()"></button>
      </div>
      <div class="ctm-parts-container">
        <button class="speaker"
          (click)="playTargetAudio()"
          [disabled]="disableAVButtons"
        ></button>
        <div class="ctm-areas">
          <div class="ctm-prefix-suffix-width" [ngStyle]="{'visibility': (prefixResponseOptions.length) ? 'visible' : 'hidden'}">
            <div class="ctm-prefix-text">PREFIX</div>
            <div class="ctm-prefix-tile font-30" [ngStyle]="{'border': '3px solid ' + interventionTaskService.highlightColor() }">
              {{ prefixTile }}
            </div>
            <button class="prefix-suffix-target-speaker-button" (click)="playSpecifiedAudio(correctPrefixAudio)" [disabled]="disableAVButtons" *ngIf="prefixAttempts"></button>
            <button class="ctm-remove-suffix-prefix" [ngClass]="{ 'fully-transparent': !prefixTile }" (click)="removeMorpheme('prefix')"></button>
          </div>
          <div class="ctm-root-word-width">
            <div class="ctm-base-root-text">BASE</div>
            <div class="ctm-root-word-area font-30">
              {{ rootWordTile }}
            </div>
            <button class="prefix-suffix-target-speaker-button"
                    (click)="playSpecifiedAudio(correctBaseAudio)"
                    [disabled]="disableAVButtons"
                    *ngIf="prefixAttempts || suffixAttempts"
            ></button>
          </div>
          <div class="ctm-prefix-suffix-width" [ngStyle]="{'visibility': (suffixResponseOptions.length) ? 'visible' : 'hidden'}">
            <div class="ctm-suffix-text">SUFFIX</div>
            <div class="ctm-suffix-tile font-30" [ngStyle]="{'border': '3px solid ' + interventionTaskService.highlightColor() }">
              {{ suffixTile }}
            </div>
            <button class="prefix-suffix-target-speaker-button" (click)="playSpecifiedAudio(correctSuffixAudio)" [disabled]="disableAVButtons" *ngIf="suffixAttempts"></button>
            <button class="ctm-remove-suffix-prefix" [ngClass]="{ 'fully-transparent': !suffixTile }" (click)="removeMorpheme('suffix')"></button>
          </div>
        </div>
        <div class="ctm-star-container" *ngIf="showSubmitButton">
          <button class="ctm-star-submit"
                  (click)="submitResponse('change')"
                  [disabled]="disableSubmitButton"
                  [ngClass]="{ 'semi-transparent': disableSubmitButton }">
          </button>
        </div>
      </div>
      <div class="ctm-lower-container">
        <div class="ctm-prefixes-select-container" [ngStyle]="{'visibility': (prefixResponseOptions.length) ? 'visible' : 'hidden'}">
          <div class="ctm-prefix-select-container-header">PREFIXES</div>
          <div class="ctm-space-around-column">
            <div *ngFor="let option of prefixResponseOptions" class="prefix-suffix-select-options-container">
              <button class="prefix-suffix-select-options-text font-30"
                   (click)="selectPrefixSuffix(option.index, 'prefix')"
                   [ngClass]="{ 'selected': option.highlight }"
                   [ngStyle]="{ 'border-color': interventionTaskService.highlightColor() }"
                   [disabled]="disableResponseButtons"
              >
                {{ option.text }}
              </button>
              <button class="prefix-suffix-speaker-button" (click)="playSpecifiedAudio(option.audio!)" [disabled]="disableAVButtons" *ngIf="prefixAttempts"></button>
              <button class="prefix-suffix-info-button" (click)="playSpecifiedAudio(option.definition)"></button>
            </div>
          </div>
        </div>
        <div class="ctm-base-root-select-container" [ngClass]="{ 'semi-transparent': !baseWordResponseOptions.length || baseWordTrialComplete}">
          <div class="ctm-base-root-select-container-header">BASE</div>
          <div class="ctm-space-around-column">
            <div *ngFor="let option of baseWordResponseOptions" class="prefix-suffix-select-options-container">
              <button class="base-root-select-options font-30"
                      (click)="selectBaseAndSubmit(option.index)"
                      [ngStyle]="{ 'border-color': interventionTaskService.highlightColor() }"
                      [ngClass]="{
                        'base-root-select-options-selected': option.highlight,
                        'fully-transparent': (currentTrial.morpheme !== 'base'),
                      }"
                      [disabled]="disableResponseButtons || (currentTrial.morpheme !== 'base')"
              >
                {{ option.text }}
              </button>
              <button class="prefix-suffix-speaker-button" (click)="playSpecifiedAudio(option.audio!)" [disabled]="disableAVButtons" *ngIf="baseAttempts"></button>
            </div>
          </div>
        </div>
        <div class="ctm-suffixes-select-container" [ngStyle]="{'visibility': (suffixResponseOptions.length) ? 'visible' : 'hidden'}">
          <div class="ctm-suffix-select-container-header">SUFFIXES</div>
          <div class="ctm-space-around-column">
            <div *ngFor="let option of suffixResponseOptions" class="prefix-suffix-select-options-container">
              <button class="prefix-suffix-select-options-text font-30"
                   (click)="selectPrefixSuffix(option.index, 'suffix')"
                   [ngClass]="{ 'selected': option.highlight }"
                   [ngStyle]="{ 'border-color': interventionTaskService.highlightColor() }"
                   [disabled]="disableResponseButtons"
              >
                {{ option.text }}
              </button>
              <button class="prefix-suffix-speaker-button" (click)="playSpecifiedAudio(option.audio!)" [disabled]="disableAVButtons" *ngIf="suffixAttempts"></button>
              <button class="prefix-suffix-info-button" (click)="playSpecifiedAudio(option.definition)"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DICTIONARY CONTAINER -->
    <div class="task-screen-element ctm-dictionary-container"
         [ngStyle]="{ 'background-image': ' ' + interventionTaskService.taskContainerColor() + ' ' }"
         [ngClass]="animationComplete ? ['taskShow'] : ['taskHide']"
    >
      <div class="ctm-dictionary-sections" [ngClass]="{ 'find' : this.currentTrial.morpheme === 'base', 'change': this.currentTrial.morpheme !== 'base' }">
        <div class="ctm-dictionary-top">
          <button class="ctm-dictionary-speaker-button"
                  (click)="playDictionaryAudio('top')"
                  [disabled]="disableAVButtons"
          ></button>
          <div class="root-word-dictionary-top-left">
            <div class="root-word-top-dictionary-word font-20" [ngClass]="{ 'fully-transparent': !correctBaseResponse }">
              {{ correctBaseResponse ? correctBaseResponse!.text : '' }}
            </div>
            <div class="root-word-top-dictionary-definition font-20">
              <span *ngIf="currentTrial['dictionary-list']">{{ currentTrial["dictionary-list"].dictionary[1]["#text"] }}</span>
            </div>
          </div>

          <div class="root-word-dictionary-top-right" *ngIf="topDictionaryImageURL">
            <img src="{{ topDictionaryImageURL }}" class="root-word-bottom-image-placeholder"/>
          </div>
        </div>
        <div class="ctm-dictionary-bottom">
          <button
            class="ctm-dictionary-speaker-button"
            (click)="playDictionaryAudio('bottom')"
            *ngIf="currentTrial.morpheme !== 'base'"
            [disabled]="disableAVButtons"
          ></button>
          <div class="ctm-dictionary-bottom-left">
            <div class="root-word-top-dictionary-word font-20"
                 *ngIf="currentTrial.morpheme !== 'base'"
                 [ngClass]="{ 'fully-transparent': (suffixAttempts === 0 && prefixAttempts === 0 && !prefixSuffixResponseCorrect) }"
            >
              <span *ngIf="currentTrial['dictionary-list']">{{ currentTrial["dictionary-list"].dictionary[2]["#text"] }}</span>
            </div>
            <div class="root-word-bottom-dictionary-definition font-20" *ngIf="currentTrial.morpheme !== 'base'">
              <span *ngIf="currentTrial['dictionary-list']">{{ currentTrial["dictionary-list"].dictionary[3]["#text"] }}</span>
            </div>
          </div>
          <div class="ctm-dictionary-bottom-right" *ngIf="bottomDictionaryImageURL">
            <img src="{{ bottomDictionaryImageURL }}" class="root-word-bottom-image-placeholder"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>
