export class ResponseTile {
  public text: string;
  public audio: string;
  public highlight: boolean;
  public isCorrect: boolean;
  constructor(text: string, audio: string, highlight: boolean, isCorrect: boolean) {
    this.text = text;
    this.audio = audio;
    this.highlight = highlight;
    this.isCorrect = isCorrect;
  }
}
