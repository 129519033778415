<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element fill-in-blank-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <button class="open-passage" [disabled]="disableAVButtons" (click)="togglePassage()"></button>
      <div class="passage-title font-27"> {{passageTitle}} </div>
      <trial-timer-bar></trial-timer-bar>
      <button class="replayVideo-no-timerbar" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    </div>
    <div class="question-area">
      <button class="speaker" (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons" *ngIf="targetQuestionAudioVisible"></button>
      <div class="answer-the-question-target font-25">
        {{targetQuestion}}
      </div>
    </div>
    <div class="answer-the-question-response-row">
      <div class="answer-the-question-big-button-div" *ngFor="let response of responseOptions1; index as $index">
        <button class = "answer-the-question-div-letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "answer-the-question-div-letter-audio" *ngIf="showResponseAudioButtons" (click)="playWordAudio(response.audio)" [disabled]="disableAVButtons"></button>
        <button class = "make-the-response-block font-25"
          [ngClass]="{'hover': response.highlight}"
          (click)="submitResponse(response.index)"
          (touchstart)="addHoverClass(response)" (touchend)="removeHoverClass(response)"
          (mouseenter)="addHoverClass(response)" (mouseleave)="removeHoverClass(response)"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="disableResponseButtons">
          {{response.text}}
        </button>
      </div>
    </div>
    <div class="answer-the-question-response-row">
      <div class="answer-the-question-big-button-div" *ngFor="let response of responseOptions2; index as $index">
        <button class = "answer-the-question-div-letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "answer-the-question-div-letter-audio" *ngIf="showResponseAudioButtons" (click)="playWordAudio(response.audio)" [disabled]="disableAVButtons"></button>
        <button class = "make-the-response-block font-25"
          [ngClass]="{'hover': response.highlight}"
          (click)="submitResponse(response.index)"
          (touchstart)="addHoverClass(response)"
          (mouseenter)="addHoverClass(response)" (mouseleave)="removeHoverClass(response)"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="disableResponseButtons">
          {{response.text}}
        </button>
      </div>
    </div>
  </div>

  <div class="try-again-dialog-background" [ngStyle]="{'background': ' ' + interventionTaskService.taskBarColor() + ' '}" *ngIf="showPassageDialog"></div>
  <div class="passage-dialog-container" *ngIf="showPassageDialog"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}">
    <button class="exit-passages-dialog" (click)="togglePassage()"></button>
    <div class="passages-target-area">
      <button class="speaker" (click)="playPassage()" [disabled]="disableAVButtons"></button>
      <div class="passages-target passages-dialog-target-adjust font-27">
        <button class="passages-title-audio" (click)="playTitle()"></button>
        {{passageTitle}}
      </div>
    </div>
    <!-- Container for sentences -->
    <div class="scrollable-sentence-container" id="{{'passage-scroll-' + currentDestination}}">
      <div class="passages-line font-20" *ngFor="let line of phrase; index as $index">
        <button class="passages-line-audio" (click)="playLine(line['@audio'])"></button>
        {{line['#text']}}
      </div>
    </div>
  </div>

  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog [passage]="sourcePassage"></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>
