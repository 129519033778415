export interface DemoCurriculumRequest {
  curriculum: string,
  subscriptionType: number,
  wordListType?: number,
  unitNumber?: number,
  objectiveNumber?: number,
  type?: number,
}

export interface PlacementOption {
  unitNumber: number,
  objectiveNumber: number,
  type: number,
  displayText: string,
}

export interface CurriculumOption {
  key: string,
  value: string,
}

export interface SubscriptionOption {
  key: number,
  value: string,
}

export interface WordlistOption {
  key: number,
  value: string
}



export enum SubscriptionTypes {
  FreeProduct = 'Screener',
  DiagnosticProduct = 'Diagnostic',
  FullProduct = 'System',
}