import { Injectable } from '@angular/core';
import { DiagnosticTask, InterventionTask } from 'src/app/core/models/task.model';
import { SSOCredentials } from '../models/credentials.model';
import { ThemeProperties } from '../models/theme.model';
import { AvatarItem, AvatarData, StudentData, InterventionData, StudentAssessment, Leaderboard, StudentAssessmentTaskStatus, InterventionTaskStatus } from '../models/student-data.model';
import {Curriculum} from "../models/curriculum.model";
import { Capacitor } from '@capacitor/core';

interface IStudentApplicationState {
  checkForUpdateTime: number | null;
  username: string | null;
  password: string | null; // Used for basic auth header
  sso: SSOCredentials | null;
  isLoggedIn: boolean;
  studentData: StudentData | null;
  curriculum: Curriculum | null;
  themeProperties: ThemeProperties | null;
  selectedTask: DiagnosticTask | InterventionTask | null;
  taskCounts: {[key: string]: number} | null;
  justCompletedDestinationNumber: number | null;
  isIntervention: boolean;
  demoUserSubscription: number | null;
  currentAvatar: AvatarItem | null;
  avatarList: AvatarItem[];
  mostRecentlyAwardedAvatar: AvatarItem | null;
  lastSeenDailyPoints: number | null;
  isSideMenuOpen: boolean;
  sessionExpirationTime: number | null;
}

const SESSION_STORAGE_KEY = 'WORDFLIGHT';
let state: IStudentApplicationState = getDefaultState();

/**
 * Service for handling the global application state. On web, state will be stored in memory
 * but also be persisted to session storage so that we can recover it on page refresh. On iOS,
 * application state will be stored only in memory.
 */
@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {

  constructor() {
    console.debug(`Instantiating ApplicationStateService | session key: ${SESSION_STORAGE_KEY}`);
    // Check for session storage and rehydrate state (in case of page refresh)
    if (Capacitor.getPlatform() === 'web') {
      this.rehydrateState();
    }
  }

  getCheckForUpdateTime(): number | null {
    return state.checkForUpdateTime;
  }

  setCheckForUpdateTime(checkForUpdateTime: number) {
    if (checkForUpdateTime) {
      this.saveState('checkForUpdateTime', checkForUpdateTime);
    }
  }

  setUsername(username: string) {
    this.saveState('username', username);
  }

  getUsername(): string | null {
    return state.username;
  }

  setPassword(password: string) {
    this.saveState('password', password);
  }

  getPassword(): string | null {
    return state.password;
  }

  setSSOInfo(sso: SSOCredentials) {
    this.saveState('sso', sso);
  }

  getSSOInfo(): SSOCredentials | null {
    return state.sso;
  }

  setIsLoggedIn(isLoggedIn: boolean) {
    this.saveState('isLoggedIn', isLoggedIn);
  }

  isLoggedIn(): boolean {
    return state.isLoggedIn;
  }

  setStudentData(studentData: StudentData) {
    this.saveState('studentData', studentData);
  }

  getStudentData(): StudentData | null {
    return state.studentData;
  }

  setCurriculum(curriculum: Curriculum) {
    this.saveState('curriculum', curriculum);
  }

  getCurriculum(): Curriculum | null {
    return state.curriculum;
  }

  clearCurriculum() {
    this.saveState('curriculum', null);
  }

  setThemeProperties(themeProperties: ThemeProperties) {
    this.saveState('themeProperties', themeProperties);
  }

  getThemeProperties(): ThemeProperties | null {
    return state.themeProperties;
  }

  setStudentLeaderboard(leaderboard: Leaderboard) {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.leaderboard = leaderboard;
      this.setStudentData(studentData);
    }
  }

  // Get the task that was selected from the destination screen
  getSelectedTask(): DiagnosticTask | InterventionTask | null {
    return state.selectedTask;
  }

  // Set the task that was selected from the destination screen
  setSelectedTask(task: DiagnosticTask | InterventionTask) {
    this.saveState('selectedTask', task);
  }

  clearSelectedTask(): void {
    this.saveState('selectedTask', null);
  }

  // Get the task start time
  getSelectedTaskStartTime(): Date | null {
    const selectedTask = this.getSelectedTask();
    if (selectedTask == null) {
      return null;
    }

    let startTime = selectedTask.taskStartTime || null;
    if (startTime !== null) {
      startTime = new Date(startTime);
    }
    return startTime;
  }

  // Set the task start time
  setSelectedTaskStartTime() {
    let selectedTask = this.getSelectedTask();
    if (selectedTask == null) {
      console.error(`selectedTask is null. Cannot set selectedTask start time.`);
      return;
    }

    selectedTask.taskStartTime = new Date();
    this.setSelectedTask(selectedTask);
  }

  setStudentAssessmentTaskList(taskList: StudentAssessmentTaskStatus[]) {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.studentAssessment.taskList = taskList;
      this.setStudentData(studentData);
    }
  }

  initInterventionTaskCompletionCounts(studentData: StudentData)
  {
    if (!studentData.interventionData) return ;

    let counts: { [key: string] : number } = {};
    studentData.interventionData.interventionTaskList.forEach((intTask) => {
      counts[intTask.interventionTaskIdentifier] = intTask.numberOfCompletions ?? 0;
    }) ;

    this.saveState('taskCounts', counts);
  }

  // Updates the count of how many times a student completed a particular task
  updateStudentTaskCompletionCount(taskId: string) {
    let counts = state.taskCounts;
    if (!counts) return ;

    if (counts && counts[taskId])
    {
      counts[taskId] += 1 ;
    }
    else
    {
      counts[taskId] = 1 ;
    }
    this.saveState('taskCounts', counts);
  }

  getStudentTaskCompletionCount(taskId: string): number | null {
    const counts = state.taskCounts;
    return counts ? counts[taskId] : null ;
  }

  setStudentInterventionTaskList(taskList: InterventionTaskStatus[]) {
    let studentData = this.getStudentData();
    if (studentData?.interventionData) {
      studentData.interventionData.interventionTaskList = taskList;
      this.setStudentData(studentData);
    }
  }

  // Get current destination
  getCurrentDestination(): number {
    if (this.isIntervention()) {
      let studentData = this.getStudentData()
      return studentData?.interventionData?.currentDestination ?? 0;
    } else {
      let studentData = this.getStudentData();
      return studentData?.studentAssessment.currentDestination ?? 0;
    }
  }

  getJustCompletedDestinationNumber(): number | null {
    return state.justCompletedDestinationNumber;
  }

  setJustCompletedDestinationNumber(justCompletedDestinationNumber: number) {
    this.saveState('justCompletedDestinationNumber', justCompletedDestinationNumber);
  }

  getLevelCompleted(): number {
    return this.getStudentData()?.levelCompleted ?? 0;
  }

  setLevelCompleted(levelCompleted: number) {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.levelCompleted = levelCompleted;
      this.setStudentData(studentData);
    }
  }

  setIsIntervetion(isIntervention: boolean): void {
    this.saveState('isIntervention', isIntervention);
  }

  getDemoUserSubscription(): number {
    return state.demoUserSubscription ?? 0;
  }

  setDemoUserSubscription(demoUserSubscription: number) {
    this.saveState('demoUserSubscription', demoUserSubscription);
  }

  isIntervention(): boolean {
    return state.isIntervention;
  }

  setHasBeenNotifiedOfNewTeam(hasBeenNotifiedOfNewTeam: boolean): void {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.team.hasBeenNotifiedOfNewTeam = hasBeenNotifiedOfNewTeam;
      this.setStudentData(studentData);
    }
  }

  setHasSeenInstructionalVideo(hasSeenInstructionalVideo: boolean): void {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.team.hasSeenInstructionalVideo = hasSeenInstructionalVideo;
      this.setStudentData(studentData);
    }
  }

  getCurrentAvatar(): AvatarItem | null {
    return state.currentAvatar;
  }

  setCurrentAvatar(avatarItem: AvatarItem) {
    this.saveState('currentAvatar', avatarItem);
  }

  getStudentAvatarData(): AvatarData | null {
    let studentData = this.getStudentData();
    return studentData?.avatarData ?? null;
  }

  setStudentAvatarData(avatarData: AvatarData) {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.avatarData = avatarData;
      this.setStudentData(studentData);
    }
  }

  getAvatarList(): AvatarItem[] {
    return state.avatarList;
  }

  setAvatarList(avatarList: AvatarItem[]) {
    this.saveState('avatarList', avatarList);
  }

  getRecentlyAwardedAvatar(): AvatarItem | null {
    return state.mostRecentlyAwardedAvatar;
  }

  setRecentlyAwardedAvatar(mostRecentlyAwardedAvatar: AvatarItem | null) {
    this.saveState('mostRecentlyAwardedAvatar', mostRecentlyAwardedAvatar);
  }

  getLastSeenDailyPoints(): number | null {
    return state.lastSeenDailyPoints;
  }

  setLastSeenDailyPoints(lastSeenDailyPoints: number): void {
    this.saveState('lastSeenDailyPoints', lastSeenDailyPoints);
  }

  getSideMenuOpen(): boolean {
    return state.isSideMenuOpen;
  }

  setSideMenuOpen(isSideMenuOpen: boolean) {
    this.saveState('isSideMenuOpen', isSideMenuOpen);
  }

  getStudentInterventionData(): InterventionData | null {
    return this.getStudentData()?.interventionData ?? null;
  }

  setStudentInterventionData(interventionData: InterventionData): void {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.interventionData = interventionData;
      this.setStudentData(studentData);
    }
  }

  getStudentAssessment(): StudentAssessment | null {
    return this.getStudentData()?.studentAssessment ?? null;
  }

  setStudentAssessment(studentAssessment: StudentAssessment): void {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.studentAssessment = studentAssessment;
      this.setStudentData(studentData);
    }
  }

  clearStudentAssessmentUnsavedData() {
    let studentData = this.getStudentData();
    if (studentData) {
      studentData.studentAssessment.unsavedData = null;
      this.setStudentData(studentData);
    }
  }

  clearUnsavedInterventionTaskData() {
    let studentData = this.getStudentData();
    if (studentData?.interventionData) {
      studentData.interventionData.unsavedInterventionTaskData = null;
      this.setStudentData(studentData);
    }
  }

  setSessionExpirationTime(expirationTime: number | null) {
    this.saveState('sessionExpirationTime', expirationTime);
  }

  getSessionExpirationTime(): number | null {
    return state.sessionExpirationTime;
  }

  getCopyOfSessionData(): string {
    return JSON.stringify(state);
  }

  restoreSessionFromCopy(sessionCopy: string) {
    // Restore state from copy
    state = JSON.parse(sessionCopy);
    if (Capacitor.getPlatform() === 'web') {
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(state));
    }
  }

  /**
   * Reset application state back to defaults
   */
  clear() {
    let checkForUpdateTime = this.getCheckForUpdateTime();
    state = getDefaultState();
    if (Capacitor.getPlatform() === 'web') {
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(state));
    }

    if (checkForUpdateTime)
    {
      this.setCheckForUpdateTime(checkForUpdateTime);
    }
  }

  private saveState<K extends keyof IStudentApplicationState>(key: K, value: IStudentApplicationState[K]) {
    console.debug(`Saving state [${key}]`, (key === 'password') ? '****' : value);
    state[key] = value;
    // Save to session storage on web
    if (Capacitor.getPlatform() === 'web') {
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(state));
    }
  }

  private rehydrateState() {
    const sessionStorageString = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (sessionStorageString) {
      state = JSON.parse(sessionStorageString) as IStudentApplicationState;
      const { password, ...loggedState } = state;
      console.debug(`Rehydrating state from session storage (password omitted from debug log)`, loggedState);
      return;
    }

    console.debug(`No state found in session storage`);
  }
}

function getDefaultState(): IStudentApplicationState {
  return {
    checkForUpdateTime: null,
    username: null,
    password: null,
    sso: null,
    isLoggedIn: false,
    studentData: null,
    curriculum: null,
    themeProperties: null,
    selectedTask: null,
    taskCounts: null,
    justCompletedDestinationNumber: null,
    isIntervention: false,
    demoUserSubscription: null,
    currentAvatar: null,
    avatarList: [],
    mostRecentlyAwardedAvatar: null,
    lastSeenDailyPoints: null,
    isSideMenuOpen: false,
    sessionExpirationTime: null,
  };
}
