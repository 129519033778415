import { Injectable } from '@angular/core';
import { StudentDataService } from './student-data.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Curriculum, CurriculumAdapter } from '../models/curriculum.model' ;
import { Task } from '../models/task.model' ;

@Injectable({
  providedIn: 'root'
})
export class CurriculumService {

  constructor(
    private httpClient: HttpClient,
    private studentDataService: StudentDataService,
    private curriculumAdapter: CurriculumAdapter
  ) { }

  // Function will return the curriculum for the student.  It stores the
  // curriculum in session storage for subsequent access
  loadCurriculum() {
    let curriculumType = this.getCurriculumType() ;
    let curriculumFile = this.getCurriculumFilePath();
    let url = environment.AssetServerURL + curriculumFile;

    return this.httpClient.get<boolean>(url).pipe(
      map((data: any) => {
        let curriculum: Curriculum = this.curriculumAdapter.adapt({
          ...data[curriculumType],
          levels: this.computeNumLevelsPerType(),
          type: (this.studentDataService.isIntervention()) ? 'intervention' : 'diagnostic'
        }, true);
        this.studentDataService.setCurriculum(curriculum);

        return true;
      })
    );
  }

  private getInterventionFileName(interventionData: any) {
    let objectiveNumber = interventionData.objectiveNumber;
    let unitNumber = interventionData.unitNumber;

    let fileName = 'AC';
    if (unitNumber == null)
    {
      let type = (interventionData.type === 0) ? 'pre' : 'post';
      fileName += 'Obj' + objectiveNumber + type;
    }
    else
    {
      fileName += 'Unit' + unitNumber;
    }

    fileName += '.json';
    return fileName;
  }

  // NOTE
  // Since we are fetching from a server anyways it may be best
  //  if we get these from the dataservice server and make it authenticated
  private getCurriculumFilePath() {
    let studentCurriculum = this.studentDataService.getStudentCurriculumName();
    let curriculumFile = '/assets/curriculum/';
    if (this.studentDataService.isIntervention())
    {
      // Get File from the intervention curricula
      curriculumFile += 'intervention/' + studentCurriculum + '/' +
          this.getInterventionFileName(this.studentDataService.getStudentInterventionData());
    }
    else
    {
      // Get File from diagnostic/screener curricula
      curriculumFile += studentCurriculum + ".json";
    }
    return curriculumFile;
  }

  private getCurriculumType() {
    // Get the curriculum type, it can only be an objective if
    //  the student is in intervention and the type is as defined
    //  by the Subscriptions Type on the backend.
    let curriculumType = 'unit';
    if (this.studentDataService.isIntervention())
    {
      let type = this.studentDataService.getStudentInterventionData()?.type;
      if (type === 0 || type === 2)
      {
        curriculumType = 'objective';
      }
    }
    return curriculumType;
  }

  // get number of levels in a unit from curriculum
  // NOTE Why is this hardcoded, can we get these counts from the curriculum?
  private computeNumLevelsPerType() {
    if (this.studentDataService.isIntervention())
    {
      return [
        {
          "Objective": {
            "PreTest": 2,
            "Unit": [
              5,
              5,
              5,
              5,
            ],
            "PostTest": 2
          }
        },
        {
          "Objective": {
            "PreTest": 2,
            "Unit": [
              5,
              5,
              5,
              5,
            ],
            "PostTest" : 2
          }
        },
        {
          "Objective": {
            "PreTest": 2,
            "Unit": [
              5,
              5,
              5,
              5,
            ],
            "PostTest": 2
          }
        },
        {
          "Objective": {
            "PreTest": 2,
            "Unit": [
              5,
              5,
              5,
              5,
            ],
            "PostTest": 2
          }
        },
        {
          "Objective": {
            "PreTest": 2,
            "Unit": [
              5,
              5,
              5,
              5,
            ],
            "PostTest": 2
          }
        },
        {
          "Objective": {
            "PreTest": 2,
            "Unit": [
              5,
              5,
              5,
              5,
            ],
            "PostTest": 2
          }
        },
      ]
    }
    else if (this.studentDataService.isScreenerDiagnostic())
    {
      return [
        { "Screener" : 3 }
      ]
    }
    else
    {
      return [
        { "Diagnostic" : 7 }
      ]
    }
  }

  // Function to get tasks for certain destinations
  getTasksForDestination (destinationNumber: number): Task[] {
    let taskListForDestination: Task[] = [];

    this.studentDataService.getCurriculum().tasks.forEach((task) => {
      if (task.destination === destinationNumber)
      {
        taskListForDestination.push(task) ;
      }
    }) ;

    return taskListForDestination;
  }

  clearCurriculum() {
    this.studentDataService.clearCurriculum();
  }
}
