import { Injectable } from '@angular/core' ;
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router' ;
import { Observable } from 'rxjs' ;
import { AssetPreloaderService } from '../../core/services/asset-preloader.service';
import { StudentDataService } from '../../core/services/student-data.service';

@Injectable({
  providedIn: 'root'
})
export class TaskSelectResolver  {
  constructor(
    private studentDataService: StudentDataService,
    private assetPreloaderService: AssetPreloaderService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let imageList: string[] = [
      this.studentDataService.getTaskSelectBackground(),
      ...this.studentDataService.getTaskSelectButtonImages()
    ]

    return this.assetPreloaderService.preloadImages(imageList) ;
  }
}