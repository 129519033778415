import { Component, ElementRef, OnInit, OnDestroy, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { StudentDataService } from '../../core/services/student-data.service';
import { DiagnosticTask, InterventionTask } from '../../core/models/task.model';
import { TaskSelectButton } from '../../core/models/task-select-button.model';
import { StudentHeaderComponent } from '../../shared/components/student-header/student-header.component';
import { SideMenuComponent } from '../../shared/components/side-menu/side-menu.component';
import { CurriculumService } from '../../core/services/curriculum.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InterventionTaskTheme, TaskTheme, THEMES } from '../../core/models/theme.model';
import { SessionTimerService } from '../../core/services/session-timer.service';
import {
  AvatarItem,
  DiagnosticTaskResponse,
  InterventionTaskResponse,
  InterventionTaskStatus,
} from '../../core/models/student-data.model';
import { WorldNavigationTimerService } from '../../core/services/world-nav-timer.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { AudioPlayerService } from '../../core/services/audio-player.service';
import { StudentFooterComponent } from '../../shared/components/student-footer/student-footer.component';
import { FocusDialog } from '../../shared/components/focus-dialog/focus-dialog.component';
import { TaskService } from '../../core/services/task.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-task-select',
  templateUrl: './task-select.component.html',
  styleUrls: ['./task-select.component.css']
})
export class TaskSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  protected FIND_PICTURE_SUBTASK: string = 'PAP';
  protected ANSWER_QUESTION_SUBTASK: string = 'PAQ';
  protected READ_THE_SENTENCE_SUBTASK: string = 'PAS' ;
  protected READ_THE_WORD_SUBTASK: string = 'PAW' ;
  protected destinationValue: number = 1;
  protected disableButtonDuration: number = 0;
  protected filteredTaskList: DiagnosticTask[] | InterventionTask[] = [];
  protected allTasksCompleteForDestination: boolean = false;
  protected allTasksIncomplete: boolean = false;
  protected enableAutocomplete: boolean = false;
  protected isInterventionStudent: boolean = false;
  protected taskList: DiagnosticTask[] | InterventionTask[] = [];
  protected levelProperties: InterventionTaskTheme | TaskTheme | undefined;
  protected timeoutList: number[] = [];
  protected eventsSubscription?: Subscription;
  protected navigationTimerSubscription?: Subscription;
  protected sessionTimerIntervalId: number = -1;

  disableButtons: boolean = true;
  disableSideButton: boolean = false;
  taskButtonList: TaskSelectButton[] = [];
  taskButtonList1: TaskSelectButton[] = [];
  taskButtonList2: TaskSelectButton[] = [];
  taskButtonList3: TaskSelectButton[] = [];
  shouldCompareStudentScores = false;
  shouldDisplayAlternatingTasks = false;
  isPreDiagnosticStudent = false;
  taskSelectBackgroundImage: string = '';
  previousTaskScoreData: any;
  needsFocus: boolean;
  themes = THEMES;

  @ViewChild('studentHeader') headerComponent!: StudentHeaderComponent;
  @ViewChild('sideMenu') sideMenuComponent!: SideMenuComponent;
  @ViewChild('studentFooter') studentFooter!: StudentFooterComponent;
  @ViewChild(FocusDialog) focusDialog!: FocusDialog;

  constructor(
    public router: Router,
    public host: ElementRef,
    public changeDetector: ChangeDetectorRef,
    public sessionTimerService: SessionTimerService,
    public navigationTimerService: WorldNavigationTimerService,
    public studentDataService: StudentDataService,
    public curriculumService: CurriculumService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
  ) {
    this.previousTaskScoreData = this.router.getCurrentNavigation()?.extras.state?.previousTaskScoreData;
    this.needsFocus = this.router.getCurrentNavigation()?.extras.state?.needsFocus;
  }

  ngOnInit(): void {
    let compareDiagnostics = (this.studentDataService.isPreDiagnosticCompleted() || this.studentDataService.isMidDiagnosticCompleted()) && (this.studentDataService.isMidDiagnostic() || this.studentDataService.isPostDiagnostic());
    // Make sure that we start out with no task selected
    this.studentDataService.clearSelectedTask();

    // Initialize our properties
    this.isInterventionStudent = this.studentDataService.isIntervention();
    this.isPreDiagnosticStudent = this.studentDataService.isPreDiagnostic() || this.studentDataService.isScreenerDiagnostic();
    this.shouldDisplayAlternatingTasks = !this.studentDataService.isIntervention();
    this.shouldCompareStudentScores = this.studentDataService.isInterventionPostTest() || compareDiagnostics;
    this.taskSelectBackgroundImage = this.studentDataService.getTaskSelectBackground();
    this.destinationValue = this.studentDataService.getCurrentDestination();
    this.allTasksCompleteForDestination = this.studentDataService.areAllTasksCompleteForDestination(this.destinationValue);
    this.allTasksIncomplete = this.studentDataService.areAllTasksIncompleteForDestination(this.destinationValue);
    this.levelProperties = this.studentDataService.getLevelProperties();

    if (this.studentDataService.isIntervention()) {
      this.taskList = this.curriculumService.getTasksForDestination(this.destinationValue) as InterventionTask[];
    }
    else {
      this.taskList = this.curriculumService.getTasksForDestination(this.destinationValue) as DiagnosticTask[];
    }

    if (this.isInterventionStudent && this.previousTaskScoreData !== null) {
      // Check for previous task data and rewrite to the correct intervention task
      let taskId = '';
      let destination = 0;
      if (this.previousTaskScoreData) {
        taskId = this.previousTaskScoreData.interventionTaskIdentifier;
        destination = this.previousTaskScoreData.destination;
      }

      let taskList = <InterventionTaskStatus[]>this.studentDataService.getTaskStatusList();
      let lastInterventionTask = taskList.find((task) => (taskId === task.interventionTaskIdentifier && destination === task.destination))
      if (lastInterventionTask) {
        lastInterventionTask.numberOfTrials = this.previousTaskScoreData.numberOfTrials;
        lastInterventionTask.numberOfCorrectTrials = this.previousTaskScoreData.numberOfCorrectTrials;
        this.studentDataService.setStudentInterventionTaskList(taskList);
      }
    }

    if (this.allTasksCompleteForDestination) {
      this.disableButtonDuration = 1300;
    }

    this.timeoutList.push(window.setTimeout(() => {
      this.disableButtons = false;
    }, this.disableButtonDuration));

    // Setup our Task Select Buttons
    this.configureTaskButtons(this.destinationValue);

    // Listen for the navigation timer service expiration so we can auto-select the first task if the user does not choose in time
    // NOTE: The footer component currently houses the logic to determine whether the the user is a superuser and needs the nav timer
    this.navigationTimerSubscription = this.navigationTimerService.expired.subscribe((expired) => {
      if (expired) this.handleNavigationTimerExpired();
    });
  }

  ngOnDestroy(): void {
    if (this.studentDataService.getSideMenuOpen()) {
      this.studentDataService.setSideMenuOpen(false);
    }

    this.timeoutList.forEach((timeoutId) => {
      window.clearTimeout(timeoutId);
    });

    if (this.sessionTimerIntervalId !== -1) {
      window.clearInterval(this.sessionTimerIntervalId);
    }

    this.navigationTimerSubscription?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    // Focus on our main div so we can catch key press events, in the event of a superuser autocompleting
    let container = this.host.nativeElement.querySelector('#task-select');
    if (container) container.focus();

    // Now setup our events checks
    this.checkForNotifications();
    this.changeDetector.detectChanges();

    // Check to prompt the student to pay attention
    if (this.needsFocus) {
      this.focusDialog.showDialog();
    }
  }

  autoCompleteTask($event: KeyboardEvent) {
    if (!this.enableAutocomplete || !this.studentDataService.isUserSuperUser() || ($event.key !== 'c') || this.taskButtonList.length === 0) {
      return;
    }

    // Find a task to complete
    let task: DiagnosticTask | InterventionTask | null = null;
    let alreadyCompletedFlag: boolean | null = null;
    this.enableAutocomplete = false;
    for (let taskBtnIdx = 0; taskBtnIdx < this.taskButtonList.length; taskBtnIdx++) {
      // For intervention, check to see if the task is already complete
      // If not intervention, we'll just say it is not complete and let the other
      // logic for visibility dictate whether we need to complete it or not
      let taskBtn = this.taskButtonList[taskBtnIdx];
      if (!taskBtn.disabled && taskBtn.visible && !taskBtn.completed) {
        // We found a task to autocomplete, so start it and complete it
        task = this.filteredTaskList[taskBtnIdx];
        break;
      }
    }

    if (task !== null) {
      this.studentDataService.setSelectedTask(task);
      let response: DiagnosticTaskResponse | InterventionTaskResponse[];
      if (this.isInterventionStudent) {
        response = [];

        // Autocomplete the intervention task
        let numberOfTrials = (!task.trial) ? 0 : task.trial.length;
        let interventionData = this.studentDataService.getStudentInterventionData();
        let additionalData = {
          wordListType: this.studentDataService.isInterventionUnit() && (task as InterventionTask).wordlistType ? this.studentDataService.getWordListTypeAsInt((task as InterventionTask).wordlistType) : null,
          durationMillis: this.studentDataService.getSelectedTaskDurationMillis(),
          objectiveNumber: interventionData?.objectiveNumber ?? 0,
          type: interventionData?.type ?? 0,
          unitNumber: interventionData?.unitNumber ?? 0,
        };
        response.push((task as InterventionTask).generateAutocompleteResponse(additionalData));

        // A 'Passages...' task needs to be handled differently, we first autocomplete the passages read and listen task,
        // then we determine if there are any 'Passages...' sub-tasks at the current destination, autocomplete those if they exist
        // Autocompleting any passages task could potentially have sub-tasks to handle
        if (task.id.startsWith('PASS')) {
          let taskList = this.curriculumService.getTasksForDestination(this.destinationValue) as InterventionTask[];
          let splitTaskId = task.id.split('-');
          let subTaskSuffix = '-' + splitTaskId[1] + '-' + splitTaskId[2];
          const subtaskIds = [ 'PAP' + subTaskSuffix, 'PAQ' + subTaskSuffix, 'PAS' + subTaskSuffix, 'PAW' + subTaskSuffix ] ;
          alreadyCompletedFlag = true;

          for (let tIdx = 0; tIdx < taskList.length; tIdx++) {
            if (subtaskIds.some((subtaskId) => subtaskId === taskList[tIdx].id)) {
              // Start our sub-task
              numberOfTrials += taskList[tIdx].trial.length;
              this.studentDataService.setSelectedTask(taskList[tIdx]);
              this.studentDataService.startSelectedTask();

              let interventionData = this.studentDataService.getStudentInterventionData();
              let additionalData = {
                wordListType: this.studentDataService.isInterventionUnit() && (taskList[tIdx] as InterventionTask).wordlistType ? this.studentDataService.getWordListTypeAsInt((taskList[tIdx] as InterventionTask).wordlistType) : null,
                durationMillis: this.studentDataService.getSelectedTaskDurationMillis(),
                objectiveNumber: interventionData?.objectiveNumber ?? 0,
                type: interventionData?.type ?? 0,
                unitNumber: interventionData?.unitNumber ?? 0,
              };
              response.push((taskList[tIdx] as InterventionTask).generateAutocompleteResponse(additionalData));
            }
          }
        }
        else {
          alreadyCompletedFlag = this.studentDataService.isInterventionTaskComplete(this.destinationValue, (task as InterventionTask));
        }

        let cumulativeTotalPoints: number = response.reduce((cumulativeTotalPoints, resp) => {
          return resp.points + cumulativeTotalPoints;
        }, 0);

        this.studentDataService.setTotalPoints(this.destinationValue, cumulativeTotalPoints, numberOfTrials, numberOfTrials);
      }
      else {
        // Start our task
        this.studentDataService.setSelectedTask(task);
        this.studentDataService.startSelectedTask();

        response = (task as DiagnosticTask).generateAutocompleteResponse();
        this.studentDataService.setTotalPoints(this.destinationValue, response.points, response.numberOfTrials, response.numberOfCorrectTrials);
      }

      // Save our data and go back to task select
      this.studentDataService.saveTrialData(response).subscribe({
        complete: () => {
          // Because we are calling the same route, we need to let the router do this by telling it to reload even though the app
          // knows that we are on the same route. More so, we need to also tell Angular to *not* reuse the same component instance
          // TODO: Check to see the optimization of this
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl('/taskSelect', { state: { canGoToTaskSelect: true, lastTaskCompletedID: task!.id, alreadyCompleted: alreadyCompletedFlag } });
        }
      });
    }
  }

  goToTask(taskIndex: number) {
    if (this.sessionTimerService.hasTimerExpired()) {
      this.router.navigateByUrl('/timeUp', { state: { isLevelSelect: false } });
    }
    else if (!this.disableButtons) {
      this.timeoutList.push(window.setTimeout(() => {
        this.navigateToTask(this.filteredTaskList[taskIndex]);
      }, 500));
    }
  }

  handleTouchEnd(taskButtonListNum: number, index: number, taskIndex: number, $event: TouchEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.unhighlightTaskButton(taskButtonListNum, index);

    let taskButtonList = this.getTaskButtonList(taskButtonListNum);
    if (taskButtonList[index].disabled || this.disableButtons) return;

    this.goToTask(taskIndex);
  }

  updateSelectedAvatar(avatar: AvatarItem) {
    this.headerComponent.updateAvatarIcon();
  }

  // NOTE: These highlight/unhighlight methods are needed because the background image is dependent on a few things,
  //     : ideally we could create appropriately specific CSS classes so we can do a hover
  highlightTaskButton(taskButtonListNum: number, index: number) {
    let taskButtonList = this.getTaskButtonList(taskButtonListNum);
    taskButtonList[index].buttonImage = taskButtonList[index].buttonImage.replace('_bu', '_bh');
  }

  unhighlightTaskButton(taskButtonListNum: number, index: number) {
    let taskButtonList = this.getTaskButtonList(taskButtonListNum);
    taskButtonList[index].buttonImage = taskButtonList[index].buttonImage.replace('_bh', '_bu');
  }

  private checkForAllTasksComplete() {
    return new Observable((observer: Observer<boolean>) => {
      if (this.allTasksCompleteForDestination) {
        this.audioPlayerService.play('Audio/Other/task_select_complete.mp3').subscribe({
          error: (error) => {
            Sentry.captureException(new Error('Could not play audio'), {
              tags: {
                section: 'task-select',
              },
              contexts: {
                audio: {
                  file: 'Audio/Other/task_select_complete.mp3',
                }
              }
            }) ;
          }
        });

        setTimeout(() => {
          observer.next(true);
          observer.complete();
        }, 1000)
      }
      else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private checkForNotifications() {
    this.disableSideButton = true;

    this.eventsSubscription = this.headerComponent.checkForPointsAnimation()
      .pipe(
        switchMap(() => this.checkForAllTasksComplete()),
        switchMap(() => this.sideMenuComponent.checkForTeamStandingAnimation())
      )
      .subscribe({
        next: () => {
          this.studentFooter.checkForCountdownClock();
          this.disableSideButton = false;
          this.changeDetector.detectChanges();

          this.sessionTimerIntervalId = window.setInterval(() => {
            if (this.sessionTimerService.hasTimerExpired()) {
              this.router.navigateByUrl('/timeUp');
            }
          }, 5000);
        },
        error: (error) => {
          Sentry.captureException(error, {
            tags: {
              section: 'task-select',
              action: 'check-for-notifications',
            },
          }) ;
        },
      });
  }

  protected configureTaskButtons(destination: number) {
    // Safety check (this is inverted from the legacy codebase)
    if (this.taskList === null || this.taskList.length === 0) return;

    let studentTaskList = this.studentDataService.getStudentTaskStatusForDestination(destination);
    let taskIdString;
    if (!this.isInterventionStudent) {
      taskIdString = 'taskIdentifier';
      this.filteredTaskList = this.taskList;
    }
    else {
      // Filter the taskList to only include tasks for a single wordlist if student is in intervention
      taskIdString = 'interventionTaskIdentifier';
      this.filteredTaskList = this.filterTaskList(this.taskList as InterventionTask[], studentTaskList as InterventionTaskStatus[]);
    }

    for (let filterIdx = 0; filterIdx < this.filteredTaskList.length; filterIdx++) {
      let taskComplete = this.studentDataService.isTaskComplete(studentTaskList, destination, this.filteredTaskList[filterIdx].id, taskIdString);
      let disabled = taskComplete && !this.studentDataService.isInterventionUnit();
      let taskCompletionCount = this.studentDataService.getTaskCompletionCount(this.filteredTaskList[filterIdx].id);
      if (taskCompletionCount && taskCompletionCount >= 4) {
        disabled = true;
      }

      let passageTitle = null;
      if (this.isInterventionStudent && this.filteredTaskList[filterIdx].id.startsWith('PASS')) {
        // Only interventions
        passageTitle = (this.filteredTaskList[filterIdx] as InterventionTask).passage!.title!['#text'];
      }

      this.taskButtonList.push(new TaskSelectButton(
        filterIdx,
        this.filteredTaskList[filterIdx].taskType,
        this.filteredTaskList[filterIdx].taskName,
        this.filteredTaskList[filterIdx].taskIcon,
        passageTitle,
        this.studentDataService.getTaskPreviousAttemptPercentage(this.filteredTaskList[filterIdx].id),
        this.studentDataService.getTaskPercentage(this.filteredTaskList[filterIdx].id),
        true,
        disabled,
        taskComplete,
        this.allTasksIncomplete,
        this.allTasksCompleteForDestination,
        this.shouldDisplayAlternatingTasks,
        this.studentDataService.isDemoUser(),
        this.levelProperties,
        this.studentDataService.getStudentThemeNumber(),
      ));
    }

    if (this.taskButtonList.length === 4) {
      this.taskButtonList1 = this.taskButtonList.slice(0, 2);
      this.taskButtonList2 = [
        new TaskSelectButton(
          0,
          'NA',
          'NA',
          'iconMono',
          '',
          0,
          0,
          false,
          true,
          false,
          this.allTasksIncomplete,
          this.allTasksCompleteForDestination,
          this.shouldDisplayAlternatingTasks,
          this.studentDataService.isDemoUser(),
          this.levelProperties,
          this.studentDataService.getStudentThemeNumber(),
        )
      ];
      this.taskButtonList3 = this.taskButtonList.slice(2, 4);
    }
    else {
      this.taskButtonList1 = this.taskButtonList.slice(0, 2);
      this.taskButtonList2 = this.taskButtonList.slice(2, 3);
      this.taskButtonList3 = this.taskButtonList.slice(3, 5);
    }

    this.enableAutocomplete = true;
  }

  /**
   * This method is only for an intervention, we need to filter the task list to only include tasks for a single wordlist
   * for the student
   * @param taskList
   * @param studentTaskList
   * @private
   */
  protected filterTaskList(taskList: InterventionTask[], studentTaskList: InterventionTaskStatus[]): InterventionTask[] {
    let taskIds: string[] = [];
    let filteredTasks: InterventionTask[] = [];
    let wordListType = this.studentDataService.getWordListType();

    // Get all unique (non-subtask) task ids from the taskList
    for (let taskIdx = 0; taskIdx < taskList.length; taskIdx++) {
      const isBetaTask: boolean = this.taskService.isBetaTask(taskList[taskIdx].id) ;
      const isBetaSpeechTask: boolean = this.taskService.isBetaSpeechTask(taskList[taskIdx].id) ;
      let taskId: string = taskList[taskIdx].id;
      let isSubTask = [
        this.FIND_PICTURE_SUBTASK,
        this.ANSWER_QUESTION_SUBTASK,
        this.READ_THE_SENTENCE_SUBTASK,
        this.READ_THE_WORD_SUBTASK
      ].some((subtaskId) => !taskId.startsWith('PASS') && taskId.startsWith(subtaskId));
      let studentTask = studentTaskList.find((studentTask) => studentTask.interventionTaskIdentifier === taskId);

      if (!taskIds.includes(taskId) && !isSubTask && studentTask &&
         (!isBetaTask || (isBetaTask && this.studentDataService.getStudentData().betaTasksEnabled)) &&
         (!isBetaSpeechTask || (isBetaSpeechTask && this.studentDataService.getStudentData().betaSpeechTasksEnabled))) {
        taskIds.push(taskId);
      }
    }

    // For all our unique task IDs, find the correct word list type and put it in our list to return
    for (let idIdx = 0; idIdx < taskIds.length; idIdx++) {
      let specificTaskArray: InterventionTask[] = [];

      // We are handling unique task IDs one by one, so only deal with the current task ID we are filtering
      taskList.forEach((task: InterventionTask) => {
        if (task.id === taskIds[idIdx]) specificTaskArray.push(task);
      });

      // Add the task with the correct word list if it exists, otherwise add the task with the closest word list difficulty
      if (specificTaskArray.length === 1) {
        filteredTasks.push(specificTaskArray[0]);
      }
      else if (specificTaskArray.length > 1) {
        // Filter our intervention task array to find the correct word list tasks
        let orderedTaskObj: any = {};
        specificTaskArray.forEach((specificTask: InterventionTask) => {
          orderedTaskObj[specificTask.wordlistType] = specificTask;
        });

        // Now, if we had an exact wordlist type match, add it, otherwise try to find the closest matching difficult
        if (orderedTaskObj.hasOwnProperty(wordListType)) {
          filteredTasks.push(orderedTaskObj[wordListType]);
        }
        else {
          // We didn't have the wordlist type we want, so attempt to find the nearest difficulty level
          // NOTE: This was directly ported from the legacy codebase and could use optimization
          if (wordListType === 'challenge') {
            if (orderedTaskObj.hasOwnProperty('medium')) filteredTasks.push(orderedTaskObj['medium']);
            else if (orderedTaskObj.hasOwnProperty('easy')) filteredTasks.push(orderedTaskObj['easy']);
          }
          else if (wordListType === 'medium') {
            if (orderedTaskObj.hasOwnProperty('easy')) filteredTasks.push(orderedTaskObj['easy']);
            else if (orderedTaskObj.hasOwnProperty('challenge')) filteredTasks.push(orderedTaskObj['challenge']);
          }
          else if (wordListType === 'easy') {
            if (orderedTaskObj.hasOwnProperty('medium')) filteredTasks.push(orderedTaskObj['medium']);
            else if (orderedTaskObj.hasOwnProperty('challenge')) filteredTasks.push(orderedTaskObj['challenge']);
          }
        }
      }
    }

    return filteredTasks;
  }

  private getTaskButtonList(taskButtonListNum: number) {
    switch (taskButtonListNum) {
      case 1:
        return this.taskButtonList1;
      case 2:
        return this.taskButtonList2;
      case 3:
        return this.taskButtonList3;
      default:
        return this.taskButtonList;
    }
  }

  protected handleNavigationTimerExpired() {
    this.navigationTimerSubscription?.unsubscribe();

    // On navigation timeout, load the next incomplete task, or navigate back to levelSelect if all done
    // NOTE: The original implementation of this method iterated over the tasks to find the first incomplete task,
    //     : if found it would then directly call navigateToTask(task). However, I modified this to instead call
    //     : goToTask(index) because that method handles potential session timeout events.
    let taskIdx: number = -1;
    for (let idx = 0; idx < this.taskButtonList.length; idx++) {
      if (!this.taskButtonList[idx].completed) {
        // OPTIMIZE: This assigment feels a little fragile because we are iterating over the taskButtonList, but
        //         : then assigning from the filteredTaskList. The taskButtonList array is created by iterating
        //         : over the filteredTaskList (and making a button for it), but it might be more extensible to
        //         : instead iterate over the filteredTaskList and attempt to match the task ID of the button
        taskIdx = idx;
        break;
      }
    }

    if (taskIdx !== -1) {
      this.goToTask(taskIdx);
    }
    else {
      this.router.navigateByUrl('/levelSelect', { state: { previous: this.router.url } });
    }
  }

  private navigateToTask(task: DiagnosticTask | InterventionTask) {
    // Select our task
    this.studentDataService.setSelectedTask(task);

    // If student is attempting an already completed task -- let the studentDataFactory keep track of this
    let secondAttempt = false;
    if (this.isInterventionStudent) {
      let currentDestination = this.studentDataService.getCurrentDestination();
      let interventionTaskData = this.studentDataService.getInterventionTask(task.id, currentDestination);
      if (interventionTaskData) {
        secondAttempt = (interventionTaskData?.attempt === 1 || (interventionTaskData?.numberOfTrials > 0 && !interventionTaskData?.complete));
      }
    }

    let interventionStateParams = {
      wordListAttempt: 1,
      attempt: (secondAttempt) ? 2 : 1,
      passage: null
    };

    let taskType = task.taskType;
    if (taskType.indexOf("FILL IN THE") === 0) {
      this.isInterventionStudent ? this.router.navigateByUrl('/fillInBlankIntervention', { state: { ...interventionStateParams } }) : this.router.navigateByUrl('/fillInBlank');
    }
    else if (taskType.indexOf("VERIFY THE") === 0) {
      this.isInterventionStudent ? this.router.navigateByUrl('/verifyTheBlankIntervention', { state: { ...interventionStateParams } }) : this.router.navigateByUrl('/verifyTheBlank');
    }
    else if (taskType.indexOf("SPELL THE...") === 0) {
      this.router.navigateByUrl('/spellTheWordIntervention', { state: { ...interventionStateParams } });
    }
    else if (taskType.indexOf("FIND THE...") === 0) {
      if (task.taskName.indexOf('Rhyme') === 0) {
        this.isInterventionStudent ? this.router.navigateByUrl('/findTheRhymeIntervention', { state: { ...interventionStateParams } }) : this.router.navigateByUrl('/findTheRhyme');
      }
      else if (task.taskName.indexOf('Picture') === 0) {
        this.isInterventionStudent ? this.router.navigateByUrl('/findThePictureIntervention', { state: { ...interventionStateParams } }) : this.router.navigateByUrl('/findThePicture');
      }
      else if (task.taskName.indexOf('Number of Syllables') === 0) {
        this.router.navigateByUrl('/findTheSyllablesIntervention', { state: { ...interventionStateParams } });
      }
      else if ((task.taskName.indexOf('Syllable Breaks') === 0) ||
        (task.taskName.indexOf('Word Family') === 0) ||
        (task.taskName.indexOf('Word') === 0)) {
        this.router.navigateByUrl('/findTheWordSyllablesIntervention', { state: { ...interventionStateParams } });
      }
      else if (task.taskName.indexOf('Phrase') === 0) {
        this.router.navigateByUrl('/findThePhraseIntervention', { state: { ...interventionStateParams } });
      }
      else {
        console.log("Task not implemented yet", taskType, task.taskName);
      }
    }
    else if (taskType.indexOf("MAKE THE...") === 0) {
      this.router.navigateByUrl('/makeTheIntervention', { state: { ...interventionStateParams } });
    }
    else if (taskType.indexOf("CHANGE THE...") === 0)
    {
      if (task.taskName.indexOf('Meaning') === 0)
      {
        this.router.navigateByUrl('/changeTheMeaningIntervention', { state: { ...interventionStateParams } }) ;
      }
      else
      {
        this.isInterventionStudent ? this.router.navigateByUrl('/changeTheWordIntervention', { state: { ...interventionStateParams } }) : this.router.navigateByUrl('/changeTheBlank');
      }
    }
    else if (taskType.indexOf("SPELLING") === 0) {
      this.router.navigateByUrl('/spellingIntervention', { state: { ...interventionStateParams } });
    }
    else if (taskType.indexOf("PASSAGES") === 0)
    {
      this.router.navigateByUrl('/subtaskSelect', { state: { mainTaskId: task.id } }) ;
    }
    else if (taskType.indexOf("READ THE") === 0) {
      if (task.taskName.indexOf('Word') === 0) {
        this.router.navigateByUrl('/readTheWordIntervention', { state: { ...interventionStateParams } }) ;
      }
      else if (task.taskName.indexOf('Sentence') === 0) {
        this.router.navigateByUrl('/readTheSentenceIntervention', { state: { ...interventionStateParams } }) ;
      }
      else {
        console.log("Task not implemented yet", taskType, task.taskName);
      }
    }
    else
    {
      console.log("Task not implemented yet", taskType);
    }
  }
}
