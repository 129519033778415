export class ResponseTile {
  public index: number;
  public text: string;
  public audio: string;
  public highlight: boolean;
  public isCorrect: boolean;

  constructor(index: number, text: string, audio: string, highlight: boolean, isCorrect: boolean) {
    this.index = index;
    this.text = text;
    this.audio = audio;
    this.highlight = highlight;
    this.isCorrect = isCorrect;
  }
}