import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef} from '@angular/core'

@Component({
  selector: 'focus-dialog',
  templateUrl: './focus-dialog.component.html',
})
export class FocusDialog implements OnInit {

  ngShowFocusDialog: boolean;
  
  constructor(private cdRef : ChangeDetectorRef) { 
    this.ngShowFocusDialog = false;
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() : void {
    this.cdRef.detectChanges();
  }
  
  focusDialogPositive(){
    this.ngShowFocusDialog = false;
    this.cdRef.detectChanges();
  }

  showDialog(){
    this.ngShowFocusDialog = true;
    this.cdRef.detectChanges();
  }

}
