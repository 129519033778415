<div class="viewport-1" id="task-select" tabindex="0" (keyup)="autoCompleteTask($event)" [style.background-image]="'url(' + taskSelectBackgroundImage + ')'">
  <student-header #studentHeader></student-header>
  <side-menu #sideMenu (selectedAvatar)="updateSelectedAvatar($event)" [disabled]="disableSideButton"></side-menu>

  <div class="task-select-content-div" *ngIf="!shouldDisplayAlternatingTasks; else notAlternatingTasks">
    <div class="task-wrapper">
      <div *ngFor="let taskButton of taskButtonList1; let idx = index" [ngClass]="taskButton.divContainer" [hidden]="!taskButton.visible">
        <button class="task-button"
                [ngClass]="{'task-button-disabled': taskButton.disabled}"
                [ngStyle]="{'background-image':'url(' + taskButton.buttonImage + ')'}"
                [disabled]="taskButton.disabled || disableButtons"
                (click)="goToTask(taskButton.index)"
                (touchstart)="highlightTaskButton(1, idx)"
                (touchend)="handleTouchEnd(1, idx, taskButton.index, $event)"
                (mouseover)="highlightTaskButton(1, idx)"
                (mouseleave)="unhighlightTaskButton(1, idx)">

          <p class="font-18 task-btn-task-type" [hidden]="taskButton.showPassageTitle">{{taskButton.type}}</p>
          <p class="font-18 task-btn-task-name" [hidden]="taskButton.showPassageTitle">{{taskButton.name}}</p>
          <p [hidden]="!taskButton.showPassageTitle" class="task-btn-passage-title font-passage-title">{{ taskButton.passageTitle | formatString }}</p>
          <img class="task-btn-icon" [src]="taskButton.icon">
          <p class="font-18 task-btn-task-points" *ngIf="shouldCompareStudentScores && taskButton.shouldDisplayPreviousScore()">{{ taskButton.displayPreviousScore() }}</p>
          <p class="font-18 task-btn-task-score" *ngIf="taskButton.shouldDisplayScore()">{{ taskButton.displayScore() }}</p>

        </button>
      </div>
    </div>

    <div class="task-wrapper">
      <div *ngFor="let taskButton of taskButtonList2; let idx = index" [ngClass]="taskButton.divContainer" [hidden]="!taskButton.visible">
        <button class="task-button"
                [ngClass]="{'task-button-disabled': taskButton.disabled}"
                [ngStyle]="{'background-image':'url(' + taskButton.buttonImage + ')'}"
                [disabled]="taskButton.disabled || disableButtons"
                (click)="goToTask(taskButton.index)"
                (touchstart)="highlightTaskButton(2, idx)"
                (touchend)="handleTouchEnd(2, idx, taskButton.index, $event)"
                (mouseover)="highlightTaskButton(2, idx)"
                (mouseleave)="unhighlightTaskButton(2, idx)">

          <p class="font-18 task-btn-task-type" [hidden]="taskButton.showPassageTitle">{{taskButton.type}}</p>
          <p class="font-18 task-btn-task-name" [hidden]="taskButton.showPassageTitle">{{taskButton.name}}</p>
          <p [hidden]="!taskButton.showPassageTitle" class="task-btn-passage-title font-passage-title">{{ taskButton.passageTitle | formatString }}</p>
          <img class="task-btn-icon" [src]="taskButton.icon">
          <p class="font-18 task-btn-task-points" *ngIf="shouldCompareStudentScores && taskButton.shouldDisplayPreviousScore()">{{ taskButton.displayPreviousScore() }}</p>
          <p class="font-18 task-btn-task-score" *ngIf="taskButton.shouldDisplayScore()">{{ taskButton.displayScore() }}</p>

        </button>
      </div>
    </div>

    <div class="task-wrapper">
      <div *ngFor="let taskButton of taskButtonList3; let idx = index" [ngClass]="taskButton.divContainer" [hidden]="!taskButton.visible">
        <button class="task-button"
                [ngClass]="{'task-button-disabled': taskButton.disabled}"
                [ngStyle]="{'background-image':'url(' + taskButton.buttonImage + ')'}"
                [disabled]="taskButton.disabled || disableButtons"
                (click)="goToTask(taskButton.index)"
                (touchstart)="highlightTaskButton(3, idx)"
                (touchend)="handleTouchEnd(3, idx, taskButton.index, $event)"
                (mouseover)="highlightTaskButton(3, idx)"
                (mouseleave)="unhighlightTaskButton(3, idx)">

          <p class="font-18 task-btn-task-type" [hidden]="taskButton.showPassageTitle">{{taskButton.type}}</p>
          <p class="font-18 task-btn-task-name" [hidden]="taskButton.showPassageTitle">{{taskButton.name}}</p>
          <p [hidden]="!taskButton.showPassageTitle" class="task-btn-passage-title font-passage-title">{{ taskButton.passageTitle | formatString }}</p>
          <img class="task-btn-icon" [src]="taskButton.icon">
          <p class="font-18 task-btn-task-points" *ngIf="shouldCompareStudentScores && (taskButton.previousScore === -1 || taskButton.previousScore > 0)">{{taskButton.previousScore}}%</p>
          <p class="font-18 task-btn-task-score" *ngIf="taskButton.shouldDisplayScore()">{{ taskButton.displayScore() }}</p>

        </button>
      </div>
    </div>
  </div>
  <ng-template #notAlternatingTasks>
  <div class="pre-diagnostic-task-select-content-div">
    <div *ngFor="let taskButton of taskButtonList; let idx = index" class="pre-task-wrapper">
      <div [ngClass]="taskButton.divContainer" [hidden]="!taskButton.visible">
        <button class="task-button"
                [ngStyle]="{'background-image':'url(' + taskButton.buttonImage + ')'}"
                [disabled]="taskButton.disabled || disableButtons"
                (click)="goToTask(taskButton.index)"
                (touchstart)="highlightTaskButton(0, idx)"
                (touchend)="handleTouchEnd(0, idx, taskButton.index, $event)"
                (mouseover)="highlightTaskButton(0, idx)"
                (mouseleave)="unhighlightTaskButton(0, idx)">

          <p class="font-18 pre-task-btn-task-type" [hidden]="taskButton.showPassageTitle">{{taskButton.type}}</p>
          <p class="font-18 pre-task-btn-task-name" [hidden]="taskButton.showPassageTitle">{{taskButton.name}}</p>
          <p [hidden]="!taskButton.showPassageTitle" class="task-btn-passage-title font-passage-title">{{ taskButton.passageTitle | formatString }}</p>
          <img class="pre-task-btn-icon" [src]="taskButton.icon">
          <p class="font-18 pre-task-btn-task-score"
             [ngClass]="{'left-task-score': taskButton.pointsLeft, 'secondary-theme' : (taskButton.theme === themes.secondary) }"
             *ngIf="isPreDiagnosticStudent && taskButton.shouldDisplayScore()">{{ taskButton.displayScore() }}</p>
          <p class="font-18 diagnostic-task-btn-task-prev-score"
             [ngClass]="{'left-task-score': taskButton.pointsLeft, 'secondary-theme' : (taskButton.theme === themes.secondary) }"
             *ngIf="!isPreDiagnosticStudent && shouldCompareStudentScores && taskButton.shouldDisplayPreviousScore()">{{ taskButton.displayPreviousScore() }}</p>
          <p class="font-18 diagnostic-task-btn-task-score"
             [ngClass]="{'left-task-score': taskButton.pointsLeft, 'secondary-theme' : (taskButton.theme === themes.secondary) }"
             *ngIf="!isPreDiagnosticStudent && taskButton.shouldDisplayScore()">{{ taskButton.displayScore() }}</p>
        </button>
      </div>
    </div>
  </div>
  </ng-template>
  <student-footer #studentFooter timerBackgroundImage="/assets/images/timer/timerLabel-task.svg"></student-footer>
  <focus-dialog></focus-dialog>
</div>
