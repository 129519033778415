<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]='taskTotalPoints'></task-bar>

  <div class="task-screen-element find-the-rhyme-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'"
        (click)="replayVideo()" [disabled]="disableVideo()"></button>
    </div>
    <div class="word-to">
      <div class="word-masked font-42">
        {{targetWord}}
      </div>
      <button [ngClass]="{'again-button-grayed' : disableAgainButton, 'again-button' : !disableAgainButton, 'toggle-again-on' : toggleAgainButton, 'again-button-hidden': hideAgainButton}"
              *ngIf="!unmaskedTask"
              [disabled]="disableAgainButton"
              (click)="displayTrialAgain()">
      </button>
    </div>
    <div class="response-row">
      <button *ngIf="!unmaskedTask"
      [ngClass]="{'next-button-grayed' : disableNextButton, 'next-button' : !disableNextButton, 'toggle-next-on' : toggleNextButton, 'next-button-hidden': hideNextButton}"
      [disabled]="disableNextButton" (click)="displayTrial()"></button>
    </div>
    <div class="full-response-row" *ngIf="!hideResponses">
      <div class="big-button-div" *ngFor="let response of responseOptions1; index as $index">
        <button class = "rhyme-letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "rhyme-letter-audio" *ngIf="showResponseAudioButtons"
          (click)="playResponseAudio($index)" [disabled]="disableAVButtons"></button>
        <button #respButton1 class="rhyme-instructional-response-block font-30"
                [ngClass]="{'hover': response.highlight}"
                (click)="submitResponse($index); removeHoverClass(respButton1);"
                (touchstart)="addHoverClass(respButton1)" (touchend)="removeHoverClass(respButton1)"
                (mouseenter)="addHoverClass(respButton1)" (mouseleave)="removeHoverClass(respButton1)"
                [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
                [disabled]="disableResponseButtons">
          {{response.text}}
        </button>
      </div>
    </div>
    <div class="full-response-row" *ngIf="!hideResponses">
      <div class="big-button-div" *ngFor="let response of responseOptions2; index as $index">
        <button class = "rhyme-letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "rhyme-letter-audio" *ngIf="showResponseAudioButtons"
          (click)="playResponseAudio($index+3)" [disabled]="disableAVButtons"></button>
        <button #respButton2 class="rhyme-instructional-response-block font-30"
            [ngClass]="{'hover': response.highlight}"
            (click)="submitResponse($index+3); removeHoverClass(respButton2);"
            (touchstart)="addHoverClass(respButton2)" (touchend)="removeHoverClass(respButton2)"
            (mouseenter)="addHoverClass(respButton2)" (mouseleave)="removeHoverClass(respButton2)"
            [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
            [disabled]="disableResponseButtons">
          {{response.text}}
        </button>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>
{{runChangeDetection}}
