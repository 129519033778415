<div class="footer-div">
  <div class="logout-div">
    <div class="logout-button" (click)="logoutOrShowConfirmationDialogue()"></div>
  </div>
  <div class="countdown-wrapper">
    <!-- TODO: Pass in  correct background for countdown clock from the two select screens. Right now style defaults to level-select screen -->
    <div class="countdown-div" [ngStyle]="{'background-image': 'url(' + timerBackgroundImage + ')'}" *ngIf="showCountdown" >
      <div class="countdown-number font-20">{{ secondsLeft }}</div>
    </div>
    <canvas #timerCanvas style="width: 5.5%; z-index: 1; position: absolute;"  [hidden]="!showCountdown" id="countdownCanvas" width="300" height="300"></canvas>
  </div>
</div>


<div class="logout-confirm-dialog-background" *ngIf="showLogoutConfirmation"></div>
<div class="logout-confirm-dialog-container" *ngIf="showLogoutConfirmation">
  <div class="try-again-dialog-top-container">
    <label class="logout-confirm-text font-24">There is still time left in your session. Are you sure you want to logout?</label>
  </div>
  <div class="try-again-dialog-bottom-container">
    <button class="try-again-dialog-btn" (click)="logout()">
      <span class='try-again-text font-24'>Yes</span>
    </button>
    <button class="try-later-dialog-btn" (click)="toggleConfirmation()">
      <span class='try-later-text font-24'>No</span>
    </button>
  </div>
</div>