import { Component } from '@angular/core';

@Component({
  selector: 'please-wait-dialog',
  templateUrl: './please-wait-dialog.component.html',
})
export class PleaseWaitDialogComponent {
  show: boolean = false;

  constructor() { }
}
