import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { StudentDataService } from '../services/student-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private studentDataService: StudentDataService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean | UrlTree {
    // Since we wrap all of the routes as children for this AuthGuard, we
    // need to check the children of the route snapshot to get the furthest
    // nested child route to see if the route needs to be authenticated or not.
    let deepestChild: ActivatedRouteSnapshot = route;
    while (deepestChild.firstChild)
    {
      deepestChild = deepestChild.firstChild;
    }

    let isUnauthorizedRoute: boolean = deepestChild.data.needs_auth === false;
    if (!this.studentDataService.isLoggedIn() && !isUnauthorizedRoute)
    {
      return this.router.createUrlTree(['/login']);
    }

    if (state.url === '/demo' && !this.studentDataService.isDemoUser())
    {
      // Navigate back to the current page if the user tries to
      // navigate to the demo page and is not authorized.
      return this.router.createUrlTree(['..']);
    }

    return true;
  }
  
}
