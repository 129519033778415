<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element fill-in-blank-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    </div>
    <div class="word-to-complete-unit">
      <button class="speaker" (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons"></button>
      <div class="make-the-target-row">
        <div class="make-the-response-target-block stw-target intervention-target-block"
          [ngClass]="{'hover': ($index===blankTile)}"
          *ngFor="let letter of targetWord; index as $index"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}">
          <button class = "make-the-target-letter-audio-hidden" *ngIf="!oneIncorrectAnswer"></button>
          <button class = "make-the-target-letter-audio" *ngIf="oneIncorrectAnswer"
            (click)="playTargetLetterAudio($index)" [disabled]="disableAVButtons"></button>
          <button class="make-the-target-text font-30">
            {{letter.text}}
          </button>
          <button [ngClass]="{'make-the-remove-letter-disabled': letter.disabled, 'make-the-remove-letter': !letter.disabled, 'make-the-remove-letter-disabled-hover': ($index===blankTile)}"
            (click)="removeLetter($index)" [disabled]="letter.disabled"></button>
        </div>
      </div>
      <button (click)="submitResponse()" [ngClass]="(disableSubmitButton) ? ['star-submit-disabled'] : ['star-submit']"
        [disabled]="disableSubmitButton" *ngIf="!hideSubmitButton" ></button>
    </div>
    <div class="make-the-response-row">
      <div [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-button-div'] : ['big-button-div']"
        *ngFor="let response of responseOptions1; index as $index">
        <button [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-div-letter-audio-hidden'] : ['big-div-letter-audio-hidden']"
          *ngIf="!showResponseAudioButtons"></button>
        <button [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-div-letter-audio'] : ['big-div-letter-audio']"
          *ngIf="showResponseAudioButtons" (click)="playLetterAudio(response.audio)" [disabled]="disableAVButtons"></button>
        <button #resp1Button [ngClass]="(makeThePhrase) ? ['make-the-phrase-response-block'] : ['make-the-response-block']"
          class = "font-30"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="responsesDisabled" (click)="addLetter(response.index); removeHoverClass(resp1Button);"
          (mouseenter)="addHoverClass(resp1Button)" (mouseleave)="removeHoverClass(resp1Button)"
          (touchstart)="addHoverClass(resp1Button)" (touchend)="removeHoverClass(resp1Button)">
          {{response.text}}
        </button>
      </div>
    </div>
    <div class="make-the-response-row">
      <div [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-button-div'] : ['big-button-div']"
        *ngFor="let response of responseOptions2; index as $index">
        <button [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-div-letter-audio-hidden'] : ['big-div-letter-audio-hidden']"
          *ngIf="!showResponseAudioButtons"></button>
        <button [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-div-letter-audio'] : ['big-div-letter-audio']"
          *ngIf="showResponseAudioButtons" (click)="playLetterAudio(response.audio)" [disabled]="disableAVButtons"></button>
        <button #resp2Button [ngClass]="(makeThePhrase) ? ['make-the-phrase-response-block'] : ['make-the-response-block']"
          class = "font-30"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="responsesDisabled" (click)="addLetter(response.index); removeHoverClass(resp2Button)"
          (mouseenter)="addHoverClass(resp2Button)" (mouseleave)="removeHoverClass(resp2Button)"
          (touchstart)="addHoverClass(resp2Button)" (touchend)="removeHoverClass(resp2Button)">
          {{response.text}}
        </button>
      </div>
    </div>
    <div class="make-the-response-row">
      <div [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-button-div'] : ['big-button-div']"
        *ngFor="let response of responseOptions3; index as $index" [hidden]="!showResponseOptions3">
        <button [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-div-letter-audio-hidden'] : ['big-div-letter-audio-hidden']"
          *ngIf="!showResponseAudioButtons"></button>
        <button [ngClass]="(makeThePhrase) ? ['make-the-phrase-big-div-letter-audio'] : ['big-div-letter-audio']"
          *ngIf="showResponseAudioButtons" (click)="playLetterAudio(response.audio)" [disabled]="disableAVButtons"></button>
        <button #resp3Button [ngClass]="(makeThePhrase) ? ['make-the-phrase-response-block'] : ['make-the-response-block']"
          class = "font-30"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="responsesDisabled" (click)="addLetter(response.index); removeHoverClass(resp3Button)"
          (mouseenter)="addHoverClass(resp3Button)" (mouseleave)="removeHoverClass(resp3Button)"
          (touchstart)="addHoverClass(resp3Button)" (touchend)="removeHoverClass(resp3Button)">
          {{response.text}}
        </button>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
  <focus-dialog></focus-dialog>
</div>
