export const environment = {
  production: true,
  SENTRY_DSN: '',
  EC2URL: 'https://ptwfservices.wordflight.com/',
  AssetServerURL: 'https://ptstudent.wordflight.com',
  VideoAssetServerURL: 'https://ptstudent.wordflight.com',
  CLEVER_REDIRECT_URI: 'https://wfservices.wordflight.com/clever/login',
  CLEVER_CLIENT_ID: '6c6d4dfbf1c99e1e747e',
  CLASSLINK_REDIRECT_URI: '',
  CLASSLINK_CLIENT_ID: '',
  versionNumber: '5.015',
  'appStoreLink': 'https://apps.apple.com/us/app/wordflight-student/id1584915043',
  // Specifies how many seconds a student should finish a trial within.
  // If a task takes  more than this time on aggregate, student will
  // be notified and poor usage might be reported.
  trialSecondsLimit: 60,
  RTW_MAX_RECORD_SEC: 10,
  RTS_MAX_RECORD_SEC: 30,
};
