import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AvatarItem, Leaderboard, Team, TeamWithPoints } from 'src/app/core/models/student-data.model';
import { SideMenuTheme } from 'src/app/core/models/theme.model';
import { AssetPreloaderService } from 'src/app/core/services/asset-preloader.service';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { CarouselItem, TeamStanding } from './side-menu.model';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
})
export class SideMenuComponent implements OnInit {
  private achievements!: Leaderboard;
  private lastTeamStanding!: number;
  private teamsWithPoints!: TeamWithPoints[];
  private endOfAnimation: boolean = true;
  private hasSeenAnimation: boolean = false;
  private currentTeamStanding!: number;
  private page: number = 1;
  private avatarList!: AvatarItem[];
  private sideButtonIn: string = '/assets/images/sideMenu/bttn_sideMenuIn_bu.svg';
  private sideButtonOut: string = '/assets/images/sideMenu/bttn_sideMenuOut_bu.svg';
  private imageList: any[] = [];

  usersTeam: Team | null = null ;
  studentsTeamPoints?: TeamWithPoints;
  showLeaderboard: boolean = false;
  showAvatarMenu: boolean = false;
  showLevel: boolean = false;
  isSideMenuOpen: boolean = false;
  sideMenuBtnImage: string = this.sideButtonOut;
  currentTab: number = 1;
  school!: string;
  teamStandingList: TeamStanding[] = [];
  disableScrollLeft: boolean = false;
  disableScrollRight: boolean = false;
  usersCurrentAvatar!: AvatarItem;
  carouselItemList: CarouselItem[] = [];
  dailyPoints!: number;
  weeklyPoints!: number;
  totalPoints!: number;
  level!: number;
  sideMenuTheme!:  SideMenuTheme;
  previewedAvatar?: CarouselItem;
  weeklyTime!: number;
  weeklyGoalProgress!: number;
  weeklyGoal!: number;
  currentUnit?: number = undefined;
  achievementsBackground: string = '';

  @Input('disabled') disableSideButton: boolean = false;
  @Output() selectedAvatar = new EventEmitter<AvatarItem>();


  constructor(
    private audioPlayerService: AudioPlayerService,
    private assetPreloader: AssetPreloaderService,
    private studentDataService: StudentDataService,
  ) { }

  ngOnInit(): void {
    this.achievements = this.studentDataService.getStudentLeaderboard();
    this.usersTeam = this.studentDataService.getStudentTeam();
    this.lastTeamStanding = this.studentDataService.getLastTeamStanding();
    this.showAvatarMenu = this.studentDataService.isFullProductSubscription();
    this.showLevel = !this.studentDataService.isDemoUser() && this.studentDataService.isFullProductSubscription();
    this.isSideMenuOpen = this.studentDataService.getSideMenuOpen();
    this.showLeaderboard = (this.usersTeam != null) && this.achievements.showingTeamResults;
    this.teamsWithPoints = this.achievements.teamsWithPoints;
    this.school = this.studentDataService.getStudentData().studentAssessment.school;
    this.usersCurrentAvatar = this.studentDataService.getCurrentStudentAvatar();
    this.avatarList = this.studentDataService.getAvatarList();
    this.sideMenuTheme = this.studentDataService.getSideMenuTheme();

    this.dailyPoints = this.achievements.studentDailyPoints;
    this.weeklyPoints = this.achievements.studentWeeklyPoints;
    this.totalPoints = this.achievements.studentTotalPoints;
    this.level = this.studentDataService.getCurrentLevel();

    this.weeklyTime = Math.round(this.studentDataService.getWeeklySessionTime() / 1000 / 60);
    this.weeklyGoalProgress = this.studentDataService.getWeeklyLevelsCompleted();
    this.weeklyGoal = 8;

    for (let category in this.sideMenuTheme)
    {
      if (category === 'leaderboard')
      {
        this.imageList.push(this.sideMenuTheme[category].background);
        this.imageList.push(this.sideMenuTheme[category].open);
        this.imageList.push(this.sideMenuTheme[category].closed);
        this.imageList = this.imageList.concat(this.sideMenuTheme[category].standings);
        this.imageList = this.imageList.concat(this.sideMenuTheme[category].currentStandings);
      }
      else if (category === "achievements" || category === "avatar")
      {
        for (const [key, image] of Object.entries(this.sideMenuTheme[category]))
        {
          this.imageList.push(image);
        }
      }
    }
    this.imageList.push(this.sideButtonIn);
    this.imageList.push(this.sideButtonIn.replace('bu', 'bh'));
    this.imageList.push(this.sideButtonOut);
    this.imageList.push(this.sideButtonOut.replace('bu', 'bh'));


    if (this.studentDataService.isFullProductSubscription())
    {
      let isUnit = this.studentDataService.isInterventionUnit();
      let isPreTest = this.studentDataService.isInterventionPreTest();
      let isPostTest = this.studentDataService.isInterventionPostTest();
      let currentLevel = this.studentDataService.getCurrentLevel();
      if (isUnit)
      {
        this.currentUnit = this.studentDataService.getStudentInterventionData()!.unitNumber!;
      }
      else if (isPostTest || (isPreTest && currentLevel >= this.studentDataService.getNumLevelsInPrePost()))
      {
        this.currentUnit = currentLevel / this.studentDataService.getNumLevelsInUnit();
      }
      else if (this.studentDataService.isMidDiagnostic() || this.studentDataService.isPostDiagnostic())
      {
        this.currentUnit = this.studentDataService.getLastCompletedUnit();
      }
    }
    else
    {
      this.currentUnit = 0;
    }
  }

  createCarouselItem(index: number, avatarItem: AvatarItem | null, visible: boolean, disabled: boolean): CarouselItem {
    let selected = false;
    return {
      avatarItem,
      index,
      visible,
      selected,
      disabled,
    }
  }


  createCarouselList() {
    this.avatarList = this.studentDataService.getAvatarList();
    for (let i=0; i < 36; i++)
    {
      if (i < this.avatarList.length)
      {
        this.carouselItemList.push(this.createCarouselItem(i, this.avatarList[i], i < 12, false));
      }
      else
      {
        this.carouselItemList.push(this.createCarouselItem(i, null, i < 12, true));
      }
    }
    if (this.page === 1)
    {
      this.disableScrollLeft = true;
    }

    if (this.page*12 >= this.avatarList.length)
    {
      this.disableScrollRight = true;
    }
  }


  createTeamStanding(index: number, teamName: string, weeklyPoints: number): TeamStanding{
    let background: { 'background-image': string };
    let animation: string;

    if (index === this.currentTeamStanding)
    {
      background = {
        'background-image': 'url(' + this.sideMenuTheme.leaderboard.currentStandings[index] + ')'
      }
    }
    else{
      background = {
        'background-image': 'url(' + this.sideMenuTheme.leaderboard.standings[index] + ')'
      }
    }

    if (!this.hasSeenAnimation && this.currentTeamStanding < this.lastTeamStanding && index == this.currentTeamStanding)
    {
      animation = 'animate-standing-' + (this.lastTeamStanding - this.currentTeamStanding);
    }
    else
    {
      animation = '';
    }

    return {
      index,
      teamName,
      weeklyPoints,
      background,
      animation,
    }
  }

  highlightButton() {
    this.sideMenuBtnImage = this.sideMenuBtnImage.replace('bu', 'bh');
  }

  unhighlightButton() {
    this.sideMenuBtnImage = this.sideMenuBtnImage.replace('bh', 'bu');
  }

  toggleSideMenu() {
    if (this.carouselItemList.length === 0)
    {
      this.createCarouselList();
    }

    this.isSideMenuOpen = !this.isSideMenuOpen;
    if (this.isSideMenuOpen)
    {
      this.sideMenuBtnImage = this.sideButtonIn;
      switch (this.currentTab)
      {
        case 1:
            this.openAchievements();
            break;
        case 2:
            this.openLeaderboard();
            break;
        case 3:
            this.openAvatarMenu();
            break;
      }
    }
    else
    {
      this.sideMenuBtnImage = this.sideButtonOut;
    }
  }

  openAchievements() {
    this.currentTab = 1;
  }

  private endAnimation() {
      this.teamStandingList[this.currentTeamStanding].animation = '';
      this.teamStandingList[this.currentTeamStanding].background = {
        'background-image': 'url(' + this.sideMenuTheme.leaderboard.currentStandings[this.currentTeamStanding] + ')'
      }
      this.endOfAnimation = true;
      this.hasSeenAnimation = true;
      this.studentDataService.setLastTeamStanding(this.currentTeamStanding);
  }

  openLeaderboard() {
    this.currentTab = 2;
    if (!this.hasSeenAnimation && this.currentTeamStanding < this.lastTeamStanding)
    {
      this.endOfAnimation = false;
      setTimeout(() => {
        this.endAnimation();
      }, 2400);
    }
  }

  openAvatarMenu() {
    this.currentTab = 3;
  }

  scrollLeft() {
    this.page--;
    this.disableScrollRight = false ;
    this.displayCorrectPages();

    if (this.page === 1)
    {
      this.disableScrollLeft = true;
    }
  }

  scrollRight() {
    this.page++;
    this.disableScrollLeft = false;
    this.displayCorrectPages();
    if (this.page*12 >= this.avatarList.length)
    {
      this.disableScrollRight = true;
    }
  }

  private displayCorrectPages() {
    for (var i=0; i < 36; i++)
    {
      if (i < this.page*12 && i >= (this.page-1) * 12)
      {
        this.carouselItemList[i].visible = true;
      }
      else
      {
        this.carouselItemList[i].visible = false;
      }
    }
  }

  previewAvatar(index: number) {
    if (this.previewedAvatar != null)
    {
      this.carouselItemList[this.previewedAvatar.index].selected = false;
    }
    this.carouselItemList[index].selected = true;
    this.previewedAvatar = this.carouselItemList[index];
  }

  selectAvatar(){
    if (this.previewedAvatar?.avatarItem)
    {
      let avatarItem: AvatarItem = this.previewedAvatar.avatarItem;
      this.usersCurrentAvatar = avatarItem;
      this.studentDataService.setCurrentStudentAvatar(avatarItem);
      this.selectedAvatar.emit(avatarItem);
    }
  }

  hideScore(standing: number) {
    return (this.currentTeamStanding !== standing && !this.endOfAnimation)
  }


  runTeamStandingAnimation(): Observable<any> {
    return new Observable((observer) => {
      if (this.showLeaderboard)
      {
        for (let i = 0; i < this.teamsWithPoints.length; i++)
        {
          this.teamStandingList.push(this.createTeamStanding(i, this.teamsWithPoints[i].team.teamName, this.teamsWithPoints[i].weeklyPoints));
        }
      }
      if (this.showAvatarMenu)
      {
        this.createCarouselList();
      }

      if (this.lastTeamStanding > this.currentTeamStanding)
      {
        this.audioPlayerService.play('Audio/Other/team_ranking_change.mp3').subscribe();
        this.currentTab = 2;
        this.toggleSideMenu();
        // wait for end of animation
        setTimeout(() => {
          // TODO: Should I emit an event when disable side button changes for parent components???
          this.disableSideButton = false;
          observer.next();
          this.endAnimation();
        },3000);
      }
      else
      {
        this.studentDataService.setLastTeamStanding(this.currentTeamStanding);
        this.disableSideButton = false;
        observer.next();
      }
    });
  }

  checkForTeamStandingAnimation(): Observable<any> {
    if (this.usersTeam != null)
    {
      for (const [index, teamWithPoints] of this.teamsWithPoints.entries())
      {
        if (this.usersTeam.teamID === teamWithPoints.team.teamID)
        {
          this.studentsTeamPoints = teamWithPoints;
          this.currentTeamStanding = index;
          break;
        }
      }
    }

    return this.assetPreloader.preloadImages(this.imageList).pipe(
      // Using concat map to run when all the imagelist is loaded.
      concatMap(() => this.runTeamStandingAnimation()),
    );
  }
}
