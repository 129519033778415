import { Injector, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { SharedModule } from './shared/shared.module';
import { BasicAuthorizationInterceptor } from './core/interceptors/basic-authorization.interceptor';
import { CleverAuthorizationInterceptor } from './core/interceptors/clever-authorization.interceptor';
import { ClasslinkAuthorizationInterceptor } from './core/interceptors/classlink-authorization.interceptor' ;
import { NoCacheInterceptor } from './core/interceptors/no-cache.interceptor';
import { TaskModule } from './tasks/task.module';
import { TimeupComponent } from './timeup/timeup.component';
import { WorldNavigationModule } from "./world-navigation/world-navigation.module";
import { DemoModule } from './demo/demo.module';

@NgModule({
  declarations: [
    AppComponent,
    TimeupComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    LoginModule,
    DemoModule,
    SharedModule,
    WorldNavigationModule,
    TaskModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CleverAuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ClasslinkAuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, })},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // For manually injecting some of the deps
  static injector: Injector;
  constructor(injector: Injector) {
      AppModule.injector = injector;
  }
}
