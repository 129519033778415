import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial, Response } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { ResponseTile } from './find-the-phrase-intervention.model';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'app-find-the-phrase-intervention',
  templateUrl: './find-the-phrase-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindThePhraseInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
    public router: Router,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
        .pipe(first(),
          map(() => {
            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          // NOTE: we always play the instructional audio on the first trial, so only check for video here
        )
        .subscribe({
          complete: () => this.displayTrial(this.trialIndex),
          error: () => this.displayTrial(this.trialIndex),
        });
    });

    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  trialResponseList: Response[] = [];
  numberOfAttemptsForTrial: number = 0;
  originalStartTime: number = 0;
  firstResponseTime: number = 0;
  secondResponseTime: number = 0;
  responseOptions: ResponseTile[] = [];

  // Variable to prevent multiple responses from being clicked which would change the student's answer
  responsesDisabled: boolean = true;

  addHoverClass(element: HTMLElement) {
    element.classList.add('hover');
  }

  removeHoverClass(element: HTMLElement) {
    element.classList.remove('hover');
  }

  // Update the total points on $scope (generally used as a callback to the this.interventionTaskService)
  updateTotalPoints(points: number) {
    this.taskTotalPoints += points;
  }

  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.playTargetAudio();
  }

  // Set up audio for the target speaker button
  playTargetAudio() {
    let targetAudioFile = this.trials[this.trialIndex].phrase!['@audio'];
    this.audioPlayerService.play(targetAudioFile).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('find-the-phrase-intervention', 'play-target-audio', targetAudioFile, err)
    });
  }

  // Set up audio for the response speaker button
  playResponseAudio(index: number) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.trialResponseList[index]['@audio']!).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('find-the-phrase-intervention', 'play-response-audio', this.trialResponseList[index]['@audio']!, err)
    });

    this.reusableTimer = window.setTimeout(() => {
      this.responsesDisabled = false;
    }, this.interventionTaskService.letterAudioDelay);
  }

  firstResponseIncorrectSequence() {
    let targetAudioFile = this.trials[this.trialIndex].phrase!['@audio'];
    this.audioPlayerService.play(targetAudioFile).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('find-the-phrase-intervention', 'first-response-incorrect', targetAudioFile, err),
      complete: () => {
        this.reusableTimer = window.setTimeout(() => {
          this.removeResponseHighlighting();
          this.showResponseAudioButtons = true;
          this.originalStartTime = this.timerService.startTimer();
          this.responsesDisabled = false;
          this.disableAVButtons = false;
          this.changeDetector.markForCheck() ;
        }, 0);
      }
    });
  }

  secondResponseIncorrectSequence() {
    this.highlightCorrectResponse();
    this.playTargetAudio();
    this.reusableTimer = window.setTimeout(() => {
      let responseObject = this.trialList[this.trialList.length - 1];
      this.updateTotalPoints(responseObject.points);
      this.endOfTrialCallback();
    }, this.interventionTaskService.moveToNextTrialDelay);
  }

  // Function for what happens when user clicks on response tile
  submitResponse(selectedResponse: number) {
    this.responsesDisabled = true;
    this.disableAVButtons = true;
    ++this.numberOfAttemptsForTrial;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    if (this.numberOfAttemptsForTrial === 1){
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      this.secondResponseTime = 0;
    }
    else{
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    }

    // Record the student's response
    let responseTile = this.responseOptions[selectedResponse];
    responseTile.highlight = true;
    let isCorrect = responseTile.isCorrect;
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.playSoundEffect(isCorrect);
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, responseTile.text);

    if (isCorrect) {
      let isTrialCorrect = isCorrect && (this.numberOfAttemptsForTrial == 1);
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      if (isTrialCorrect) {
        this.numberOfCorrectTrials++;
      }
      this.trialList.push(responseObject);

      this.taskService.answerTrial(isTrialCorrect) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.endOfTrialCallback();
        }
      });
    }
    else if (this.numberOfAttemptsForTrial === 1) {
      // One incorrect response
      this.reusableTimer = window.setTimeout(() => {
        // Play "Please try again..." and then play the word audio
        this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
          complete: () => this.firstResponseIncorrectSequence(),
          error: () => this.firstResponseIncorrectSequence()
        });
      }, this.interventionTaskService.firstIncorrectDelay);
    }
    else {
      // Two incorrect responses
      this.interventionTaskService.trackResponseTrends(isCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex, this.firstResponseTime,
          this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      this.trialList.push(responseObject);
      this.taskService.answerTrial(isCorrect) ;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
          complete: () => this.secondResponseIncorrectSequence(),
          error: () => this.secondResponseIncorrectSequence()
        });
      }, this.interventionTaskService.secondIncorrectDelay);
    }
  }

  removeResponseHighlighting() {
    for (let response in this.responseOptions) {
      this.responseOptions[response].highlight = false;
    }
  }

  highlightCorrectResponse() {
    for (let response in this.responseOptions) {
      this.responseOptions[response].highlight = this.responseOptions[response].isCorrect;
    }
    this.changeDetector.markForCheck() ;
  }

  createResponseList() {
    this.responseOptions = [];
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    // Create a shallow copy of the response list
    let responseList = this.trials[this.trialIndex]['resp-list']!.resp.slice();

    // Reduce the response list if applicable
    responseList = this.interventionTaskService.reduceResponsesIfNecessary(responseList);

    // Shuffle the responses if the curriculum calls for it.
    responseList = this.interventionTaskService.shuffleResponses(responseList, this.trials[this.trialIndex]['resp-list']!['@randomResponses']);

    // Set the global trial response list to the now fully formed trimmed/shuffled list.
    this.trialResponseList = responseList;

    // Build the response lists
    let notHighlighted = false;
    let index = 0;
    let responseText;

    for (let respIndex in responseList) {
      responseText = responseList[respIndex]['#text'];
      let isCorrectResponse = (responseList[respIndex]['@type'] === 'Correct');
      if (isCorrectResponse) {
        this.dataTracker.targetAnswer = responseText;
      }
      this.responseOptions.push(new ResponseTile(index, responseText, notHighlighted, isCorrectResponse));
      ++index;
    }

    this.showResponseAudioButtons = this.interventionTaskService.hasInitialAudioSupport();
    this.changeDetector.markForCheck() ;
  }

  trialLoopAudioComplete() {
    // Allow user to submit a response to the trial
    this.reusableTimer = window.setTimeout(() => {
      this.responsesDisabled = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;
    }, 0);

    this.originalStartTime = this.timerService.startTimer();
    this.startTime = this.originalStartTime;
    let timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
    if (timerBarTaskSettings.timerBarEnabled) {
      let initialDelay = this.interventionTaskService.trialBarBaseDelay + timerBarTaskSettings.timerBarDelay;
      this.trialTimerBar.startTrialTimer(timerBarTaskSettings.timerBarSpeed, initialDelay);
    }
  }

  trialInstructionalAudioComplete() {
    // Trial instructional audio is done - now play the target audio before
    // allowing the user to submit a response
    let targetAudioFile = this.trials[this.trialIndex].phrase!['@audio'];
    this.audioPlayerService.play(targetAudioFile).subscribe({
      complete: () => this.trialLoopAudioComplete(),
      error: (err: any) => {
        this.trialLoopAudioComplete() ;
        this.logAudioPlaybackSentryError('find-the-phrase-intervention', 'trial-instructional-audio', targetAudioFile, err);
      }
    });
  }

  displayTrial(newIndex: number) {
    this.numberOfAttemptsForTrial = 0;
    this.showResponseAudioButtons = false;

    // Remove any left over highlighting from a missed response in a previous trial
    this.removeResponseHighlighting();
    this.trialIndex = newIndex;
    this.createResponseList();

    // Plays the trial instructional audio just the first time, followed by enabling responses
    if (this.trialIndex === 0){
      this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
        complete: () => this.trialInstructionalAudioComplete(),
        error: () => this.trialInstructionalAudioComplete(),
      });
    } else {
      this.trialInstructionalAudioComplete();
    }
  }

  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  endOfTrialCallback() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();

      let newIndex = this.trialIndex + 1;
      if (newIndex < this.trials.length) {
        this.displayTrial(newIndex);
      } else {
        this.saveTaskData();
      }
    },this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error: (err: any) => {
        this.saveDataDialog.showSaveDataDialog() ;
        this.logSaveTaskSentryError('find-the-phrase-intervention', err) ;
      }
    });
  }
}
