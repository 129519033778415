import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'verify-the-blank-intervention',
  templateUrl: './verify-the-blank-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyTheBlankInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {

  targetWord: string = '';

  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public router: Router,
    public applicationStateService: ApplicationStateService,
    public taskService: TaskService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }

  ngOnInit(): void {
    this.trials = this.task.trial;
    // Check to shuffle responses
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    // Set timeout to ensure trialTimerBar isn't undefined onInit
    window.setTimeout(() => {
      this.trialTimerBar.hideTimerBar();
    }, 0)
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      //NOTE: if video or audio instructions are supposed to be played, returning of({}) pipes
      // to the next observable in the chain
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
      .pipe(first(),
        map(() => {
          // TODO: This can cause the trial timer bar to appear/disappear after the container fade-in animation
          //    get that to appear with the main task container
          let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
          timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
        }),
        concatMap(() => {
          if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
            this.playInstructionalAudio = false;
            return this.instructions.playInstructionalVideo();
          }
          else {
            return of({});
          }
        }),
        concatMap(() => {
          if (this.playInstructionalAudio) {
            return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
          } else {
            return of({});
          }
        }),
      )
      .subscribe({
        complete: () => this.audioCompleteFunc(),
        error: () => this.audioCompleteFunc(),
      });
    });
  }

  checkForClick(): void {
    if (!this.disableNextButton) {
      this.toggleNextButton = !this.toggleNextButton;
      this.changeDetector.markForCheck() ;
    }
  }

  createInterval(): void {
    this.intervalTimer = 0;

    // Run this function every second to see if the nextTrial button has been clicked
    this.intervalTimer = window.setInterval(() => {
      if (this.readyToHighlight) {
        this.checkForClick();
      }
    }, 1000, 0);
  }

  stopInterval(): void {
    if(this.intervalTimer !== null) {
      clearTimeout(this.intervalTimer);
    }
  }

  playWordAudio() {
    this.disableNextButton = true;
    this.disableAVButtons = false;
    this.changeDetector.markForCheck() ;
    this.playTrialWordAudio();
    this.responseTimeout = window.setTimeout(() => {
      this.disableResponseButtons = false;
      this.changeDetector.markForCheck() ;
    }, 750);
    this.reusableTimer = window.setTimeout(() => {
      this.readyToHighlight = true;
      this.changeDetector.markForCheck() ;
    }, 2000);
  }

  // TODO:
  //$scope.handleTouchEnd = function(response, $event)
  // Also handling adding/removing hover classes
  // I don't know if this will actually be necessary because
  // the click events should probably handle all this?
  // In the old implementation, the handleTouchEnd deals with class
  // changes and answer submission when responses are touched on a touch screen

  submitAnswer(bool: boolean) {
    this.endTime = this.timerService.stopTimer();

    // Set the selected response to 'y' or 'n' depending on if the user selects true or false for the word and audio matching
    let selectedResponse = bool ? 'y': 'n';
    let responseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    this.disableResponseButtons = true;
    this.disableAVButtons = true;
    this.targetWord = '';

    let targetAnswer = (this.trials[this.trialIndex].correct!.resp as string) || "";
    this.dataTracker.targetAnswer = targetAnswer;
    let isCorrect = (targetAnswer === selectedResponse);
    // TODO: check data tracker update here to make sure response/response2 is set
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, selectedResponse);

    this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    this.interventionTaskService.playSoundEffect(isCorrect);
    let getPoints = this.trialTimerBar.getPoints();
    let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex,
        responseTime, 0, getPoints, this.dataTracker, bool? 0: 1); // Mapping binary options to integer response postition
    this.taskTotalPoints += responseObject.points;
    if (isCorrect) this.numberOfCorrectTrials++;

    this.trialList.push(responseObject);

    this.taskService.answerTrial(isCorrect) ;
    if (this.trialIndex + 1 < this.trials.length) {
      this.trialIndex += 1;

      this.reusableTimer = window.setTimeout(() => {
        this.disableNextButton = false;
        this.hideNextButton = false;
        this.readyToHighlight = true;
        this.changeDetector.markForCheck() ;
        if (this.unmaskedTask){
          this.nextTrial();
        } else {
          // Enable video replay button while waiting for student to start the next trial
          this.disableAVButtons = false;
          this.createInterval();
        }
      }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));  // Wait a bit for trial counter animation delay
    } else {
      this.saveTaskData();
    }
  }

  saveTaskData() {
    // Just needs a little time to finish trial counter animation
    this.reusableTimer = window.setTimeout(() => {
      // Mark task as completed
      this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
          this.numberOfTrials, this.numberOfCorrectTrials).pipe(
          mergeMap(() => {
            let params = this.interventionTaskService.getTaskDataParams();
            return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished);
          }
        )).subscribe({
        next: () => {
          this.hideNextButton = true;
          this.saveDataDialog.hideSaveDataDialog();
          this.completeTask();
        },
        error: () => {
          this.saveDataDialog.showSaveDataDialog();
          this.logSaveTaskSentryError('verify-the-blank-intervention') ;
        }
      });

    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  displayTrial() {
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();
    if (this.trialMasked) {
      this.reusableTimer = window.setTimeout(() => {
        this.targetWord = this.masker;
        this.wordSeen = true;
        this.changeDetector.markForCheck() ;
        this.playWordAudio();
      }, this.task.maskerTime);
      if (this.wordSeen === false) {
        this.targetWord = <string>this.trials[this.trialIndex].display.tile;
        this.changeDetector.markForCheck() ;
      }
      this.startTime = this.timerService.startTimer();
    }
    else {
      this.playWordAudio();
      this.targetWord = <string>this.trials[this.trialIndex].display.tile;
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer();
    }
  }

  displayFocuser() {
    this.reusableTimer = window.setTimeout(() => {
      this.displayTrial();
    }, this.task.focuserTime || 750);

    this.displayTimer = window.setTimeout(() => {
      if (this.focuserDisplayed == false) {
        this.targetWord = this.focuser;
      }
      this.focuserDisplayed = true;
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  // Run this function after the masked instructional audio plays
  audioCompleteFunc() {
    // Put this in a 0 second timeoout so the view checks the scope variables again and enables buttons as needed
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = true;
      this.disableAVButtons = true;
      this.disableNextButton = false;
      this.changeDetector.markForCheck() ;
      if (this.unmaskedTask){
        this.nextTrial();
      } else {
        // Enable video replay button while waiting for student to start the next trial
        this.disableAVButtons = false;
        this.createInterval();
      }
      window.setTimeout(() => {
        this.readyToHighlight = true;
        this.changeDetector.markForCheck() ;
      }, 2000);
    }, 0);
  }

  // Function for displaying trial
  nextTrial() {
    this.hideNextButton = true;
    this.disableNextButton = true;
    this.changeDetector.markForCheck() ;
    if (this.trialIndex < this.trials.length) {
      this.focuserDisplayed = false;
      this.wordSeen = false;
      this.disableResponseButtons = true;
      this.changeDetector.markForCheck() ;
      this.displayFocuser();
    }
    this.stopInterval();
  }
}
