export class TargetSyllable {
  public syllableNum: number ;

  constructor(
    public index: number,
    public syllable: string,
    public audio: string,
    public underline: boolean
  )
  {
    this.syllableNum = index + 1 ;
  }
}

export class ResponseOption {
  constructor(
    public syllables: number,
    public isCorrect: boolean,
    public highlight: boolean
  ) {}
}