import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import { StudentDataService } from 'src/app/core/services/student-data.service';

// Point Total Constants
const ENABLED_MAX_POINTS: number = 250;
const ENABLED_MIN_POINTS: number = 100;
const DISABLED_PRE_POST_MAX_POINTS: number = 500;
const DISABLED_UNIT_MAX_POINTS: number = 100;
const SECOND_TRY_MAX: number = 50;
const INCORRECT: number = 0;

@Component({
  selector: 'trial-timer-bar',
  templateUrl: './trial-timer-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialTimerBarComponent implements OnInit, OnDestroy {

  @ViewChild('timerBox')
  timerBox!: ElementRef;
  @ViewChild('timerBar')
  timerBar!: ElementRef;

  // Variables
  currentPointTotal: number = ENABLED_MAX_POINTS;
  darkContainerColor: string = '';
  taskBarColor: string = '';
  highlight: boolean = false;
  hideTimer: boolean = true;
  floatPointsUp: boolean = false;

  // Local variables
  numberOfResponses: number = 0;
  timeoutId: number | null = null;
  intervalId: number | null = null;
  taskType: string = '';
  filledWidth: number = 0;

  constructor(
    private studentDataService: StudentDataService,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.darkContainerColor = this.studentDataService.getTaskDarkContainerColor();
    // Get student's current task
    this.taskType = this.studentDataService.getSelectedTask().taskType;
    this.taskBarColor = this.studentDataService.getTaskBarColor();
    this.filledWidth = 83;
  }

  ngOnDestroy(): void {
    // Clean up
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  }

  /**
    * Start the Trial Timer Bar
    * Params: taskSpeed - The amount of time it takes the timer bar to run out (in ms)
    *         initialDelay - The amount of time to wait before starting the timer bar (in ms)
    */
  startTrialTimer(taskSpeed: number, initialDelay: number) {
    let iterations = ENABLED_MAX_POINTS - ENABLED_MIN_POINTS;
    let intervalTime = Math.ceil(taskSpeed / iterations);

    // The current width of the filled green timer bar (in %)
    let timerBoxWidth = 0;
    let shrinkPercentage = 0;

    if ((this.timerBar != null) && (this.timerBox != null)) {
      // Calculate the width of the center trial timer box as a percentage of the timer bar width.
      // This will be used to ensure the timer runs out when the filled green bar shrinks to the same size as the timer box
      timerBoxWidth = this.timerBox.nativeElement.offsetWidth / this.timerBar.nativeElement.offsetWidth * 100;

      // Calculate the width percentage change in the timer bar for each iteration
      shrinkPercentage = (this.filledWidth - timerBoxWidth) / iterations;
    }

    // Animate the timer bar
    let count = 0;
    this.timeoutId = window.setTimeout(() => {
      this.zone.run(() => {
        this.intervalId = window.setInterval(() => {
          count++;
          if (count >= iterations || this.currentPointTotal === ENABLED_MIN_POINTS) {
            // Once the set number of iterations has been reached, set the timer bar to the exact width of the timer box
            // This prevents extra iterations due to rounding errors caused by subtracting floating point decimals in javascript
            this.filledWidth = 0;
            this.currentPointTotal = ENABLED_MIN_POINTS;
            this.changeDetector.markForCheck() ;
  
            if (this.intervalId) {
              window.clearInterval(this.intervalId);
            }
          }
          else {
            this.currentPointTotal--;
            this.filledWidth = this.filledWidth - shrinkPercentage;
            this.changeDetector.markForCheck() ;
          }
        }, intervalTime);
      });
    }, initialDelay);
  }

  /**
    * Resets the Trial Timer Bar to it's default state of max points and full width
    */
  resetTrialTimer() {
    this.currentPointTotal = ENABLED_MAX_POINTS;
    this.filledWidth = 83;
    this.highlight = false;
    this.floatPointsUp = false;
    this.numberOfResponses = 0;
    this.changeDetector.markForCheck() ;
  }

  showTimerBar() {
    this.hideTimer = false;
    this.changeDetector.markForCheck() ;
  }

  hideTimerBar() {
    this.hideTimer = true;
    this.changeDetector.markForCheck() ;
  }

  /**
    * Sends the trial response to the timer bar to evaluate what the current point total for the trial should be
    * Returns 'true' if running the points animation, otherwise returns 'false'
    */
  sendResponseToTimerBar(isCorrect: boolean): boolean {
    this.stopTrialTimer();
    this.numberOfResponses++;
    let inInterventionUnit = this.studentDataService.isInterventionUnit();

    // Determine the points earned based on the student's scoring system
    if (this.hideTimer && inInterventionUnit && this.taskType !== "SPELLING") {
      //Timer bar disabled and student is in an intervention unit:
      // DISABLED_UNIT_MAX_POINTS, SECOND_TRY_MAX, or INCORRECT
      if (isCorrect && this.numberOfResponses === 1) {
        this.currentPointTotal = DISABLED_UNIT_MAX_POINTS;
      }
      else if (isCorrect && this.numberOfResponses > 1) {
        this.currentPointTotal = SECOND_TRY_MAX;
      }
      else {
        this.currentPointTotal = INCORRECT;
      }
    }
    else if (this.taskType == "SPELLING"){
      //Timer bar disabled and student is in an intervention unit:
      // DISABLED_UNIT_MAX_POINTS, SECOND_TRY_MAX, or INCORRECT
      if (isCorrect && this.numberOfResponses === 1) {
        this.currentPointTotal = ENABLED_MAX_POINTS;
      }
      else if (isCorrect && this.numberOfResponses > 1) {
        this.currentPointTotal = ENABLED_MIN_POINTS;
      }
      else {
        this.currentPointTotal = INCORRECT;
      }
    }
    else if (this.hideTimer && !inInterventionUnit) {
      // Timer bar disabled and student is in pre/post test:
      // DISABLED_PRE_POST_MAX_POINTS or INCORRECT
      this.currentPointTotal = (isCorrect) ? DISABLED_PRE_POST_MAX_POINTS : INCORRECT;
    }
    else {
      // Timer bar enabled:
      // $scope.currentPointTotal kept by the running timer bar, SECOND_TRY_MAX, or INCORRECT
      if (isCorrect) {
        this.animatePoints();
        return true;
      }
      else if (!isCorrect && this.numberOfResponses === 1) {
        this.currentPointTotal = SECOND_TRY_MAX;
      }
      else {
        this.currentPointTotal = INCORRECT;
      }
    }
    this.changeDetector.markForCheck() ;

    return false;
  }

  /**
    * Returns the current point value from the timer bar
    */
  getPoints() {
    return this.currentPointTotal;
  }

    /**
     * Stops the currently running Trial Timer Bar
     */
  stopTrialTimer() {
    // Cancel both the interval and timeout so the trial timer bar can be stopped both
    // while it is still in it's initial delay phase or running
    if (this.intervalId) window.clearInterval(this.intervalId);
    if (this.timeoutId) window.clearTimeout(this.timeoutId);
  }

    /**
     * Runs the border blinking animation around the center box and the floating points animation
     */
  animatePoints() {
    this.highlight = true;
    this.floatPointsUp = true;
    this.changeDetector.markForCheck() ;
  }
}
