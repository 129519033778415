import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';

@Component({
  selector: 'try-again-dialog',
  templateUrl: './try-again-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TryAgainDialogComponent implements OnInit {
  @Input()
  passage: any = undefined ;
  ngshowTryAgainDialog: boolean = false;
  dialogButtonsDisabled: boolean = false;
  taskBarColor: string = '';
  needsFocus: boolean = false;

  constructor(
    private interventionTaskService: InterventionTaskService,
    private audioPlayerService: AudioPlayerService,
    private studentDataService: StudentDataService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.taskBarColor = this.interventionTaskService.taskBarColor();
    this.ngshowTryAgainDialog = false;
  }


  showTryAgainDialog(needsFocus: boolean) {
    this.needsFocus = needsFocus;
    this.dialogButtonsDisabled = false;
    this.ngshowTryAgainDialog = true;
    this.audioPlayerService.play('Audio/Help/help_tryagaindialog.mp3').subscribe();
    this.changeDetector.markForCheck();
  }

  hideTryAgainDialog() {
    this.ngshowTryAgainDialog = false;
  }

  tryAgainBtnDialogClick() {
    this.dialogButtonsDisabled = true;
    this.tryTaskAgain()
  }

  tryLaterBtnDialogClick() {
    this.dialogButtonsDisabled = true;
    this.returnToPreviousScreen();
  }

  tryTaskAgain() {
    this.ngshowTryAgainDialog = false;
    let url = this.router.url;
    this.studentDataService.setSelectedTask(this.interventionTaskService.getFirstWordListTask());
    // NOTE: router won't update anything if navigating to current url,
    //   one way around this is to navigate away from the route, and then back to the current url.
    let stateParams: any = {
      wordListAttempt: 1,
      attempt: 2,
      needsFocus: this.needsFocus
    } ;

    // If we have a passage, we need to pass it to our reload
    if (this.passage)
    {
      stateParams.passage = this.passage;
    }

    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl(url, { state: stateParams });
    })
  }

  returnToPreviousScreen() {
    if (this.interventionTaskService.getParentTaskId()) {
      // Return to the subtask select
      this.router.navigateByUrl('/subtaskSelect', {
        state: {
          mainTaskId: this.interventionTaskService.getParentTaskId(),
        }
      });
    }
    else {
      let alreadyCompleted = this.interventionTaskService.getAlreadyCompleted();
      this.router.navigateByUrl('/taskSelect', {
        state: {
          canGoToTaskSelect: true,
          alreadyCompleted: alreadyCompleted,
          needsFocus: this.needsFocus
        }
      });
    }
  }
}
