import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { catchError, switchMap } from 'rxjs/operators';
import { StudentData } from '../core/models/student-data.model';
import { CurriculumService } from '../core/services/curriculum.service';
import { StudentDataService } from '../core/services/student-data.service';
import { ThemeParseService } from '../core/services/theme-parse.service';
import {
  DemoCurriculumRequest,
  SubscriptionTypes,
  PlacementOption,
  SubscriptionOption,
  CurriculumOption,
  WordlistOption
} from './demo.model';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: [ './demo.component.css' ],
})
export class DemoComponent implements OnInit {
  showLoading: boolean = false;
  disableButtons: boolean = false;
  systemDemoDisabled: boolean = true;
  diagnosticDemoDisabled: boolean = true;
  subscription: number = 0;
  curriculum: string = '';
  wordlist: number = 0;
  placement?: PlacementOption;
  errorMessage?: string;
  username: string = '';
  curriculumOptions: CurriculumOption[] = [];
  placementArr: PlacementOption[] = [];
  subscriptionOptions: SubscriptionOption[] = [];
  wordListOptions: WordlistOption[] = [];
  systemSubscriptionKey: number = -1;
  diagnosticSubscriptionKey: number = -1;

  private iScreenerCurriculumOptions: CurriculumOption[] = [];
  private diagnosticProductCurriculumOptions: CurriculumOption[] = [];
  private fullProductCurriculumOptions: CurriculumOption[] = [];

  constructor(
    private router: Router,
    private curriculumService: CurriculumService,
    private studentDataService: StudentDataService,
    private themeParseService: ThemeParseService,
  ) { }

  ngOnInit(): void {
    this.username = this.studentDataService.getUsername() || '';

    // Don't include the version number - back end will automatically append the latest version to the curriculum name
    this.iScreenerCurriculumOptions.push({key: 'ElementaryScreener', value: SubscriptionTypes.FreeProduct + ' Elementary'});
    this.iScreenerCurriculumOptions.push({key: 'SecondaryScreener', value: SubscriptionTypes.FreeProduct + ' Secondary'});

    this.diagnosticProductCurriculumOptions.push({key: 'ElementaryForm1', value: SubscriptionTypes.DiagnosticProduct + ' Elementary'});
    this.diagnosticProductCurriculumOptions.push({key: 'SecondaryForm1', value: SubscriptionTypes.DiagnosticProduct + ' Secondary'});

    forkJoin({
      curriculum: this.studentDataService.getDemoCurriculumOptions().pipe(catchError(error => of(error))),
      subscription: this.studentDataService.getDemoSubscriptionOptions().pipe(catchError(error => of(error))),
      wordList: this.studentDataService.getDemoWordListOptions().pipe(catchError(error => of(error))),
      placement: this.studentDataService.getDemoPlacementOptions().pipe(catchError(error => of(error))),
    }).subscribe(options => {
      // Set up our options lists
      // Placement options
      this.placementArr = options.placement;
      this.placement = this.placementArr.find(placement => placement.unitNumber === 1);

      // Curriculum options
      this.fullProductCurriculumOptions = options.curriculum;

      // Subscription options
      if (options.subscription != null)
      {
        this.subscriptionOptions = options.subscription;
        this.systemSubscriptionKey = this.subscriptionOptions[2].key;
        this.diagnosticSubscriptionKey = this.subscriptionOptions[1].key;
        // OPTIMIZE: The logic for this works okay now, but would get more complicated
        // if more condtions/curricula are added, might be useful to come up with better
        // way to determine demo access
        if (this.studentDataService.isDemoUserDiagnosticProductSubscription())
        {
          // User is diagnostic user, can demo diagnostics and screeners
          this.subscription = this.subscriptionOptions[1].key;
          this.systemDemoDisabled = true;
          this.diagnosticDemoDisabled = false;
        }
        else if (this.studentDataService.isDemoUserSystemProductSubscription())
        {
          // User is system user, can demo any curriculum
          this.subscription = this.subscriptionOptions[2].key;
          this.systemDemoDisabled = false;
          this.diagnosticDemoDisabled = false;
        }
        else
        {
          // User is screener-only user, can only demo the screener
          this.subscription = this.subscriptionOptions[0].key;
          this.systemDemoDisabled = true;
          this.diagnosticDemoDisabled = true;
        }
      }

      // WordList options
      this.wordListOptions = options.wordList;

      this.updateCurriculumOptions();
    });
  }

  private goToStartScreen() {
    // Load the curriculum
    let startScreenSubscription = this.curriculumService.loadCurriculum().pipe(
      switchMap(() => this.themeParseService.loadThemeProperties()),
    ).subscribe({
      next: () => {
        startScreenSubscription.unsubscribe();
        if (this.studentDataService.isFullProductSubscription())
        {
          this.router.navigateByUrl('/world');
        }
        else
        {
          this.router.navigateByUrl('/levelSelect', { state: { previous: this.router.url } });
        }
      },
      error: (err: any) => {
        startScreenSubscription.unsubscribe();
        this.handleError(err);
      }
    });
  }

  // Start button clicked handler.
  submit() {
    // Send these values to the backend to get the correct task list and rest of student data for a teacher.
    let data: DemoCurriculumRequest = {
      curriculum: this.curriculum,
      subscriptionType: this.subscription,
    };

    this.showLoading = true;
    this.disableButtons = true;

    if (this.subscription === 2)
    {
      data.wordListType =this.wordlist;
      if(this.placement?.unitNumber) {
        data.unitNumber = this.placement?.unitNumber;
      }
      data.objectiveNumber = this.placement?.objectiveNumber;
      data.type = this.placement?.type;
    }

    this.studentDataService.getDemoStudentData(data).subscribe({
      next: (response: StudentData) => {
        this.curriculumService.clearCurriculum();
        this.studentDataService.setStudentData(response);

        // Update our task counts to simulate locking a task at 4 attempts
        this.studentDataService.initInterventionTaskCompletionCounts(response) ;

        this.goToStartScreen();
      },
      error: (err: any) => {
        console.error('Error getting demo student data: ', err);
        this.handleError(err);
      }
    });
  }

  private handleError(err: any) {
    this.showLoading = false ;
    this.disableButtons = false ;
    this.errorMessage = "The application is temporarily unavailable. Please wait a few moments and try again.";

    Sentry.captureException(err, {
      tags: {
        section: 'demo',
      }
    }) ;
  }

  updateCurriculumOptions() {
    if (this.subscription === this.subscriptionOptions[0].key)
    {
      this.curriculumOptions = this.iScreenerCurriculumOptions;
    }
    else if (this.subscription == this.subscriptionOptions[1].key)
    {
      this.curriculumOptions = this.diagnosticProductCurriculumOptions;
    }
    else
    {
      this.curriculumOptions = this.fullProductCurriculumOptions;
    }

    this.curriculum = this.curriculumOptions[this.curriculumOptions.length-1].key;
  }

  onSubscriptionChange(event: Event) {
    let target = event.target as HTMLInputElement;
    this.subscription = parseInt(target.value);

    this.updateCurriculumOptions();
  }
}
