import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './guards/module-import-guard';
import { PleaseWaitDialogComponent } from './components/please-wait-dialog/please-wait-dialog.component';
import { AssetLoadingDialogComponent } from './components/asset-loading-dialog/asset-loading-dialog.component';

@NgModule({
  declarations: [
    PleaseWaitDialogComponent,
    AssetLoadingDialogComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PleaseWaitDialogComponent,
    AssetLoadingDialogComponent,
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
