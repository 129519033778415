import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {
  private singleAudio: HTMLAudioElement | null = null;

  constructor() { }

  play = (audioFile: string): Observable<MediaError> => {
    let audioElement = new Audio('/assets/' + audioFile);
    let audioObservable = new Observable<MediaError>((observer) => {
      this.stopAll();
      let completeHandler = () => {
        observer.next();
        observer.complete();
      }
      let errorHandler = () => {
        observer.error(audioElement.error);
      }

      audioElement.addEventListener('ended', completeHandler);
      audioElement.addEventListener('error', errorHandler);

      audioElement.play().catch((error) => {
        observer.error(error);
      });
      this.singleAudio = audioElement;

      return {
        unsubscribe() {
          audioElement.removeEventListener('ended', completeHandler);
          audioElement.removeEventListener('error', errorHandler);
        }
      }
    });

    return audioObservable;
  }

  stopAll = () => {
    if ((this.singleAudio) && !this.singleAudio.paused) {
      this.singleAudio.pause();
      this.singleAudio.currentTime = 0;
      this.singleAudio = null;
    }
  }
 }
