import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AssetPreloaderService } from '../../core/services/asset-preloader.service';
import { CurriculumService } from '../../core/services/curriculum.service';
import { StudentDataService } from '../../core/services/student-data.service';
import { ThemeParseService } from '../../core/services/theme-parse.service';
import { LoginService } from '../../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class LevelSelectResolver  {
  constructor(
    private assetPreloaderService: AssetPreloaderService,
    private curriculumService: CurriculumService,
    private loginService: LoginService,
    private themeService: ThemeParseService,
    private studentDataService: StudentDataService,
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let nextAssessmentComplete = false;
    let allTasksWereJustCompleted = this.studentDataService.areAllTasksComplete();
    let isDemoUser = this.studentDataService.isDemoUser();

    let data = {
      nextAssessmentComplete,
      allTasksWereJustCompleted,
    };

    if (allTasksWereJustCompleted && isDemoUser)
    {
      // Not easy to preload demo user next data so we just return.
      return of(data);
    }
    else
    {
      let obs$: Observable<any> = of({});
      if (allTasksWereJustCompleted)
      {
        obs$ = this.updateStudentData();
      }

      //
      // TODO: I think this can all be cleaned up a little bit.
      //  More of these tasks can probably also be run in parallel.
      //
      // Resolve request steps:
      //  1. If needed fetch student data, update curriculum, and update the theme properties
      //  2. Load the images
      //  3. Return the resolve data containing flags for nextAssessmentComplete and allTasksWereJustCompleted.
      return obs$.pipe(
        switchMap(() => this.loadImages()),
        // Return the level select resolver data with the potentially updated nextAssessmentComplete value.
        switchMap(() => of({
            ...data,
            nextAssessmentComplete: this.studentDataService.getStudentData()?.studentAssessment?.isComplete || false,
          })
        ),
      );
    }
  }

  private updateStudentData(): Observable<any> {
    return this.studentDataService.fetchStudentData().pipe(
      catchError((error) => {
        // Log the error and logout if there is an error fetching student data
        console.log('Error fetching the student data. Logging out...');
        this.loginService.studentLogout().pipe(take(1)).subscribe();
        throw(error);
      }),
      switchMap(studentData => {
        // Needs to clear the curriculum,
        // otherwise it uses the stored version
        this.curriculumService.clearCurriculum();
        return this.curriculumService.loadCurriculum();
      }),
      switchMap(() => this.themeService.loadThemeProperties()),
    );
  }

  private loadImages(): Observable<any> {
    let imageList: string[] = [];
    let imageA = this.studentDataService.getLevelSelectBackgroundA();
    let imageB = this.studentDataService.getLevelSelectBackgroundB();

    imageList.push(imageA);
    if (imageB)
    {
      imageList.push(imageB);
    }

    return this.assetPreloaderService.preloadImages(imageList);
  }
}
