export class KeyboardLetters {
  public text: string ;
  public keyCode: string ;
  public highlight: boolean ;
  public disabled: boolean ;
  public index: number ;

  constructor(text: string, keyCode: string, highlight: boolean, disabled: boolean, index: number) {
    this.text = text ;
    this.keyCode = keyCode ;
    this.highlight = highlight ;
    this.disabled = disabled ;
    this.index = index ;
  }
}

export class KeyboardSpecial{
  public text: string ;
  public keyCode: string ;
  public highlight: boolean ;
  public disabled: boolean ;

  constructor(text: string, keyCode: string, highlight: boolean, disabled: boolean) {
    this.text = text ;
    this.keyCode = keyCode ;
    this.highlight = highlight ;
    this.disabled = disabled ;
  }
}

export class TargetLetters{
  public blank: string ;
  public text: string ;
  public cvc: string ;
  
  constructor(blank: string, text: string, cvc: string) {
    this.blank = blank ;
    this.text = text ;
    this.cvc = cvc ;
  }
}
