import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const PING_DELAY_MILLIS = 2000;
const PING_MAX_ATTEMPTS = 15;

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  private pingBackend(): Observable<any> {
    let pingUrl = environment.EC2URL + 'ping';
    let reqOptions = {
      headers: {
          'Access-Control-Allow-Origin' : "*"
      }
    }

    return this.httpClient.get(pingUrl, reqOptions);
  };

  checkServerConnectivity(): Observable<any> {
    return this.pingBackend().pipe(
      retryWhen(errors => errors.pipe(
        concatMap((error, i) => 
          // Executes a conditional Observable depending on the result
          // of the first argument
          iif(
            () => i > PING_MAX_ATTEMPTS,
            // If the condition is true we throw the error (the last error)
            throwError({message: 'Could not connect to server', error}),
            // Otherwise we pipe this back into our stream and delay the retry
            of(error).pipe(delay(PING_DELAY_MILLIS))
          )
        )
      )),
    )
  }
}
