export class WordSpaces {
  public text: string;
  public disabled: boolean;
  public targetIndex: number | null;
  public audio: string;

  constructor(text: string, disabled: boolean, targetIndex: number | null, audio: string) {
    this.text = text;
    this.disabled = disabled;
    this.targetIndex = targetIndex;
    this.audio = audio;
  }
}

export class ResponseTile{
  public index: number;
  public text: string;
  public audio: string  

  constructor(index: number, text: string, audio: string) {
    this.index = index;
    this.text = text;
    this.audio = audio;
  }
}
