import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { DiagnosticTrial } from 'src/app/core/models/task.model';
import { AssetPreloaderService } from 'src/app/core/services/asset-preloader.service';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { DiagnosticTaskComponent } from '../diagnostic-task.component';
import { PictureWithText } from './find-the-picture.model'
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'app-find-the-picture',
  templateUrl: './find-the-picture.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindThePictureComponent extends DiagnosticTaskComponent implements OnInit, AfterViewInit {
  trials: DiagnosticTrial[] = this.task.trial;

  // Task specific variables
  targetWord: string = '';
  toggleNextButton: boolean = false;
  hideResponses: boolean = true;
  unmaskedTask: boolean;
  responseOptions: PictureWithText[] = [];
  maskerTime: number = 0;

  constructor(
    public router: Router,
    public applicationStateService: ApplicationStateService,
    public studentDataService: StudentDataService,
    public audioPlayerService: AudioPlayerService,
    public timerService: TimerService,
    public shuffleService: ShuffleService,
    public assetPreloaderService: AssetPreloaderService,
    public changeDetector: ChangeDetectorRef,
    private taskService: TaskService,
  ) {
    super(router, applicationStateService, studentDataService, audioPlayerService, timerService, shuffleService, assetPreloaderService, changeDetector);

    this.unmaskedTask = this.task.trial[0].masked === 'U';
  }

  ngOnInit(): void {
    // If random trials is set to true on the task object, shuffle the trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe((complete) => {
      if (complete) {
        // set this to tell the trial-counter that animation is complete
        this.animationComplete = true;
        this.displayTaskContainerElements()
        .pipe(first(),
          concatMap(() => {
            if (!this.studentDataService.isDemoUser() && !this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id)) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.instructionalAudioFile);
            } else {
              return of({});
            }
          }),
        )
        .subscribe({
          complete: () => this.maskedAudioCompleteFunc(),
          error: () => this.maskedAudioCompleteFunc(),
        });
      }
    });
  }

  createPictureWithText(imageUrl: string, text: string): PictureWithText {
    let pictureWithText: PictureWithText = {
      imageUrl: imageUrl,
      text: text
    }
    return pictureWithText;
  }

  // Run this function after the masked instructional audio plays
  maskedAudioCompleteFunc() {
    // Put this in a 0 second timeoout so the view checks the scope variables again and enables buttons as needed
    this.reusableTimer = window.setTimeout(() => {
      this.disableNextButton = false;
      this.readyToHighlight = true;
      this.hideResponses = true;
      this.buildResponseLists(this.trialIndex);
      if (this.unmaskedTask){
        this.displayTrial();
      } else {
        this.createInterval();
      }
    }, 0);
  }

  saveStudentData() {
    let currentDestination = this.studentDataService.getCurrentDestination();

    // Keep track of total points locally
    this.studentDataService.setTotalPoints(currentDestination, this.totalTaskPoints, this.trials.length, this.numberOfCorrectTrials);
    this.saveTrialData();
  }

  buildResponseLists(index: number) {
    // Build the response option lists from the curriculum

    this.responseOptions = [];
    for (let response in this.trials[this.trialIndex]['resp-list'].resp) {
      let image = this.trials[this.trialIndex]['resp-list'].resp[response]['@image'] ?? "";
      let text = this.trials[this.trialIndex]['resp-list'].resp[response]['#text'];
      let newResponse = this.createPictureWithText(image, text);
      this.responseOptions.push(newResponse);
    }

    if (this.trials[index]['resp-list']['@randomResponses']) {
      this.shuffleService.shuffleArray(this.responseOptions);
    }
  }

  displayTrial() {
    this.stopInterval();
    this.readyToHighlight = false;
    this.disableNextButton = true;
    this.hideResponses = false;
    this.hideNextButton = true;

    this.maskerTime = parseInt(this.trials[this.trialIndex].maskerTime);
    this.targetWord = "______";
    this.changeDetector.markForCheck() ;

    this.reusableTimer = window.setTimeout(() => {
      this.targetWord = this.trials[this.trialIndex].correct.resp;
      this.changeDetector.markForCheck() ;
      this.displayMasker();
    }, this.task.focuserTime || 750);
  }

  displayMasker() {
    if (!this.unmaskedTask) {
      this.reusableTimer = window.setTimeout(() => {
        this.targetWord = "########";
        this.disableResponseButtons = false;
        this.changeDetector.markForCheck() ;
        this.startTime = this.timerService.startTimer();
      }, this.maskerTime);
    }
    else {
      this.disableResponseButtons = false;
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer();
    }
  }

  submitAnswer(index: number) {
    this.endTime = this.timerService.stopTimer();
    this.audioPlayerService.play('Audio/Help/SNDneutral.mp3').subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });
    this.disableNextButton = true;
    this.disableResponseButtons = true;
    this.changeDetector.markForCheck() ;

    let selectedResponseText = this.responseOptions[index].text;
    let foil = null;
    let responsePosition = index + 1 || 0;
    let responseType = '';
    let word = this.trials[this.trialIndex].word || "";
    // NOTE: word can be an object with #text and @audio properties in other tasks,
    //  using toString here to ensure target is just a string
    let target: string = word.toString();
    let response = this.submitResponse(selectedResponseText, foil, target, responsePosition, responseType);

    //this.trialCounter.updateTrialCounter(response.correct)
    this.taskService.answerTrial(response.correct) ;
    if (this.trialIndex + 1 < this.trials.length) {
      this.trialIndex += 1;

      this.reusableTimer = window.setTimeout(() => {
        this.disableNextButton = false;
        this.hideNextButton = false;
        this.targetWord = '';
        this.hideResponses = true;
        this.buildResponseLists(this.trialIndex);
        this.readyToHighlight = true;
        if (this.unmaskedTask){
          this.displayTrial();
        } else {
          this.createInterval();
        }
      }, 100);  // Wait a bit for trial counter animation delay
    } else {
      this.reusableTimer = window.setTimeout(() => {
        this.saveStudentData();
      }, 750); // Just needs a little time to finish trial counter animation
    }
  }
}
