import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VgCoreModule } from '@videogular/ngx-videogular/core';

import { InstructionsComponent } from './components/instructions/instructions.component';
import { StudentHeaderComponent } from './components/student-header/student-header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { StudentFooterComponent } from './components/student-footer/student-footer.component';
import { CongratulationsComponent } from './components/congratulations/congratulations.component';
import { BackgroundComponent } from './components/background/background.component';
import { VersionFooterComponent } from './components/version-footer/version-footer.component';
import { FocusDialog } from './components/focus-dialog/focus-dialog.component';



@NgModule({
  declarations: [
    InstructionsComponent,
    StudentHeaderComponent,
    SideMenuComponent,
    StudentFooterComponent,
    CongratulationsComponent,
    BackgroundComponent,
    VersionFooterComponent,
    FocusDialog,
  ],
  imports: [
    VgCoreModule,
    CommonModule
  ],
  exports: [
    InstructionsComponent,
    StudentHeaderComponent,
    SideMenuComponent,
    CongratulationsComponent,
    BackgroundComponent,
    StudentFooterComponent,
    VersionFooterComponent,
    FocusDialog,
  ]
})
export class SharedModule { }
