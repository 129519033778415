<!-- Template for header directive -->

<div class="header-div" [ngClass]="{'header-div-no-points': hidePoints}">
    <div class="header-left-col" style="z-index: 1;">
        <div class="header-avatar-student-container" *ngIf="!hidePoints">
            <object id="userInfoObject" data="../assets/images/headerFooter/userInfo_background.svg"
              type="image/svg+xml">
            </object>
            <div class="header-avatar" [ngStyle]="{'background-image':'url(' + avatarIcon + ')'}"
              (dblclick)="moveBackOneLevel($event)" on-double-tap="moveBackOneLevel($event)"></div>
            <div *ngIf="studentID" [ngClass]="{'font-18': (studentID.length || 0) >= 16, 'font-24': (studentID.length || 0) < 16}" class="header-username">{{studentID}}</div>
            <div class="header-points font-24">{{studentDailyPoints | number}}</div>
        </div>

        <div class="header-student-container" *ngIf="hidePoints">
          <object id="usernameObject" data="../assets/images/headerFooter/username_background.svg"
            type="image/svg+xml"></object>
          <div *ngIf="studentID" [ngClass]="{'font-18': (studentID.length || 0) >= 16, 'font-24': (studentID.length || 0) < 16}" class="header-name-hide-points">{{studentID}}</div>
        </div>
    </div>

    <div class="new-team-dialog-container" *ngIf="showNewTeam"></div>
    <div class="header-center-col" [hidden]="hideTeam">
        <div class="header-team-name" [ngClass]="{'header-team-no-points': hidePoints}">
            <div [hidden]="hideTeamName" class="font-30"
              [ngClass]="{'animate-header-team-name': animateTeamName}">{{teamName}}
            </div>
        </div>
        <div class="header-your-team font-14">Your Team</div>
    </div>
    <div class="header-right-col">
        <button class='header-logout-button' (click)="headerGoBack()" *ngIf="headerBackButtonVisible"
          [ngClass]="{'back_button_animate': pulseHeaderGoBackButton && !disableGoBackButton, 'disabled': disableGoBackButton}" [disabled]=disableGoBackButton></button>
    </div>
    <instructions [videoSource]="videoSource" #instructions></instructions>
</div>
