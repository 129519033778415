import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DiagnosticTaskComponent } from '../diagnostic-task.component';
import { DiagnosticTrial } from 'src/app/core/models/task.model';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { concatMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { AssetPreloaderService } from 'src/app/core/services/asset-preloader.service';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'app-verify-the-blank',
  templateUrl: './verify-the-blank.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyTheBlankComponent extends DiagnosticTaskComponent implements OnInit, AfterViewInit {

  targetWord: string = '';
  trials: DiagnosticTrial[] = this.task.trial;
  numberOfTrials: number = this.trials.length;

  // Let me know if the task is masked or not
  unmaskedTask: boolean;

  // Define the masker and focuser variables that will be shown during the trials
  focuser: string = '______';
  masker: string = '########';

  // Flag to tell me if the focuser was already displayed for the trial. Will need to get reset to false on each trial
  focuserDisplayed: boolean = false;

  // Flag to know if the word has already been shown to the user once, so they can't make it display more than once
  wordSeen: boolean = false;

  constructor(
    public router: Router,
    public applicationStateService: ApplicationStateService,
    public studentDataService: StudentDataService,
    public audioPlayerService: AudioPlayerService,
    public timerService: TimerService,
    public shuffleService: ShuffleService,
    public assetPreloaderService: AssetPreloaderService,
    public taskService: TaskService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(router, applicationStateService, studentDataService, audioPlayerService, timerService, shuffleService, assetPreloaderService);
    this.totalTaskPoints = 0;
    this.unmaskedTask = this.task.trial[0].masked === 'U';
  }

  ngOnInit(): void {
    // If random trials is set to true on the task object, shuffle the trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
  }

  playTrialWordAudio(): void {
    this.disableNextButton = true;
    this.disableAVButtons = false;
    this.changeDetector.markForCheck() ;
    this.playWordAudio();
    this.responseTimeout = window.setTimeout(() => {
      this.disableResponseButtons = false;
      this.changeDetector.markForCheck() ;
    }, 750);
    this.reusableTimer = window.setTimeout(() => {
      this.readyToHighlight = true;
      this.changeDetector.markForCheck() ;
    }, 2000);
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe((complete) => {
      if (complete) {
        // set this to tell the trial-counter that animation is complete
        this.animationComplete = true;
        this.displayTaskContainerElements()
        .pipe(first(),
          concatMap(() => {
            if (!this.studentDataService.isDemoUser() && !this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id)) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.instructionalAudioFile);
            } else {
              return of({});
            }
          }),
        )
        .subscribe({
          complete: () => this.maskedAudioCompleteFunc(),
          error: () => this.maskedAudioCompleteFunc(),
        });
      }
    });
  }

  nextTrial(): void {
    this.hideNextButton = true;
    this.disableNextButton = true;
    this.changeDetector.markForCheck() ;

    if (this.trialIndex < this.trials.length) {
      this.focuserDisplayed = false;
      this.wordSeen = false;
      this.disableResponseButtons = true;
      this.changeDetector.markForCheck() ;
      this.displayFocuser();
    }
    if (!this.unmaskedTask){
      this.stopInterval();
    }
  }

  submitAnswer(selectedResponse: boolean): void {
    this.endTime = this.timerService.stopTimer();
    this.audioPlayerService.play('Audio/Help/SNDneutral.mp3').subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });

    // Set the selected response to 'y' or 'n' depending on if the user selects true or false for the word and audio matching
    this.currentSelectedResponse = selectedResponse ? 'y': 'n';
    this.disableResponseButtons = true;
    this.disableAVButtons = true;
    this.targetWord = '';
    this.changeDetector.markForCheck() ;
    let foil = this.trials[this.trialIndex].display.tile.toString();
    let responsePosition = (this.currentSelectedResponse === 'y') ? 1 : 2 || "";
    let responseType = '';
    let target = this.trials[this.trialIndex].word['#text'] || "";
    let response = this.submitResponse(this.currentSelectedResponse, foil, target, responsePosition, responseType);

    this.taskService.answerTrial(response.correct) ;
    if (this.trialIndex + 1 < this.trials.length) {
      this.trialIndex += 1;

      this.reusableTimer = window.setTimeout(() => {
        this.disableNextButton = false;
        this.hideNextButton = false;
        this.changeDetector.markForCheck() ;
        if (this.unmaskedTask){
          this.nextTrial();
        } else {
          this.createInterval();
        }
      }, 750);  // Wait a bit for trial counter animation delay
    } else {
      this.reusableTimer = window.setTimeout(() => {
        this.saveStudentData();
        this.hideNextButton = true;
        this.changeDetector.markForCheck() ;
      }, 750); // Just needs a little time to finish trial counter animation
    }
  }

  displayTrial(): void {
    if (!this.unmaskedTask) {
      this.reusableTimer = window.setTimeout(() => {
        this.targetWord = this.masker;
        this.wordSeen = true;
        this.changeDetector.markForCheck() ;
        this.playTrialWordAudio();
      }, parseInt(this.trials[this.trialIndex].maskerTime));
      if (this.wordSeen === false) {
        this.targetWord = this.trials[this.trialIndex].display.tile.toString();
      }
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer();
    }
    else {
      this.playTrialWordAudio();
      this.targetWord = this.trials[this.trialIndex].display.tile.toString();
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer();
    }
  }

  displayFocuser(): void {
    this.reusableTimer = window.setTimeout(() => {
      this.displayTrial();
    }, this.task.focuserTime || 750);

    this.displayTimer = window.setTimeout(() => {
      if (this.focuserDisplayed == false) {
        this.targetWord = this.focuser;
      }
      this.focuserDisplayed = true;
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  // Run this function after the masked instructional audio plays
  maskedAudioCompleteFunc = () => {
    // Put this in a 0 second timeoout so the view checks the scope variables again and enables buttons as needed
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = true;
      this.disableAVButtons = true;
      this.disableNextButton = false;
      this.changeDetector.markForCheck() ;
      if (this.unmaskedTask){
        this.nextTrial();
      } else {
        this.createInterval();
      }
      setTimeout(() => {
        this.readyToHighlight = true;
        this.changeDetector.markForCheck() ;
      }, 2000);
    }, 0);
  }
}
