import {
  Injectable,
} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

let NO_CACHE_EXTENSIONS = [".json", ".css"];

/**
 * Intercepts HTTP requests from Angular and applies a "nocache" request parameter
 * to ensure the request will be unique
 * Reference: http://overengineer.net/preventing-angularjs-from-caching-your-templates-and-other-files
 */
@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {

  constructor() {}

  private shouldApplyNoCache(requestUrl: string) {
    return NO_CACHE_EXTENSIONS.some((extension) => requestUrl.endsWith(extension));
  }

  private getNoCacheUrl(requestUrl: string): string {
    let newRequestUrl: string = requestUrl;

    // Calculate the current minute to 4 digits
    //  ~~ to do a Math.floor
    let paramNumber = ~~(Date.now() / 60000) % 10000;

    newRequestUrl += (requestUrl.indexOf('?') !== -1) ? '&' : '?';
    newRequestUrl += "nocache=" + paramNumber;

    return newRequestUrl;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.shouldApplyNoCache(request.url))
    {
      request = request.clone({
        url: this.getNoCacheUrl(request.url),
      });
    }

    return next.handle(request);
  }
}