import { Component } from '@angular/core';

@Component({
  selector: 'background',
  templateUrl: './background.component.html',
})
export class BackgroundComponent {
  fadeOut: boolean = false;
  
  constructor() { }
  
  startFadeOut() {
    this.fadeOut = true;
  }

}
