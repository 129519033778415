<div class="viewport-1">
  <background></background>
  <img id="fil-logo" class="overlay-fil-logo" src="/assets/images/wordflight_vrt_color_pos_rgb.svg" />
  <div id="login-wait" class="please-wait-dialog" *ngIf="loadingToken">
    <img src="assets/images/dialogs/dialog-please-wait.svg"/>
  </div>
  <!-- SSO errors-->
  <div class="error-wrapper" *ngIf="!loadingToken && error">
    <h4>{{ error.message }}</h4>
    <p>{{ error.details }}</p><br>
    <div class="retry-wrapper">
      <button (click)="getUserInfo()" class="retry-login">
        <img src="/assets/images/buttons/tryLaterBttnShape.svg" class="retry-icon" />
        Retry
      </button>
    </div>
  </div>
  <!-- Non-error alerts -->
  <div class="info-wrapper" *ngIf="!loadingToken && !error && alert">
    <h4>{{ alert.message }}</h4>
    <p>{{ alert.details }}</p><br>
  </div>
  <div id="welcome" class="welcome-wrapper" [hidden]="!showWelcome">
    <h4>Welcome to WordFlight</h4>
    <p>
      Try your best and have fun!
    </p>
    <button class="btn btn-primary" (click)="acceptWelcome()">Let's Go!</button>
  </div>
  <div class="usage-element-message" [hidden]="!showUsage">
    <p>
      Hi! It looks like you've been really active with us this week! That's great, but it's also important to exercise your mind and body in
      other ways -- read a book, make some art, play games or play outside! Would you like to take a break and go do another activity?
    </p>
    <button class="btn btn-success" (click)="cancelLogin()">Sure</button>
    <button class="btn btn-secondary" (click)="continueLogin()">No Thanks</button>
  </div>
  <div class="usage-element-message" [hidden]="!showUsageLogout">
    <p>Great, we'll see you next time! Go have fun!</p>
  </div>
  <div class="usage-element-message" [hidden]="!showUsageLogin">
    <p>Enjoy your lessons today and make sure to spend some time with other activities!</p>
  </div>
  <div *ngIf="restrictLogin" class="download-element-message" style="font-weight: bold ; font-size: 2rem ;">
    <div>
      WordFlight works best on mobile devices and tablets when using the WordFlight app, get it now...
    </div>
    <div class="download-app-wrapper">
      <div class="download-app-btn">
        <a [href]="appStoreLink"><img src="/assets/images/app-icon.png" width="48" height="48"/> WordFlight</a>
      </div>
    </div>
  </div>
  <version-footer></version-footer>
</div>
