<div class="focus-dialog-container" *ngIf="ngShowFocusDialog">
    <div class="focus-dialog-top-container">
        <label class="focus-dialog-text font-42">Hey There!</label>
        <!-- The same generic dialog is fired up in two scinarios, when students take long time to finish a task and when they 
        select the same answer in all trials. 
        This message could probably be customized based on the scinario by accepting it as an argument. -->
        <label class="focus-dialog-subtext font-20"> It looks like you're getting a bit distracted. 
            Take a second and try to refocus for your next task.
        </label>
    </div>
    <div class="focus-dialog-bottom-container">
        <button class="focus-dialog-btn" (click)="focusDialogPositive()">
            <span class='focus-text font-20'>Noted</span>
        </button>
    </div>
</div>