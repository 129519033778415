<div class="viewport-1">
  <background></background>
  <img class="overlay-fil-logo" src="/assets/images/wordflight_vrt_color_pos_rgb.svg" />
  <div class="demo-select-form">
      <form name="demoSelectForm" class="form demo-form-wrapper">
          <div *ngIf="errorMessage != null" class="alert alert-danger demo-alert font-17">{{errorMessage}}</div>
          <div class="demo-header-wrapper">
            <div *ngIf="username" [ngClass]="{'font-20': (username.length || 0) >= 16, 'font-26': (username.length || 0) < 16}" class="demo-header">Welcome {{username}}</div>
          </div>
          <div id="demo-subscription-options" class="demo-radio-group font-20" style="text-align:center;">
            <label *ngFor="let option of subscriptionOptions; let i=index">
                <input
                    type="radio"
                    name="subscription"
                    (change)="onSubscriptionChange($event)"
                    [disabled]="disableButtons || (systemDemoDisabled && subscriptionOptions[i].key === systemSubscriptionKey) ||
                                (diagnosticDemoDisabled && subscriptionOptions[i].key === diagnosticSubscriptionKey)"
                    [value]="subscriptionOptions[i].key"
                    [checked]="subscription === subscriptionOptions[i].key"
                />
                {{ subscriptionOptions[i].value }}
            </label>
          </div>
          <div [ngClass]="{'demo-form-group': true}">
              <div>
                <label class="demo-field-text font-27" for="curriculum">Curriculum</label>
                <select id="curriculum" name="curriculum" class="demo-dropdown font-20" [(ngModel)]="curriculum" [disabled]="disableButtons">
                  <option *ngFor="let curriculumOption of curriculumOptions" [ngValue]="curriculumOption.key">{{curriculumOption.value}}</option>
                </select>
              </div>
              <div>
                <label class="demo-field-text font-27" for="placement">Placement</label>
                <select id="placement" name="placement" class="demo-dropdown font-20" [(ngModel)]="placement" [disabled]="disableButtons || subscription !== systemSubscriptionKey">
                  <option *ngFor="let placement of placementArr" [ngValue]="placement">{{placement.displayText}}</option>
                </select>
              </div>
              <div>
                <label class="demo-field-text font-27" for="wordlist">Initial Wordlist level</label>
                <select id="wordlist" name="wordlist" class="demo-dropdown font-20" [(ngModel)]="wordlist" [disabled]="subscription !== systemSubscriptionKey || disableButtons">
                  <option *ngFor="let wordListOption of wordListOptions" [ngValue]="wordListOption.key">{{wordListOption.value}}</option>
                </select>
              </div>
              <div class="demo-submit-btn">
                <button type="submit"(click)="submit()" [disabled]="disableButtons"></button>
              </div>
          </div>
          <div *ngIf="showLoading" class="spinning-loader"></div>
      </form>
  </div>
  <version-footer></version-footer>
  <instructions></instructions>
</div>
