<div class="dialog-background"></div>
<div class="dialog-container">
  <div class="loading-assets-dialog-top-container">
    <label class="loading-assets-dialog-text">Something went wrong loading this page. Please try again.</label>
  </div>
  <div class="loading-assets-dialog-bottom-container">
    <button class="loading-assets-dialog-btn" (click)="logoutBtnDialogClick()">
      <span class='btn-login-text'>Logout</span>
    </button>
    <button class="loading-assets-dialog-btn" (click)="retryBtnDialogClick()">
      <span class='btn-login-text'>Retry</span>
    </button>
  </div>
</div>
