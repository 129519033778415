import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial, Response } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { ResponseTile } from 'src/app/tasks/find-the-word-syllables-intervention/find-the-word-syllables-intervention.model';
import { InterventionTaskComponent } from '../intervention-task.component';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'app-find-the-word-syllables-intervention',
  templateUrl: './find-the-word-syllables-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindTheWordSyllablesInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit, OnDestroy {

  // Task specific variables
  targetWord: string = '';
  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  numberOfAttemptsForTrial: number = 0;
  responsesDisabled: boolean = true;
  trialResponseList: Response[] = [];
  responseOptions: ResponseTile[] = [];
  responseOptions1: ResponseTile[] = [];
  responseOptions2: ResponseTile[] = [];
  responseOptions3: ResponseTile[] = [];
  firstResponseTime: number = 0;
  secondResponseTime: number = 0;
  findTheSyllableBreaksTask: boolean = false;

  private hasResponseAudioSupportButtons: boolean = false;
  private originalStartTime: number = 0;
  private audioPlayerSubscription: Subscription = new Subscription();
  private moveToNextTrialSubscription: Subscription = new Subscription();
  private saveTaskDataSubscription: Subscription = new Subscription();


  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public router: Router,
    public shuffleService: ShuffleService,
    public taskService: TaskService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
    // Passages Subtask
    this.findTheSyllableBreaksTask = this.task.id.indexOf('FSB') === 0;
    this.hasResponseAudioSupportButtons = !this.findTheSyllableBreaksTask;
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt, this.stateParams?.parentTaskId)
        .pipe(first(),
          map(() => {
            if ((this.task.id.indexOf('FWF') === 0) || (this.task.id.indexOf('FTW') === 0))
            {
              let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
              // TODO: could probably find a better way to do this
              timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
              this.hideTimer = !timerBarSettings.timerBarEnabled
            }
            else
            {
              // As specified in Feature 4226, the timer bar should always be removed from any Find the Syllable Breaks tasks
              this.hideTimer = true ;
              this.trialTimerBar.hideTimerBar() ;
            }
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
            } else {
              return of({});
            }
          }),
        )
        .subscribe({
          complete: () => this.displayTrial(),
          error: () => this.displayTrial(),
        });
    });

    // Display the focus dialog if needs focus is set (from intervention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  ngOnDestroy() {
    this.audioPlayerSubscription.unsubscribe();
    this.saveTaskDataSubscription.unsubscribe();
    this.moveToNextTrialSubscription.unsubscribe();

    super.ngOnDestroy();
  }

  // Update the total points
  updateTotalPoints(points: number) {
    this.taskTotalPoints += points;
  }

  removeResponseHighlighting() {
    for (let response in this.responseOptions)
    {
      this.responseOptions[response].highlight = false;
    }
  }

  highlightCorrectResponse() {
    for (let response in this.responseOptions)
    {
      this.responseOptions[response].highlight = this.responseOptions[response].isCorrect;
    }
    this.changeDetector.markForCheck() ;
  }

  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.playTargetAudio();
  }

  // Set up audio for the speaker button
  playTargetAudio(audioCompleteCallback?: Function) {
    this.audioPlayerSubscription.unsubscribe();
    this.audioPlayerSubscription = this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe({
      complete: () => {
        if (audioCompleteCallback) audioCompleteCallback();
      },
      error: (err: any) => {
        this.logAudioPlaybackSentryError('find-the-word-syllables-intervention', 'play-target-audio', this.trials[this.trialIndex].word['@audio'], err);
        if (audioCompleteCallback) audioCompleteCallback();
      },
    });
  }

  playResponseAudio(index: number) {
    this.dataTracker.requestSupport++;
    this.audioPlayerSubscription.unsubscribe();
    this.audioPlayerSubscription = this.audioPlayerService.play(this.trialResponseList[index]['@audio']!).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('find-the-word-syllables-intervention', 'play-response-audio', this.trialResponseList[index]['@audio']!, err),
    });

     window.setTimeout(() => {
       this.responsesDisabled = false;
     }, this.interventionTaskService.letterAudioDelay);
  }

  firstResponseIncorrectSequence() {
    this.disableAgainButton = true;
    this.hideAgainButton = true;
    this.changeDetector.markForCheck() ;

    this.playTargetAudio(() => {
      this.removeResponseHighlighting();
      if (this.hasResponseAudioSupportButtons)
      {
        this.showResponseAudioButtons = true;
      }

      this.startTime = this.timerService.startTimer();
      this.responsesDisabled = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;
    });
  }

  secondResponseIncorrectSequence() {
    this.highlightCorrectResponse();
    this.playTargetAudio();
    this.reusableTimer = window.setTimeout(() => {
      let responseObject = this.trialList[this.trialList.length - 1];

      this.moveToNextTrialSubscription = this.interventionTaskService.moveToNextTrial(responseObject, false)
        .subscribe({
          complete: () => {
            this.moveToNextTrialSubscription.unsubscribe();
            this.updateTotalPoints(responseObject.points);
            this.endOfTrialCallback();
          }
        });
    }, this.interventionTaskService.moveToNextTrialDelay);
  }

  trialLoopAudioComplete() {
    // Allow user to submit a response to the trial
    this.responsesDisabled = false;
    this.disableAVButtons = false;
    this.changeDetector.markForCheck() ;

    this.originalStartTime = this.timerService.startTimer();
    this.startTime = this.originalStartTime;
    let timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
    if (timerBarTaskSettings.timerBarEnabled) {
      let initialDelay = this.interventionTaskService.trialBarBaseDelay + timerBarTaskSettings.timerBarDelay;
      this.trialTimerBar.startTrialTimer(timerBarTaskSettings.timerBarSpeed, initialDelay);
    }
  }

  trialInstructionalAudioComplete() {
    this.playTargetAudio(() => this.trialLoopAudioComplete());
  }

  displayTrial() {
    this.stopInterval();

    // Remove any left over highlighting from the previous trial
    this.removeResponseHighlighting();
    this.targetWord = this.trials[this.trialIndex].word['#text'];
    this.numberOfAttemptsForTrial = 0;
    this.showResponseAudioButtons = false;
    this.createResponseList();
    this.changeDetector.markForCheck() ;

    // Plays the trial instructional audio just the first time, followed by enabling responses
    if (this.trialIndex === 0){
      this.audioPlayerSubscription.unsubscribe();
      this.audioPlayerSubscription = this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
        complete: () => this.trialInstructionalAudioComplete(),
        error: () => this.trialInstructionalAudioComplete(),
      });
    } else {
      this.trialInstructionalAudioComplete();
    }
  }

  createResponseList() {
    this.responseOptions = [];
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    // Create a shallow copy of the response list
    let responseList = this.trials[this.trialIndex]['resp-list']!.resp.slice();

    // Reduce the response list if applicable
    responseList = this.interventionTaskService.reduceResponsesIfNecessary(responseList);

    // Shuffle the responses if the curriculum calls for it.
    responseList = this.interventionTaskService.shuffleResponses(responseList, this.trials[this.trialIndex]['resp-list']!['@randomResponses']);

    // Set the global trial response list to the now fully formed trimmed/shuffled list.
    this.trialResponseList = responseList;

    // Build the response lists
    let midDot = '\u00B7';
    let notHighlighted = false;
    let index = 0;
    let responseText;
    let findTheWordFamilyTask = (this.task.id.indexOf('FWF') === 0);

    for (let responseIndex in responseList) {
      let wordFamily = '';

      if (this.findTheSyllableBreaksTask)
      {
        // Replace + signs in responses with the midDot character
        responseText = responseList[responseIndex]['#text'].split('+').join(midDot);
      }
      else if (findTheWordFamilyTask) {
        // Split into word family
        var responseParts = responseList[responseIndex]['#text'].split('+');
        responseText = responseParts[0];
        wordFamily = responseParts[1];
      }
      else
      {
        responseText = responseList[responseIndex]['#text'];
      }

      var isCorrectResponse = (responseList[responseIndex]['@type'] === 'Correct');
      this.responseOptions.push(new ResponseTile(index, responseText, notHighlighted, isCorrectResponse, wordFamily));
      if (isCorrectResponse)
      {
        if (wordFamily === '')
        {
          this.dataTracker.targetAnswer = responseText;
        }
        else
        {
          this.dataTracker.targetAnswer = wordFamily;
        }
      }
      index++;
    }

    if (this.findTheSyllableBreaksTask)
    {
      this.responseOptions1 = this.responseOptions.slice(0,3);
      this.responseOptions2 = this.responseOptions.slice(3,6);
      this.responseOptions3 = [];
    }
    else
    {
      if (this.responseOptions.length > 4)
      {
        this.responseOptions1 = this.responseOptions.slice(0,3);
        this.responseOptions2 = this.responseOptions.slice(3,5);
        this.responseOptions3 = this.responseOptions.slice(5,8);
      }
      else
      {
        this.responseOptions1 = this.responseOptions.slice(0,2);
        this.responseOptions2 = this.responseOptions.slice(2,4);
        this.responseOptions3 = [];
      }
    }

    this.showResponseAudioButtons = this.hasResponseAudioSupportButtons && this.interventionTaskService.hasInitialAudioSupport();
  }

  // Function that executes when the user clicks on a response tile
  submitResponse(selectedResponse: number) {
    this.disableNextButton = true;
    this.disableAgainButton = true;
    this.disableResponseButtons = true;
    this.responsesDisabled = true;
    this.disableAVButtons = true;

    this.numberOfAttemptsForTrial++;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    if (this.numberOfAttemptsForTrial === 1)
    {
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      this.secondResponseTime = 0;
    }
    else
    {
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    }

    // Record the student's response
    let responseTile = this.responseOptions[selectedResponse];
    let isCorrect = responseTile.isCorrect;
    responseTile.highlight = true;
    this.interventionTaskService.playSoundEffect(isCorrect);
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, (responseTile.wordFamily === '' ? responseTile.text : responseTile.wordFamily));

    if (isCorrect || !this.isUnit)
    {
      // If the response is correct or the student is taking the pre/post test version of this task
      let isTrialCorrect = isCorrect && (this.numberOfAttemptsForTrial == 1);
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(
        isTrialCorrect,
        this.trialIndex,
        this.firstResponseTime,
        this.secondResponseTime,
        trialPoints,
        this.dataTracker,
        selectedResponse
      );

      if (isTrialCorrect)
      {
        this.numberOfCorrectTrials++;
      }
      this.trialList.push(responseObject);

      this.taskService.answerTrial(isTrialCorrect) ;

      this.moveToNextTrialSubscription = this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.moveToNextTrialSubscription.unsubscribe();
          this.updateTotalPoints(responseObject.points);
          this.endOfTrialCallback();
        }
      });
    }
    else if (this.numberOfAttemptsForTrial == 1) {
      // First incorrect response for instructional unit
      this.reusableTimer = window.setTimeout(() => {
        // Play "Please try again..." followed by showing support audio buttons and enabling response/AV buttons
        this.audioPlayerSubscription.unsubscribe();
        this.audioPlayerSubscription = this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
          complete: () => this.firstResponseIncorrectSequence(),
          error: () => this.firstResponseIncorrectSequence()
        });
      }, this.interventionTaskService.firstIncorrectDelay);
    }
    else {
      // Second incorrect response for instructional unit
      this.disableAVButtons = true;
      this.interventionTaskService.trackResponseTrends(isCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex, this.firstResponseTime,
          this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      this.trialList.push(responseObject);
      this.taskService.answerTrial(isCorrect) ;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerSubscription.unsubscribe();
        this.audioPlayerSubscription = this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
          complete: () => this.secondResponseIncorrectSequence(),
          error: () => this.secondResponseIncorrectSequence()
        });
      }, this.interventionTaskService.secondIncorrectDelay);
    }
  }


  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  endOfTrialCallback() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();
      this.trialIndex++;

      if (this.trialIndex < this.trials.length)
      {
        this.displayTrial();
      }
      else
      {
        this.saveTaskData();
      }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.saveTaskDataSubscription = this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
        this.saveTaskDataSubscription.unsubscribe();
      },
      error: (err: any) => {
        this.saveDataDialog.showSaveDataDialog();
        this.saveTaskDataSubscription.unsubscribe();
        this.logSaveTaskSentryError('find-the-word-syllables-intervention', err) ;
      }
    });
  }
}
