import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TaskService } from '../../core/services/task.service';
import { Subscription } from 'rxjs';

// Constants
const INTERVENTION_INCOMPLETE_COUNTER_CLASS: string = 'incomplete';
const DIAGNOSTIC_INCOMPLETE_COUNTER_CLASS: string = 'neutral-incomplete';
const FILLED_COUNTER_CLASS: string = 'filled';
const CORRECT_COUNTER_CLASS: string = 'correct';
const INCORRECT_COUNTER_CLASS: string = 'incorrect';

@Component({
  selector: 'trial-counter',
  templateUrl: './trial-counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialCounterComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() showCorrectIncorrect?: boolean;
  @Input() taskAnimationComplete?: boolean;
  trialStatus: Array<{ [key: string]: string }> = [];

  // Trial counter is the same color as the task bar
  trialCounterImageEmpty: string = '';
  trialCounterColor: string = '';
  avatarIcon: string = '';

  // Create trial counter with 10 trials
  numberOfTrials: number;
  // Set initial index to 0
  trialCounterCurrentIndex: number = 0;

  // Update the trial counter by completing the next trial
  trialCounterTimer: any = null;
  blockWidth: number = 0 ;
  marginLeft: number = 0 ;

  private trialSubscription?: Subscription ;

  constructor(
    private studentDataService: StudentDataService,
    private taskService: TaskService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.numberOfTrials = this.studentDataService.getSelectedTask().trial.length;
    this.blockWidth = 96/this.numberOfTrials;
    this.marginLeft = (20 - this.numberOfTrials) * 2;
  }

  ngOnInit(): void {
    this.trialCounterColor = this.studentDataService.getTaskBarColor();
    this.avatarIcon = this.studentDataService.getCurrentStudentAvatar().avatar;
    this.trialCounterImageEmpty = this.showCorrectIncorrect ? INTERVENTION_INCOMPLETE_COUNTER_CLASS : DIAGNOSTIC_INCOMPLETE_COUNTER_CLASS;
    for (let i = 0; i < this.numberOfTrials; ++i) {
      this.trialStatus = [ ...this.trialStatus, {
        class: this.trialCounterImageEmpty,
        blockWidth: `${this.blockWidth}%`,
        chipWidth: '100%',
        leftMargin: `${this.marginLeft}%`,
        blockHeight: '100%',
        chipHeight: '80%',
        idx: `${i}`,
      }] ;
    }

    // Subscribe to our trial service that emits if the trial was correct or not
    this.trialSubscription = this.taskService.trialAnswer$.subscribe({
      'next': (answerIsCorrect) => {
        if (this.showCorrectIncorrect) {
          if (answerIsCorrect) {
            this.doUpdateTrialCounter(CORRECT_COUNTER_CLASS);
          } else {
            this.doUpdateTrialCounter(INCORRECT_COUNTER_CLASS);
          }
        } else {
          this.doUpdateTrialCounter(FILLED_COUNTER_CLASS);
        }
      }
    }) ;
  }

  ngOnDestroy(): void {
    if (this?.trialCounterTimer) {
      clearTimeout(this.trialCounterTimer);
    }

    // Remove our subscription to the trial answer observable
    this.trialSubscription?.unsubscribe() ;
  }

  doUpdateTrialCounter(fillImageForTrial: string) {
    if (this.trialCounterCurrentIndex < this.numberOfTrials) {
      this.trialStatus[this.trialCounterCurrentIndex] = {
        class: fillImageForTrial,
        blockWidth: `${this.blockWidth}%`,
        chipWidth: '100%',
        leftMargin: `${this.marginLeft}%`,
        blockHeight: '100%',
        chipHeight: '80%',
        idx: `${this.trialCounterCurrentIndex}`,
      } ;

      // Increment current index for next time
      this.trialCounterCurrentIndex++;

      // We updated a trial status so alert the app to update
      this.changeDetectorRef.detectChanges() ;
    }
  }
}
