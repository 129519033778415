<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element fill-in-blank-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    </div>
    <div class="find-the-phrase-target-area-no-border">
      <button class="speaker-no-target-area" (click)="playTargetAudioViaSpeakerClick()"
      [disabled]="disableAVButtons"></button>
    </div>
    <div class="find-the-phrase-button-div" *ngFor="let response of responseOptions; index as $index">
      <button class = "audio-support-top-left-corner-hidden" *ngIf="!showResponseAudioButtons"></button>
      <button class = "audio-support-top-left-corner" *ngIf="showResponseAudioButtons"
        (click)="playResponseAudio(response.index)" [disabled]="disableAVButtons"></button>
      <button #responseButton class = "find-the-phrase-response-block font-30"
          [ngClass]="{'hover': response.highlight}"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="responsesDisabled"
          (click)="submitResponse(response.index); removeHoverClass(responseButton);"
          (touchstart)="addHoverClass(responseButton)" (touchend)="removeHoverClass(responseButton)"
          (mouseenter)="addHoverClass(responseButton)" (mouseleave)="removeHoverClass(responseButton)">
        {{response.text}}
      </button>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>
