import {
  Injectable,
} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationStateService } from '../services/application-state.service';

@Injectable()
export class BasicAuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private applicationStateService: ApplicationStateService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Defaults to basic auth if request has credentials but there is no SSO Provider.
    let modifyRequest = request.withCredentials && !this.applicationStateService.getSSOInfo()?.provider;

    if (modifyRequest)
    {
      let authHeader = 'Basic ' + btoa(`${this.applicationStateService.getUsername()}:${this.applicationStateService.getPassword()}`);
      request = request.clone({
        setHeaders: {
          Authorization: authHeader,
        }
      });
    }

    return next.handle(request);
  }
}