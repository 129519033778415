<div class="viewport-1" [ngStyle]="{'background-image':'url(' + taskBackgroundImage + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="totalTaskPoints"></task-bar>

  <div class="task-screen-element fill-in-blank-container"
    [ngStyle]="{'background-image': ' ' + taskContainerColor + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <button class="replayVideo-no-timerbar" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    <div class="word-to-verify">
      <!-- tiles that spell the incomplete word will go in here
      Probably use ngFor to loop over letters in the target word-->
      <button class="speaker" (click)="playWordAudio()" [disabled]="disableAVButtons"></button>
      <div class="diagnostic-target-row">
        <div class="diagnostic-target-block" [ngClass]="{'first-target-block-left-fill' : showTargetSilentE}"
          *ngFor="let letter of targetWord; index as $index">
          <div class="diagnostic-target-text font-30">
            {{letter}}
          </div>
        </div>
      </div>
    </div>
    <!-- response options on tiles will go in here (will need to separate into two sets for the different rows)
    Probably ngFor looping through the response options-->
    <div class="response-row">
      <div class="button-div" *ngFor="let response of responseOptions1; index as $index">
        <button class="font-30" [ngClass]="(disableResponseButtons) ? ['response-block-grayed'] : ['response-block']"
          [ngStyle]="{'border-color':' ' + highlightColor + ' '}" [disabled]="disableResponseButtons"
          (click)="submitAnswer($index)">
          {{response}}
        </button>
        <button class="font-24" [ngClass]="(disableResponseButtons) ? ['silent-e-grayed'] : ['silent-e']"
          *ngIf="silentTiles[$index]" [ngStyle]="{'border-color':' ' + highlightColor + ' '}"
          [disabled]="disableResponseButtons" (click)="submitAnswer($index)">
          e
        </button>
        <button class="silent-e-hidden" *ngIf="!silentTiles[$index]" [ngStyle]="{'border-color':' ' + highlightColor + ' '}"></button>
      </div>
    </div>
    <div class="response-row">
      <!-- use $index + 4 so we can keep the index returned unique because $index resets when we start a new ngFor -->
      <div class="button-div" *ngFor="let response of responseOptions2; index as $index">
        <button class="font-30" [ngClass]="(disableResponseButtons) ? ['response-block-grayed'] : ['response-block']"
          [ngStyle]="{'border-color':' ' + highlightColor + ' '}" [disabled]="disableResponseButtons"
          (click)="submitAnswer($index+4)">
          {{response}}
        </button>
        <button class="font-24" [ngClass]="(disableResponseButtons) ? ['silent-e-grayed'] : ['silent-e']"
          *ngIf="silentTiles[$index + 4]" [ngStyle]="{'border-color':' ' + highlightColor + ' '}"
          [disabled]="disableResponseButtons" (click)="submitAnswer($index+4)">
          e
        </button>
        <button class="silent-e-hidden" *ngIf="!silentTiles[$index + 4]" [ngStyle]="{'border-color':' ' + highlightColor + ' '}"></button>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="false" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTrialData()"></save-data-dialog>
</div>