import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StudentDataService } from './student-data.service';
import { environment } from '../../../environments/environment';
import { InterventionTaskTheme, TaskTheme, ThemeProperties } from '../models/theme.model';
import { map } from 'rxjs/operators';

const CANVAS_WIDTH = 1440;
const CANVAS_HEIGHT = 1024;

@Injectable({
  providedIn: 'root'
})
export class ThemeParseService {

  constructor(
    private httpClient: HttpClient,
    private studentDataService: StudentDataService,
  ) { }

  loadThemeProperties() {
    let themeFilePath = this.getThemeFilePath();
    let url = environment.AssetServerURL + themeFilePath;

    return this.httpClient.get(url).pipe(
      map((data: any) => {
        let assessmentType = this.getAssessmentType();
        let themeProperties: ThemeProperties = {
          sideMenu: data.theme['sideMenu'],
          worldView: data.theme['worldView'],
          levelSelect: this.recalculateButtonPositions(data.theme[assessmentType]),
        };

        this.studentDataService.setThemeProperties(themeProperties);


        return themeProperties;
      }),
    );
  }

  private getThemeFilePath() {
    return '/assets/themes/theme_' + this.studentDataService.getStudentThemeNumber() + ".json";
  }

  private getAssessmentType() {
    let assessmentType = '';
    if (this.studentDataService.isScreenerDiagnostic()) {
      assessmentType = 'screener';
    }
    else if (this.studentDataService.isPreDiagnostic()) {
      assessmentType = 'preDiagnostic';
    }
    else if (this.studentDataService.isMidDiagnostic()) {
      assessmentType = 'midDiagnostic';
    }
    else if (this.studentDataService.isPostDiagnostic()) {
      assessmentType = 'postDiagnostic';
    }
    else if (this.studentDataService.isIntervention()) {
      assessmentType = 'intervention';
    }

    return assessmentType;
  }

  private recalculateButtonPositions(propertiesList: TaskTheme | InterventionTaskTheme): TaskTheme | InterventionTaskTheme {
    let xPositions = [];
    let yPositions = [];

    if (Array.isArray(propertiesList))
    {
      for (let list in propertiesList)
      {
        xPositions = propertiesList[list].xPositions;
        yPositions = propertiesList[list].yPositions;

        for (let position in xPositions)
        {
          xPositions[position] = xPositions[position]/CANVAS_WIDTH*100;
          yPositions[position] = yPositions[position]/CANVAS_HEIGHT*100;
        }

        propertiesList[list].xPositions = xPositions;
        propertiesList[list].yPositions = yPositions;
      }
    }
    else
    {
      xPositions = propertiesList.xPositions;
      yPositions = propertiesList.yPositions;

      for (let position in xPositions){
        xPositions[position] = xPositions[position]/CANVAS_WIDTH*100;
        yPositions[position] = yPositions[position]/CANVAS_HEIGHT*100;
      }

      propertiesList.xPositions = xPositions;
      propertiesList.yPositions = yPositions;
    }
    return propertiesList;
  }
}
