import {InterventionTaskTheme, TaskTheme, THEMES} from './theme.model' ;

export class TaskSelectButton {
  public pointsLeft: boolean = false ;
  public showPassageTitle: boolean ;
  public divContainer: string = '' ;
  public buttonImage: string = '' ;

  constructor(
    public index: number,
    public type: string,
    public name: string,
    public icon: string,
    public passageTitle: string | null,
    public previousScore: number,
    public score: number,
    public visible: boolean,
    public disabled: boolean,
    public completed: boolean,
    private allTasksIncomplete: boolean,
    private allTasksCompleteForDestination: boolean,
    private displayAlternatingTasks: boolean,
    private demo: boolean,
    private levelProperties: InterventionTaskTheme | TaskTheme | undefined,
    public theme: number,
  ) {
    // Based on our arguments, set some additional properties for this TaskSelectButton
    this.pointsLeft = (this.theme !== THEMES.secondary) && ((this.index % 2) === 0) ;
    this.icon = `/assets/images/destination/${this.icon}.png` ;
    this.showPassageTitle = (this.passageTitle !== null && this.passageTitle.trim() !== '') ;
    if (displayAlternatingTasks)
    {

      if (!allTasksIncomplete && !allTasksCompleteForDestination)
      {
        this.divContainer = 'pre-task-btn-container-base' ;
      }
      else
      {
        this.divContainer = 'pre-task-btn-container-base task-btn-container-appear' ;
      }

      if (this.completed)
      {
        this.buttonImage = (this.pointsLeft) ? levelProperties!.taskButtons.taskSelectBtnRComplete : levelProperties!.taskButtons.taskSelectBtnLComplete;
      }
      else
      {
        this.buttonImage = (this.pointsLeft) ? levelProperties!.taskButtons.taskSelectBtnRUp : levelProperties!.taskButtons.taskSelectBtnLUp;
      }
    }
    else
    {
      // If we are coming from a task, don't drop the buttons down
      if (!allTasksIncomplete && !allTasksCompleteForDestination)
      {
        this.divContainer = 'task-btn-container-base';
      }
      else
      {
        this.divContainer = 'task-btn-container-base task-btn-container-appear';
      }

      if (this.completed)
      {
        if (this.showPassageTitle)
        {
          this.buttonImage = levelProperties!.taskButtons.taskSelectPassagesBtnComplete ;
        }
        else
        {
          this.buttonImage = (this.previousScore !== -1 || this.previousScore > 0) ? levelProperties!.taskButtons.taskSelectCompareBtnComplete : levelProperties!.taskButtons.taskSelectBtnComplete;
        }
      }
      else
      {
        if (this.showPassageTitle)
        {
          this.buttonImage = levelProperties!.taskButtons.taskSelectPassagesBtnUp ;
        }
        else
        {
          this.buttonImage = (this.previousScore !== -1 || this.previousScore > 0) ? levelProperties!.taskButtons.taskSelectCompareBtnUp : levelProperties!.taskButtons.taskSelectBtnUp;
        }
      }
    }
  }

  displayScore(): string {
    return this.score + '%' ;
  }

  displayPreviousScore(): string {
    if (this.previousScore === -1) return '- - -' ;
    else if (this.previousScore > 0) return this.previousScore + '%' ;
    else return '' ;
  }

  shouldDisplayPreviousScore(): boolean {
    return !this.showPassageTitle && (this.previousScore === -1 || this.previousScore > 0) ;
  }

  shouldDisplayScore(): boolean {
    return !this.showPassageTitle && this.score >= 0 ;
  }
}
