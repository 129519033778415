import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, NgZone} from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { PictureWithText } from './find-the-picture-intervention.model';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'find-the-picture-intervention',
  templateUrl: './find-the-picture-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindThePictureInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit, OnDestroy {
  // Task specific variables
  showPassageAudio: boolean = false;
  passageTitle: string = '';
  passageTitleAudio: string = '';
  passageAudio: string = '';
  targetWord: string = '';
  isSecondGradeOrLess: boolean = false;
  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  numberOfAttemptsForTrial: number = 0;
  maskerTime: number = 0;
  passagesSubTask: boolean = false;
  phrase: any[] = [];
  showPassageDialog: boolean = false;
  responseOptions: PictureWithText[] = [];
  firstResponseTime: number = 0;
  secondResponseTime: number = 0;

  private backButtonSubscription?: Subscription;


  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public router: Router,
    public shuffleService: ShuffleService,
    public taskService: TaskService,
    public changeDetector: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    this.isSecondGradeOrLess = this.studentDataService.isStudentSecondGradeOrLess();
    this.maskerTime = this.task.maskerTime || 0 ;
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
    // Passages Subtask
    this.passagesSubTask = (this.task.taskType.indexOf("PASSAGES...") === 0);
    if (this.passagesSubTask){
      let passage = this.stateParams.passage;
      this.passageTitle = passage.title['#text'];
      this.passageTitleAudio = passage.title['@audio'];
      this.passageAudio = passage['@audio'];
      this.phrase = passage.phrase ?? [];
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.backButtonSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt, this.stateParams?.parentTaskId)
        .pipe(first(),
          map(() => {
            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
            } else {
              return of({});
            }
          }),
        )
        .subscribe({
          complete: () => this.maskedAudioCompleteFunc(),
          error: () => this.maskedAudioCompleteFunc()
        });
    });

    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  createPictureWithText(imageUrl: string, text: string, isCorrectResponse: boolean, highlight: boolean, audio: string): PictureWithText {
    let pictureWithText: PictureWithText = {
      imageUrl: imageUrl,
      text: text,
      isCorrectResponse: isCorrectResponse,
      highlight: highlight,
      audio: audio
    }
    return pictureWithText;
  }

  // go to passage page
  togglePassage() {
    this.showPassageDialog = !this.showPassageDialog;
    // Stop all audios when we exit passage, but doesn't hurt on the way in either
    this.audioPlayerService.stopAll();
  }

  playLine(audio: string){
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(audio).subscribe();
  }

  playTitle() {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.passageTitleAudio).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('find-the-picture-intervention', 'play-title', this.passageTitleAudio, err),
    });
  }

  playPassage() {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.passageAudio).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('find-the-picture-intervention', 'play-passage', this.passageAudio, err),
    });
  }

  // Update the total points
  updateTotalPoints(points: number) {
    this.taskTotalPoints += points;
  }

  removeResponseHighlighting() {
    for (let response in this.responseOptions) {
      this.responseOptions[response].highlight = false;
    }
  }

  highlightCorrectResponse() {
    for (let response in this.responseOptions) {
      this.responseOptions[response].highlight = this.responseOptions[response].isCorrectResponse;
    }
    this.changeDetector.markForCheck() ;
  }

  // Run this function after the masked instructional audio plays
  maskedAudioCompleteFunc() {
    this.disableNextButton = false;
    this.disableAgainButton = true;
    this.hideAgainButton = true;
    this.hideResponses = true;
    this.buildResponseLists(this.trialIndex);
    if (this.unmaskedTask){
      this.displayTrial();
    } else {
      this.createInterval();
    }
  }

  buildResponseLists(index: number) {
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    // Build the response option lists from the curriculum
    this.responseOptions = [];
    let trialResponseList = this.trials[this.trialIndex]['resp-list']!.resp;
    for (var response in trialResponseList) {
      let isCorrectResponse = this.isUnit ? (trialResponseList[response]['@type'] === 'Correct') :
        (trialResponseList[response]['#text'] === this.trials[this.trialIndex].correct!.resp);
      let newResponse = this.createPictureWithText(
        trialResponseList[response]['@image']!,
        trialResponseList[response]['#text'],
        isCorrectResponse,
        false,
        trialResponseList[response]['@audio']!);
      this.responseOptions.push(newResponse);
      if (isCorrectResponse) {
        this.dataTracker.targetAnswer = trialResponseList[response]['#text'];
      }
    }

    // Shuffle the responses if the curriculum calls for it.
    let randomizeResponses = this.trials[this.trialIndex]['resp-list']!['@randomResponses'];
    this.responseOptions = this.interventionTaskService.shuffleResponses(this.responseOptions, randomizeResponses);
    this.showResponseAudioButtons = this.interventionTaskService.hasInitialAudioSupport();
  }

  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.playTargetAudio();
  }

  // Set up audio for the speaker button
  playTargetAudio() {
    this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('find-the-picture-intervention', 'play-target-audio', this.trials[this.trialIndex].word['@audio'], err),
    });
  }

  playPictureAudio(pictureAudioFile: string) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(pictureAudioFile).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('find-the-picture-intervention', 'play-picture-audio', pictureAudioFile, err),
    });
  }

  firstResponseIncorrectSequence() {
    this.removeResponseHighlighting();
    this.disableAgainButton = true;
    this.hideAgainButton = true;
    this.playTargetAudio();
    if (this.maskerTime != null) {
      // Unmask the target word
      this.targetWord = this.isUnit ? this.trials[this.trialIndex].word['#text'] : this.trials[this.trialIndex].word.toString();
    }
    this.showResponseAudioButtons = true;
    this.disableResponseButtons = false;
    this.disableAVButtons = false;
    this.changeDetector.markForCheck() ;
    this.startTime = this.timerService.startTimer();
  }

  secondResponseIncorrectSequence() {
    // TODO: In here, get our response trends and if we have 2 consecutive trials wrong then update the masker time if extra support student
    this.highlightCorrectResponse();
    this.playTargetAudio();
    this.reusableTimer = window.setTimeout(() => {
      let responseObject = this.trialList[this.trialList.length - 1];
      this.updateTotalPoints(responseObject.points);
      this.endOfTrialCallback();
      //this.interventionTaskService.moveToNextTrial(responseObject, false, endOfTrialCallback, updateTotalPoints);
    }, this.interventionTaskService.moveToNextTrialDelay);
  }

  displayTrial() {
    this.zone.run(() => {
      this.stopInterval();

      // Remove any left over highlighting from the previous trial
      this.removeResponseHighlighting();
      this.numberOfAttemptsForTrial = 0;
      this.maskerTime = this.task.maskerTime!;

      // If we have extra support and have gotten two in a row incorrect, our maskertime is extended for the whole task
      if (!this.unmaskedTask && this.interventionTaskService.hasTwoIncorrectInSequence && this.studentDataService.getStudentData().extraSupportNeeded)
      {
        this.maskerTime += this.interventionTaskService.maskerSupportExtend ;
      }

      this.disableNextButton = true;
      this.hideResponses = false;
      this.hideNextButton = true;
      this.hideAgainButton = true;
      this.disableAgainButton = false;
      this.targetWord = "______";
      this.changeDetector.markForCheck() ;

      this.reusableTimer = window.setTimeout(() => {
        this.targetWord = this.isUnit ? this.trials[this.trialIndex].word['#text'] : this.trials[this.trialIndex].word.toString();
        this.changeDetector.markForCheck() ;
        this.displayMasker();
        if (this.studentDataService.isInterventionPreTest() || (this.studentDataService.isInterventionPostTest())){
          this.hideAgainButton = true;
          this.disableAgainButton = true ;
        }
        else {
          this.hideAgainButton = false;
          this.disableAgainButton = false ;
        }
      }, this.task.focuserTime || 750);
    });
  }

  displayTrialAgain() {
    // Remove any left over highlighting from the previous trial
    this.removeResponseHighlighting();
    this.dataTracker.redisplayMaskedWord++;
    this.hideAgainButton = true;
    this.disableAgainButton = true;
    this.targetWord = "______";
    this.maskerTime = this.maskerTime + 60;
    this.changeDetector.markForCheck() ;

    this.reusableTimer = window.setTimeout(() => {
      this.targetWord = this.isUnit ? this.trials[this.trialIndex].word['#text'] : this.trials[this.trialIndex].word.toString();
      this.changeDetector.markForCheck() ;
      this.displayMasker();
    }, this.task.focuserTime || 750);
  }

  displayMasker() {
    if (!this.unmaskedTask) {
      this.reusableTimer = window.setTimeout(() => {
        this.targetWord = "########";
        this.disableResponseButtons = false;
        this.disableAVButtons = false;
        this.changeDetector.markForCheck() ;
        this.startTime = this.timerService.startTimer();
      }, this.maskerTime);
    }
    else if (this.passagesSubTask && this.isSecondGradeOrLess) {
      this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe({
        error: (err: any) => this.logAudioPlaybackSentryError('find-the-picture-intervention', 'play-initial-audio', this.trials[this.trialIndex].word['@audio'], err),
        complete: () => {
          this.disableResponseButtons = false;
          this.disableAVButtons = false;
          this.changeDetector.markForCheck() ;
          this.startTime = this.timerService.startTimer();
        }
      });
    }
    else {
      this.disableResponseButtons = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer();
    }
  }

  // Function that executes when the user clicks on a response tile
  submitResponse(selectedResponse: number) {
    this.disableNextButton = true;
    this.disableAgainButton = true;
    this.disableResponseButtons = true;
    this.disableAVButtons = true;

    this.numberOfAttemptsForTrial++;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    if (this.numberOfAttemptsForTrial == 1) {
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      this.secondResponseTime = 0;
    }
    else {
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    }

    // Record the student's response
    let responseTile = this.responseOptions[selectedResponse];
    let isCorrect = responseTile.isCorrectResponse;
    responseTile.highlight = true;
    this.interventionTaskService.playSoundEffect(isCorrect);
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, responseTile.text);

    if (isCorrect || !this.isUnit) {
      // If the response is correct or the student is taking the pre/post test version of this task
      let isTrialCorrect = isCorrect && (this.numberOfAttemptsForTrial == 1);
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      if (isTrialCorrect) {
        this.numberOfCorrectTrials++;
      }
      this.trialList.push(responseObject);

      this.taskService.answerTrial(isTrialCorrect) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.endOfTrialCallback();
        }
      });
    }
    else if (this.numberOfAttemptsForTrial == 1) {
      // First incorrect response for instructional unit
      this.reusableTimer = window.setTimeout(() => {
        // Play "Please try again..." followed by showing support audio buttons and enabling response/AV buttons
        this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
          complete: () => this.firstResponseIncorrectSequence(),
          error: () => this.firstResponseIncorrectSequence()
        });
      }, this.interventionTaskService.firstIncorrectDelay);
    }
    else {
      // Second incorrect response for instructional unit
      this.disableAVButtons = true;
      this.interventionTaskService.trackResponseTrends(isCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex, this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      this.trialList.push(responseObject);
      this.taskService.answerTrial(isCorrect) ;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
          complete: () => this.secondResponseIncorrectSequence(),
          error: () => this.secondResponseIncorrectSequence()
        });
      }, this.interventionTaskService.secondIncorrectDelay);
    }
  }


  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  endOfTrialCallback() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();
      this.trialIndex += 1;

      if (this.trialIndex < this.trials.length) {
        this.buildResponseLists(this.trialIndex);
        this.disableNextButton = false;
        this.hideNextButton = false;
        this.disableAgainButton = true;
        this.hideAgainButton = true;
        this.targetWord = '';
        this.hideResponses = true;
        if (this.unmaskedTask){
          this.displayTrial();
        } else {
          this.createInterval();
        }
      }
      else {
        this.hideNextButton = true;
        this.hideAgainButton = true;
        this.saveTaskData();
      }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error: (err: any) => {
        this.saveDataDialog.showSaveDataDialog() ;
        this.logSaveTaskSentryError('find-the-picture-intervention', err) ;
      }
    });
  }
}
