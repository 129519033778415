<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element change-the-blank-container" 
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? ['replayVideo-no-timerbar'] : ['replayVideo']" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    </div>
    <div class="word-to-complete">
      <!-- tiles that spell the incomplete word will go in here
      Probably use ng-repeat to loop over letters in the target word-->
      <button class="speaker" (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons"></button>
      <div class="ctw-row">
        <div class="ctw-target-block" 
          *ngFor="let letter of targetWord; index as $index"
          [ngClass]="{'hover': highlightTargetTile && ($index === blankTile || ($index === targetSilentEIndex && showTargetSilentE)) }"
          [ngStyle]="{'border-color':' ' + this.interventionTaskService.highlightColor() + ' '}">
          <button class = "target-letter-audio-hidden" *ngIf="!showTargetAudioSupportButtons"></button>
          <button class = "target-letter-audio" [ngClass]="{'highlight': ($index === blankTile && highlightTargetTile)}"
            *ngIf="showTargetAudioSupportButtons" (click)="playTargetLetterAudio($index)" [disabled]="disableAVButtons"></button>
          <div class="ctw-target-text font-42" [ngClass]="{'highlight': ($index === blankTile && highlightTargetTile)}">
            {{letter}}
          </div>
        </div>
      </div>
    </div>
    <!-- response options on tiles will go in here (will need to separate into two sets for the different rows)
    Probably ngFor looping through the response options-->
    <div class="response-row">
      <div class="button-div" *ngFor="let response of responseOptions1; index as $index">
        <button class = "letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "letter-audio" *ngIf="showResponseAudioButtons" (click)="playLetterAudio($index)" [disabled]="disableAVButtons"></button>
        <button class="response-block  font-30" [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
            [disabled]="disableResponseButtons" (click)="submitResponse($index)">
          {{response.text}}
        </button>
        <button class="silent-e font-24" *ngIf="response.hasSilentE" [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
            [disabled]="disableResponseButtons" (click)="submitResponse($index)">
          e
        </button>
        <button class="silent-e-hidden" *ngIf="!response.hasSilentE" ng-style="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"></button>
      </div>
    </div>
    <div class="response-row">
      <div class="button-div" *ngFor="let response of responseOptions2; index as $index">
        <button class = "letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "letter-audio" *ngIf="showResponseAudioButtons" (click)="playLetterAudio(response.index)" [disabled]="disableAVButtons"></button>
        <button class ="response-block  font-30" [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
            [disabled]="disableResponseButtons" (click)="submitResponse(response.index)">
          {{response.text}}
        </button>
        <button class="silent-e font-24" *ngIf="response.hasSilentE" [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
            [disabled]="disableResponseButtons" (click)="submitResponse(response.index)">
          e
        </button>
        <button class="silent-e-hidden" *ngIf="!response.hasSilentE" [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"></button>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>
