import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'version-footer',
  templateUrl: './version-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionFooterComponent {
  copyrightYear: number = new Date().getFullYear();
  versionNumber: string = environment.versionNumber;

  constructor() { }
}
