import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { LoginService } from '../../login/login.service';
import { WorldResolveData } from './world.model';


const PAN_ZOOM_DURATION = 2000;
const PRE_ASSESSMENT_LEVEL_SELECT_LOCATION = 0;
const MID_ASSESSMENT_LEVEL_SELECT_LOCATION = 7;
const POST_ASSESSMENT_LEVEL_SELECT_LOCATION = 8;

@Component({
  selector: 'app-world',
  templateUrl: './world.component.html',
})
export class WorldComponent implements OnInit, OnDestroy {
  private logoutSubscription?: Subscription;
  private resolverSubscription!: Subscription ;
  displayCompletedMessage: boolean = false;
  animateActiveLocation: boolean = false;
  overlayImages: string[] = [];
  worldViewImage: string = "";
  worldViewLocationBackground: string = "";
  worldZoomClass: string = "";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private studentDataService: StudentDataService,
    private audioPlayerService: AudioPlayerService,
  ) { }

  ngOnInit(): void {
    this.overlayImages = this.getOverlayImages();
    this.worldViewImage = this.studentDataService.getWorldViewBackground();
    this.worldViewLocationBackground = this.studentDataService.getWorldViewActiveLocationBackground();
    this.resolverSubscription = this.activatedRoute.data.subscribe((resolved: any) => this.transition(resolved.data.worldData));
  }

  private transition({ allTasksWereJustCompleted, nextAssessmentComplete, midOrPostJustCompleted }: WorldResolveData) {
    if (allTasksWereJustCompleted && !nextAssessmentComplete && midOrPostJustCompleted)
    {
      // Play mid or post transition audio
      this.audioPlayerService.play('Audio/Help/audio_end-of-mid-diagnostic.mp3').subscribe({
        complete: () => this.panAndZoomToNextScreen(),
        error: () => this.panAndZoomToNextScreen(),
      });
    }
    else if (allTasksWereJustCompleted && nextAssessmentComplete)
    {
      // Completed Access Code (iASK doesn't come to world screen)
      this.displayEndOfProductAnimation('Audio/Help/audio_end-of-post-diagnostic.mp3');
    }
    else
    {
      this.animateActiveLocation = true;
      setTimeout(() => {
        this.panAndZoomToNextScreen();
      }, 2100);
    }
  }

  private getOverlayImages(): string[] {
    let overlayImages: string[] = [];
    if (this.studentDataService.isPreDiagnosticCompleted())
    {
      overlayImages.push(this.studentDataService.getWorldViewPreDiagnosticOverlay());
    }
    if (this.studentDataService.isMidDiagnosticCompleted())
    {
      overlayImages.push(this.studentDataService.getWorldViewMidDiagnosticOverlay());
    }
    if (this.studentDataService.isPostDiagnosticCompleted())
    {
      overlayImages.push(this.studentDataService.getWorldViewPostDiagnosticOverlay());
    }

    return overlayImages;
  }

  private getLevelSelectScreen(): number {
    let objectiveNumber = this.studentDataService.getStudentInterventionData()?.objectiveNumber;
    if (objectiveNumber != null)
      return objectiveNumber;

    if (this.studentDataService.isPreDiagnostic())
      return PRE_ASSESSMENT_LEVEL_SELECT_LOCATION;
    else if (this.studentDataService.isMidDiagnostic())
      return MID_ASSESSMENT_LEVEL_SELECT_LOCATION;
    else
      return POST_ASSESSMENT_LEVEL_SELECT_LOCATION;
  }

  private panAndZoomToNextScreen() {
    let team = this.studentDataService.getStudentTeam();
    let avatar = this.studentDataService.getStudentAvatar();
    let goToLevelSelect = (this.studentDataService.getCurrentDestination() == 0 ||
      (avatar.winningTeamAvatar && !avatar.hasBeenNotifiedOfWinningTeam) ||
      (avatar.weeklyGoalAvatar && !avatar.hasBeenNotifiedOfWeeklyGoal) ||
      ((team != null) && (!team.hasBeenNotifiedOfNewTeam)));

    this.worldZoomClass = this.getZoomAnimationClass();

    setTimeout(() => {
      this.resolverSubscription.unsubscribe() ;

      if (goToLevelSelect)
        this.router.navigateByUrl('/levelSelect', { state: { previous: this.router.url } });
      else
        this.router.navigateByUrl('/taskSelect');

    }, PAN_ZOOM_DURATION);

  }

  private getZoomAnimationClass(): string {
    let currentLevelSelectScreen = this.getLevelSelectScreen();
    let theme = this.studentDataService.getStudentThemeNumber();
    let className = '';

    if (currentLevelSelectScreen === PRE_ASSESSMENT_LEVEL_SELECT_LOCATION)
    {
      className = `theme-${theme}-pre-diagnostic-zoom`;
    }
    else if (currentLevelSelectScreen === MID_ASSESSMENT_LEVEL_SELECT_LOCATION)
    {
      className = `theme-${theme}-mid-diagnostic-zoom`;
    }
    else if (currentLevelSelectScreen === POST_ASSESSMENT_LEVEL_SELECT_LOCATION)
    {
      className = `theme-${theme}-post-diagnostic-zoom`;
    }
    else
    {
      className = `theme-${theme}-obj${currentLevelSelectScreen}-zoom`;
    }

    return className;
  }

  displayEndOfProductAnimation(audioFile: string) {
		this.displayCompletedMessage = true;
    this.audioPlayerService.play(audioFile).subscribe({
      complete: () => this.completeAudioDoneFunc(),
      error: (error: MediaError) => {
        console.error('Error playing audio: ', error);
        this.completeAudioDoneFunc();
      },
    });
  }

  ngOnDestroy() {
    this.logoutSubscription?.unsubscribe();
  }

  private completeAudioDoneFunc() {
    setTimeout(() => {
      this.logoutSubscription = this.loginService.studentLogout().pipe(take(1)).subscribe();
    }, 500);
  }

}
