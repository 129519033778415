<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element find-the-picture-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <button class="open-passage" [disabled]="disableAVButtons" (click)="togglePassage()" *ngIf="passagesSubTask"></button>
      <div class="passage-title font-27" *ngIf="passagesSubTask"> {{passageTitle}} </div>
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'" (click)="replayVideo()"
        [disabled]="disableNextButton && disableResponseButtons"></button>
    </div>
    <div class="word-to">
      <button class="speaker" *ngIf="showResponseAudioButtons || (isSecondGradeOrLess && passagesSubTask)" (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons"></button>
      <div class="word-masked font-42">
        {{targetWord}}
      </div>
      <button [ngClass]="{'again-button-grayed' : disableAgainButton, 'again-button' : !disableAgainButton, 'toggle-again-on' : toggleAgainButton, 'again-button-hidden': hideAgainButton}"
        *ngIf="!unmaskedTask"
        [disabled]="disableAgainButton"
        (click)="displayTrialAgain()">
      </button>
    </div>
    <!-- Row for Next Trial button -->
    <div class="full-response-row">
      <button [ngClass]="{'next-button-grayed' : disableNextButton, 'next-button' : !disableNextButton, 'toggle-next-on' : toggleNextButton, 'next-button-hidden': hideNextButton}"
        *ngIf="!unmaskedTask"
        [disabled]="disableNextButton"
        (click)="displayTrial()">
      </button>
    </div>
    <!-- Row for picture response tiles -->
    <div class="full-response-row-picture" *ngIf="!hideResponses">
      <div class="picture-button-div-intervention" *ngFor="let response of responseOptions; index as $index">
        <button class="ftp-letter-audio-hidden" *ngIf="!showResponseAudioButtons">
        </button>
        <button class="ftp-letter-audio" *ngIf="showResponseAudioButtons" (click)="playPictureAudio(response.audio)" [disabled]="disableAVButtons">
        </button>
        <button class="picture-response-block"
          [ngClass]="{'hover': response.highlight}"
          (click)="submitResponse($index)"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' ', 'background-image':'url(/assets/' + response.imageUrl + ')' }"
          [disabled]="disableResponseButtons">
        </button>
      </div>
    </div>
  </div>

  <div class="try-again-dialog-background" [ngStyle]="{'background': ' ' + interventionTaskService.taskBarColor() + ' '}" *ngIf="showPassageDialog"></div>
  <div class="passage-dialog-container" *ngIf="showPassageDialog" [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}">
    <button class="exit-passages-dialog" (click)="togglePassage()"></button>
    <div class="passages-target-area">
      <button class="speaker" (click)="playPassage()" [disabled]="disableAVButtons"></button>
      <div class="passages-target passages-dialog-target-adjust font-27">
        <button class="passages-title-audio" (click)="playTitle()"></button>
        {{passageTitle}}
      </div>
    </div>
    <!-- Container for sentences -->
    <div class="scrollable-sentence-container" id="{{'passage-scroll-' + currentDestination}}">
      <div class="passages-line font-20" *ngFor="let line of phrase; index as $index">
        <button class="passages-line-audio" (click)="playLine(line['@audio'])"></button>
        {{line['#text']}}
      </div>
    </div>
  </div>

  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>