<div class="viewport-1" id="task-select" tabindex="0" (keyup)="autoCompleteSubtask($event)" [style.background-image]="'url(' + taskSelectBackgroundImage + ')'">
  <student-header #studentHeader></student-header>
  <side-menu #sideMenu (selectedAvatar)="updateSelectedAvatar($event)" [disabled]="disableSideButton"></side-menu>

  <div [ngStyle]="{'background-image': 'url(' + taskSelectBackgroundImage + ')'}">
    <div class="subtask-select-container"
         [ngStyle]="{ 'background-image': 'url(' + subtaskSelectBackgroundImage + ')' }"
         [ngClass]="{
           'complete': allSubtasksComplete,
           'theme2': studentDataService.getStudentThemeNumber() === THEMES.secondary,
           'theme1' : studentDataService.getStudentThemeNumber() === THEMES.elementary
         }"
    >
      <div class="back-button">
        <button class="task_bar_button" [ngClass]="{'back_button_animate': isPulse}" (click)="goBack()"></button>
      </div>
      <div class="subtask-progress font-20">
        <div>{{ completedTasks }} / {{ totalTasks }}</div>
      </div>
      <div class="tasks">
        <ng-container *ngFor="let subtaskButton of subtaskButtonList;  let idx = index">
          <button class="task-container task-button font-20"
                  [ngStyle]="{'background-image':'url(' + subtaskButton.buttonImage + ')'}"
                  (mouseover)="toggleHighlight(idx, true)"
                  (mouseleave)="toggleHighlight(idx, false)"
                  (click)="gotoSubtask(idx)">
            <ng-container *ngIf="!subtaskButton.showPassageTitle">
              <p class="secondary-task-type task-btn-passage-title">{{ subtaskButton.secondaryTaskType }}</p>
              <p class="secondary-task-name task-btn-passage-title">{{ subtaskButton.secondaryTaskName }}</p>
            </ng-container>
            <p *ngIf="subtaskButton.showPassageTitle" class="task-btn-passage-title font-passage-title">{{ subtaskButton.passageTitle | formatString: 25 }}</p>
            <div><img [src]="subtaskButton.icon" alt="task background" /></div>
            <p class="font-18 subtask-btn-task-score" *ngIf="subtaskButton.shouldDisplayScore()">{{ subtaskButton.displayScore() }}</p>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <student-footer #studentFooter timerBackgroundImage="/assets/images/timer/timerLabel-task.svg"></student-footer>
</div>
