<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints" [indicatorTimer]="indicationTimer"></task-bar>

  <div class="task-screen-element passages-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <button class="replayVideo-no-timerbar" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    <div class="passages-target-area">
      <button class="speaker-passages-adjust speaker" (click)="playPassage()" [disabled]="disablePassageAudio"></button>
      <div class="passages-target font-30" [ngClass]="{ 'passages-line-highlight': highlightTitle }">
        <button class="passages-title-audio" (click)="playPassageTitle()" [disabled]="disablePassageAudio"></button>
        {{passageTitle}}
      </div>
    </div>
    <!-- Container for sentences -->
    <div class="scrollable-sentence-container" id="{{'passage-scroll-' + currentDestination}}">
      <div class="passages-line font-20" *ngFor="let phrase of phraseList; index as $index" [ngClass]="{ 'passages-line-highlight': phrase.highlight }">
        <button class="passages-line-audio" (click)="playPhraseAudio(phrase)"></button>
        {{phrase.text}}
      </div>
    </div>
  </div>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <focus-dialog></focus-dialog>
</div>
