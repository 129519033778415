<div class="viewport-1">
  <background #background></background>
  <img id="fil-logo" class="overlay-fil-logo" [ngClass]="{'fade-out': goToStartAnimation}" src="/assets/images/wordflight_vrt_color_pos_rgb.svg" />
  <div id="login-box" class="login-form-wrapper" [ngClass]="{'pop-out': loggedIn, 'hidden': showUsage || showUsageLogout || showUsageLogin}" *ngIf="!restrictLogin">
      <div id="failedLoginDisplay" class="alert custom-alert font-15"
        [ngClass]="{'alert-info': alertStatus === AlertStatus.INFO, 'alert-danger': alertStatus === AlertStatus.ERROR}"
         *ngIf="alertStatus !== AlertStatus.HIDDEN">
        {{result}}
      </div>
      <label class="login-text-label">Login</label>
      <form name="studentLoginForm" class="form login-form" [formGroup]="loginForm" (ngSubmit)="login()">
          <div *ngIf="capsOn" class="caps-lock-warning">
            <div><img src="/assets/images/caps-lock.svg" height="16" width="16" /></div>
            <div>Caps-lock is enabled</div>
          </div>
          <div class="student-form-group-wrapper">
            <div class="student-form-group">
                <label class="login-field-text font-27" for="username">Username</label>
                <label class="login-field-text font-27" for="password">Password</label>
            </div>
            <div class="student-form-group-inputs">
              <input type="text" id="username" name="username" formControlName="username" class="input-medium login-field  font-27" required autofocus>
              <input type="password" id="password" name="password" formControlName="password" class="input-medium login-field font-27" required>
            </div>
          </div>
          <button type="submit" [disabled]="!loginForm.valid || loginButtonDisabled" class="clear-btn btn-login"></button>
      </form>
      <div class="login-clever" *ngIf=showProviderButtons>
        <a [href]="cleverOauth"><img src="/assets/images/LogInWithClever.png" /></a>
        <a [href]="classlinkOauth"><img src="/assets/images/LogInWithClassLink.png" style="width: 207px;" /></a>
      </div>
  </div>
  <div *ngIf="restrictLogin" class="download-element-message" style="font-weight: bold ; font-size: 2rem ;">
    <div>
      WordFlight works best on mobile devices and tablets when using the WordFlight app, get it now...
    </div>
    <div class="download-app-wrapper">
      <div class="download-app-btn">
        <a [href]="appStoreLink"><img src="/assets/images/app-icon.png" width="48" height="48"/> WordFlight</a>
      </div>
    </div>
  </div>
  <div class="usage-element-message" [hidden]="!showUsage">
    <p>
      Hi! It looks like you've been really active with us this week! That's great, but it's also important to exercise your mind and body in
      other ways -- read a book, make some art, play games or play outside! Would you like to take a break and go do another activity?
    </p>
    <button class="btn btn-success" (click)="cancelLogin()">Sure</button>
    <button class="btn btn-secondary" (click)="continueLogin()">No Thanks</button>
  </div>
  <div class="usage-element-message" [hidden]="!showUsageLogout">
    <p>Great, we'll see you next time! Go have fun!</p>
  </div>
  <div class="usage-element-message" [hidden]="!showUsageLogin">
    <p>Enjoy your lessons today and make sure to spend some time with other activities!</p>
  </div>
  <version-footer [ngClass]="{'fade-out': goToStartAnimation}"></version-footer>
</div>
