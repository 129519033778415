import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StudentDataService } from 'src/app/core/services/student-data.service';

@Component({
  selector: 'total-points',
  templateUrl: './total-points.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalPointsComponent implements OnInit {
  @Input() totalPoints?: number;

  showTotalPointsContainer: boolean = false;
  totalPointsBackgroundColor: string = '';
  totalPointsBorderColor: string = '';

  constructor(
    private studentDataService: StudentDataService
  ) { }

  ngOnInit(): void {
    let inIntervention: boolean = this.studentDataService.isIntervention();
    this.showTotalPointsContainer = inIntervention;

    if (inIntervention) {
      this.totalPointsBackgroundColor = this.studentDataService.getTaskBarTotalPointsBackgroundColor();
      this.totalPointsBorderColor = this.studentDataService.getTaskBarTotalPointsBorderColor();
    }
  }
}
