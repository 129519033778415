<div class="viewport-1" [ngStyle]="{'background-image':'url(' + taskBackgroundImage + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="totalTaskPoints"></task-bar>
    <div class="task-screen-element verify-the-blank-container"
      [ngStyle]="{'background-image': ' ' + taskContainerColor + ' '}"
      [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <button class="replayVideo-no-timerbar" (click)="replayVideo()" [disabled]="disableVideo()">
    </button>
    <div class="word-to-verify">
      <button class="speaker" (click)="repeatWord()" [disabled]="disableAVButtons">
      </button>
      <div class="verify-word font-42">
        {{targetWord}}
      </div>
    </div>
    <div class="response-row">
      <button [ngClass]="(disableResponseButtons) ? ['verify-response-correct-grayed'] : ['verify-response-correct']"
          [ngStyle]="{'border-color':' ' + highlightColor + ' '}" [disabled]="disableResponseButtons" (click)="submitAnswer(true)">
      </button>
      <button  class="next-trial-base"
          [ngClass]="{'next-trial-hidden': hideNextButton || unmaskedTask, 'next-trial-grayed' : disableNextButton, 'next-trial' : !disableNextButton, 'toggle-next-on' : toggleNextButton}"
          [ngStyle]="{'border-color':' ' + highlightColor + ' ', 'position': 'relative ', 'top': '-40% '}"
          [disabled]="disableNextButton"
          (click)="nextTrial()">
      </button>
      <button [ngClass]="(disableResponseButtons) ? ['verify-response-incorrect-grayed'] : ['verify-response-incorrect']"
          [ngStyle]="{'border-color':' ' + highlightColor + ' '}" [disabled]="disableResponseButtons" (click)="submitAnswer(false)">
      </button>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="false" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTrialData()"></save-data-dialog>
</div>
