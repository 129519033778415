<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div class="task-screen-element fill-in-blank-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'" (click)="replayVideo()"
        [disabled]="disableAVButtons"></button>
    </div>
    <div [ngClass]="(isUnit) ? ['word-to-complete-unit'] : ['word-to-complete']">
      <button class="speaker" (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons"></button>
      <div class="target-row">
        <div class="position-target" *ngIf="isUnit"></div>
        <div class="stw-target-div" [ngClass]="{ 'intervention-target-block' : isUnit }"
          *ngFor="let letter of targetWord; index as $index">
          <button class = "target-letter-audio-hidden stw" *ngIf="!oneIncorrectAnswer"></button>
          <button class = "target-letter-audio stw" *ngIf="oneIncorrectAnswer"
            (click)="playTargetLetterAudio($index)" [disabled]="disableAVButtons"></button>
          <button class="stw-target-block font-30" [ngClass]="{'hover': $index==blankTile}"
            [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}">
            {{letter.text}}
          </button>
          <button [ngClass]="{'remove-letter-disabled': letter.disabled, 'remove-letter': !letter.disabled, 'remove-letter-disabled-hover': ($index==blankTile)}"
            [ngClass]="letter.disabled ? ['remove-letter-disabled'] : ['remove-letter']"
            (click)="removeLetter($index)" [disabled]="letter.disabled"></button>
        </div>
      </div>
      <button (click)="submitResponse()"
        [ngClass]="(disableSubmitButton) ? ['star-submit-disabled'] : ['star-submit']"
        [disabled]="disableSubmitButton" *ngIf="!hideSubmitButton"></button>
      </div>
    <div class="response-row">
      <div class="button-div" *ngFor="let response of responseOptions1; index as $index">
        <button class = "letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "letter-audio" *ngIf="showResponseAudioButtons"
          (click)="playLetterAudio(response.audio)"
          [disabled]="disableAVButtons || disableResponseButtons"></button>
        <button class="font-30"
          [ngClass]="(disableResponseButtons) ? ['response-block-grayed'] : ['response-block']"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="disableResponseButtons" (click)="addLetter($index)">
          {{response.text}}
        </button>
        <button class="font-24" [ngClass]="(disableResponseButtons) ? ['silent-e-grayed'] : ['silent-e']"
          *ngIf="response.hasSilentE"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="disableResponseButtons" (click)="addLetter($index)">
          e
        </button>
        <button class="silent-e-hidden" *ngIf="!response.hasSilentE"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"></button>
      </div>
    </div>
    <div class="response-row">
      <!-- use $index + 4 so we can keep the index returned unique because $index resets when we start a new ng-repeat -->
      <div class="button-div" *ngFor="let response of responseOptions2; index as $index">
        <button class = "letter-audio-hidden" *ngIf="!showResponseAudioButtons"></button>
        <button class = "letter-audio" *ngIf="showResponseAudioButtons"
          (click)="playLetterAudio(response.audio)"
          [disabled]="disableAVButtons || disableResponseButtons"></button>
        <button class="font-30"
          [ngClass]="(disableResponseButtons) ? ['response-block-grayed'] : ['response-block']"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="disableResponseButtons" (click)="addLetter($index+4)">
          {{response.text}}
        </button>
        <button class="font-24"
          [ngClass]="(disableResponseButtons) ? ['silent-e-grayed'] : ['silent-e']"
          *ngIf="response.hasSilentE"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          [disabled]="disableResponseButtons" (click)="addLetter($index+4)">
          e
        </button>
        <button class="silent-e-hidden" *ngIf="!response.hasSilentE"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"></button>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>
