import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial, Response, Tile } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'fill-in-blank-intervention',
  templateUrl: './fill-in-blank-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FillInBlankInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
    public router: Router,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }
  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
  }

  ngAfterViewInit() {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
        .pipe(first(),
          map(() => {
            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
            // TODO: could probably find a better way to do this
            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
            this.hideTimer = !timerBarSettings.timerBarEnabled
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
            } else {
              return of({});
            }
          }),
        )
        .subscribe({
          complete: () => this.defaultAudioCompleteFunc(),
          error: () => this.defaultAudioCompleteFunc(),
        });
    });

    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  trialResponseList: Response[] = [];
  incorrectFirstResponse: boolean = false;

  showIncorrectTop: boolean = false;
  showIncorrectBottom: boolean = false;
  incorrectTop: string[] = [];
  incorrectBottom: string[] = [];
  trialTargetTileList: any[] = [];
  silentTiles: boolean[] = [];
  audioList: string[] = [];
  showTargetSlash: boolean = false;
  floatIncorrectUp: boolean = false;
  responseSilentEIndex: number | null = null;
  targetSilentEIndex: number | null = null;
  correctResponseHasSilentE: boolean = false;
  blankTile: number = 0;
  targetWord: string[] = [];
  responseOptions: string[] = [];
  responseOptions1: string[] = [];
  responseOptions2: string[] = [];
  responseOptionsSilentE: boolean[] = [];
  correctResponseIndex: number = 0;
  targetAnswer: string = '';
  originalStartTime: number = 0;
  firstResponseTime: number = 0;
  secondResponseTime: number = 0;

  // These variables control the formatting that prevents shifting when a silent-e is involved in the response
  showBottomRowSilentE: boolean = false;
  showTopRowSilentE: boolean = false;
  showTargetSilentE: boolean = false;

  // Toggles the highlighting on the correct letter element
  highlightCorrectResponse: boolean = false;

  // Variable to prevent multiple responses from being clicked which would change the student's answer
  responsesDisabled: boolean = true;

  addHoverClass = function(element: HTMLElement) {
    element.classList.add('hover');
  }

  removeHoverClass = function(element: HTMLElement) {
    element.classList.remove('hover');
  }

  // Update the total points on $scope (generally used as a callback to the this.interventionTaskService)
  updateTotalPoints(points: number) {
    this.taskTotalPoints += points;
  }

  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('fill-in-blank-intervention', 'play-target-audio', this.trials[this.trialIndex].word['@audio'], err),
    });
  }

  // Set up audio for the speaker button
  playLetterAudio(index: number) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.trialResponseList[index]['@audio']!).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('fill-in-blank-intervention', 'play-letter-audio', this.trialResponseList[index]['@audio']!, err),
    });

    this.reusableTimer = window.setTimeout(() => {
      this.responsesDisabled = false;
    }, this.interventionTaskService.letterAudioDelay);
  }

  // Set up audio for the speaker button
  playTargetLetterAudio(index: number) {
    this.dataTracker.requestSupport++;
    let tileList = this.trialTargetTileList as Tile[];
    if (index === this.blankTile){
      let audio = this.trials[this.trialIndex]['display'].blankTile!['@audio'];
      this.audioPlayerService.play(audio).subscribe({
        error: (err: any) => this.logAudioPlaybackSentryError('fill-in-blank-intervention', 'play-target-letter-audio-blank', audio, err),
      });
    }else if (index <= this.trialTargetTileList.length) {
      if (index > this.blankTile){
        this.audioPlayerService.play(tileList[index-1]['@audio']).subscribe({
          error: (err: any) => this.logAudioPlaybackSentryError('fill-in-blank-intervention', 'play-target-letter-audio', tileList[index-1]['@audio'], err),
        });
      }else{
        this.audioPlayerService.play(tileList[index]['@audio']).subscribe({
          error: (err: any) => this.logAudioPlaybackSentryError('fill-in-blank-intervention', 'play-target-letter-audio', tileList[index]['@audio'], err),
        });
      }
    }

    this.reusableTimer = window.setTimeout(() => {
      this.responsesDisabled = false;
    }, this.interventionTaskService.letterAudioDelay);
  }

  defaultAudioCompleteFunc() {
    this.displayTask(this.trialIndex);
  }

  // After first incorrect response and floating animation:
  // + Play target word audio
  // + Show the incorrect bottom row
  // + Remove any slashes from the target area
  // + Reset the target word (force angular to update)
  firstResponseIncorrectSequence() {
    let audio = this.trials[this.trialIndex].word['@audio'];
    this.audioPlayerService.play(audio).subscribe({
      error: (err: any) => this.logAudioPlaybackSentryError('fill-in-blank-intervention', 'first-response-incorrect', audio, err),
      complete: () => {
        this.showIncorrectBottom = true;
        this.showResponseAudioButtons = true;
        this.showTargetSlash = false;
        this.floatIncorrectUp = false;
        this.responsesDisabled = false;
        this.disableAVButtons = false;
        // Sometimes angular won't update until another event occurs if we don't force it here
        window.setTimeout(() => {
          this.resetTargetWord();
          this.changeDetector.markForCheck() ;
        }, 0);
      }
    });
  }

  // After second incorrect response and floating animation:
  // + Set the incorrect bottom row to the last selected response (force angular to update)
  // + Remove any slashes from target area
  // + Reset the target word and show the correct answer (force angular to update)
  // + Highlight the correct response tile and play the word audio
  secondResponseIncorrectSequence() {
    this.reusableTimer = window.setTimeout(() => {
      this.floatIncorrectUp = false;
      // NOTE: Weird formatting occurs if we don't force angular to update here
      // TODO: can probably get rid of the timeout here
      window.setTimeout(() => {
        this.incorrectBottom = this.targetWord.slice();
        this.showBottomRowSilentE = this.showTargetSilentE;
        this.changeDetector.markForCheck() ;
      }, 0);
      this.showTargetSlash = false;
      window.setTimeout(() => {
        this.resetTargetWord();
        this.correctResponseIndex = this.showCorrectAnswer(this.trials[this.trialIndex]);
        this.changeDetector.markForCheck() ;
      }, 0);
      this.highlightCorrectResponse = true;
      this.showIncorrectTop = true;
      let audio = this.trials[this.trialIndex].word['@audio'];
      this.audioPlayerService.play(audio).subscribe({
        error: (err: any) => this.logAudioPlaybackSentryError('fill-in-blank-intervention', 'second-response-incorrect', audio, err),
      });

      this.changeDetector.markForCheck() ;
    }, 0);
  }

  /**
    * Resets the target word back to it's original starting point with a blank tile and any silent-e's removed if necessary
    */
  resetTargetWord() {
    this.targetWord[this.blankTile] = '';
    // Check if target word currently ends in e and if the target word originally had a silent-e
    if (this.targetWord[this.targetWord.length - 1] === 'e' && this.targetSilentEIndex === null || this.showTargetSilentE === true) {
      // If the blank tile position includes a silent-e, remove that e when resetting the target word
      this.targetWord.pop();
      this.showTargetSilentE = false;
    }
  }

  /**
    * Shows the correct answer in the target area and returns the index of the correct answer in the response list
    */
  showCorrectAnswer(trial: InterventionTrial) {
    let arrayIsCorrect = trial['resp-list']!.resp;
    let correctResponseIndex;
    let letter = this.targetAnswer.replace("+e","");
    let totalOccurences = 0;
    let occurence = 0;
    let fromIndex = 0;

    if (this.targetAnswer.includes("+e")) {
      this.targetWord[this.blankTile] = this.targetAnswer.replace("+e","");
      this.targetWord.push('e');
      this.showTargetSilentE = true;
    }
    else {
        this.targetWord[this.blankTile] = this.targetAnswer;
    }


    arrayIsCorrect.forEach((element) => {
      let currLetter = element['#text'].replace("+e","")
      if (currLetter === letter){
        totalOccurences++;
        if (element['@type'] === "Correct"){
          occurence = totalOccurences;
        }
      }
    });

    correctResponseIndex = this.responseOptions.indexOf(this.targetAnswer.replace("+e",""));
    if (occurence == 2) { fromIndex = correctResponseIndex + 1; }
    correctResponseIndex = this.responseOptions.indexOf(this.targetAnswer.replace("+e",""), fromIndex);

    return correctResponseIndex;
  }

  // Toggles the hover on and off on the correct response tile
  toggleHover(index: number) {
    if (this.correctResponseIndex != null && this.correctResponseIndex == index) {
      if (this.highlightCorrectResponse) return true;
    }
    return false;
  }

  trialLoopAudioComplete() {
    // Allow user to submit a response to the trial
    this.reusableTimer = window.setTimeout(() => {
      this.responsesDisabled = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;
    }, 0);

    this.originalStartTime = this.timerService.startTimer();
    this.startTime = this.originalStartTime;
    let timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
    if (timerBarTaskSettings.timerBarEnabled) {
      let initialDelay = this.interventionTaskService.trialBarBaseDelay + timerBarTaskSettings.timerBarDelay;
      this.trialTimerBar.startTrialTimer(timerBarTaskSettings.timerBarSpeed, initialDelay);
    }
  }

  // Loop through the trial list and build the target word and responses for each item, and go to the next one after a click even updates the counter
  displayTask(newIndex: number) {
    this.incorrectFirstResponse = false;
    this.showIncorrectTop = false;
    this.showIncorrectBottom = false;
    this.showResponseAudioButtons = false;
    this.incorrectTop = [];
    this.incorrectBottom = [];
    this.trialTargetTileList = [];
    this.responseSilentEIndex = null;
    this.targetSilentEIndex = null;
    this.showTargetSilentE = false;
    this.correctResponseHasSilentE = false;

    // Remove any left over highlighting from a missed response in a previous trial
    if(this.responseOptions) {
      this.highlightCorrectResponse = false;
    }

    this.trialIndex = newIndex;

    // Flag to let me know if I need to add an e to the end of the target word because of the silent e or not
    this.targetWord = [];
    this.responseOptions = [];
    this.responseOptionsSilentE = [];

    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    // Figure out the blank tile position and make it blank in the target word
    this.blankTile = parseInt(this.trials[this.trialIndex].blankTilePos!) -1;

    // Check if the display tiles are in an array
    if (Array.isArray(this.trials[this.trialIndex].display.tile)) {
      this.trialTargetTileList = <Tile[]>this.trials[this.trialIndex].display.tile;
    }
    else {
      this.trialTargetTileList.push(this.trials[this.trialIndex].display.tile);
    }

    let addEToEnd = false;
    for (let character in this.trialTargetTileList) {
      let tileText: string;
      if (this.isUnit) {
        tileText = this.trialTargetTileList[character]['#text'];
      } else {
        tileText = this.trialTargetTileList[character];
      }
      // If a tile in the target word contains the silent e syntax, remove the +e and append that to the end, but keep the letter(s) before the +e
      if (tileText.includes('+')) {
        this.targetWord.push(tileText.substring(0, tileText.indexOf('+')));
        addEToEnd = true;
      }
      else {
        this.targetWord.push(tileText);
      }
    }
    if (addEToEnd) {
      this.targetSilentEIndex = this.targetWord.push('e') - 1;
    }

    if ((this.trials[this.trialIndex].correct!.resp as string).includes('+')) {
      // The silent e will be positioned at index = current length of the target word plus one for the blank tile
      this.targetSilentEIndex = this.targetWord.length + 1;
      this.correctResponseHasSilentE = true;
    }

    // Keep track of which tiles have the silent e display
    this.silentTiles = [];

    // Create a shallow copy of the response list
    let responseList = this.trials[this.trialIndex]['resp-list']!.resp.slice();

    // Reduce the response list if applicable
    responseList = this.interventionTaskService.reduceResponsesIfNecessary(responseList);

    // Shuffle the responses if the curriculum calls for it.
    responseList = this.interventionTaskService.shuffleResponses(responseList, this.trials[this.trialIndex]['resp-list']!['@randomResponses']);

    // Set the global trial response list to the now fully formed trimmed/shuffled list.
    this.trialResponseList = responseList;

    // Build the response lists
    for (let respIndex in responseList) {
      if (responseList[respIndex]['#text'].includes('+')) {
        this.silentTiles[respIndex] = true;
        this.responseOptions.push(responseList[respIndex]['#text'].substring(0, responseList[respIndex]['#text'].indexOf('+')));
        this.responseOptionsSilentE[respIndex] = true;
      }
      else {
        this.silentTiles[respIndex] = false;
        this.responseOptionsSilentE[respIndex] = false;
        this.responseOptions.push(responseList[respIndex]['#text']);
      }
    }

    this.responseOptions1 = this.responseOptions.slice(0,4);
    this.responseOptions2 = this.responseOptions.slice(4,8);

    // Need to do this splicing to insert the blank tile position where it is needed because the way the tiles are populated would leave us one tile
    // short because it does not account for the blank tile in the length of the target word
    this.targetWord.splice(this.blankTile, 0, ' ');
    this.showResponseAudioButtons = this.interventionTaskService.hasInitialAudioSupport();

    let audio = this.trials[this.trialIndex].word['@audio'];
    this.audioPlayerService.play(audio).subscribe({
      error: (err: any) => {
        this.logAudioPlaybackSentryError('fill-in-blank-intervention', 'display-task-audio', audio, err) ;
        this.trialLoopAudioComplete() ;
      },
      complete: () => this.trialLoopAudioComplete(),
    });
    this.changeDetector.markForCheck() ;
  }

  // Function for what happens when user clicks on response tile
  submitResponse(selectedResponse: number) {
    this.responsesDisabled = true;
    this.disableAVButtons = true;
    // Set the top incorrect row to have the same formatting and response as the bottom row
    // just in case a second response is missed and the first response is moved up to this row
    this.showTopRowSilentE = this.showBottomRowSilentE;
    this.incorrectTop = this.incorrectBottom.slice();
    this.showTargetSilentE = false;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    if (this.showIncorrectBottom){
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    }
    else{
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      this.secondResponseTime = 0;
    }

    // Put the data on this tile in the empty tile location in the target word area
    this.targetWord[this.blankTile] = this.responseOptions[selectedResponse];

    /*
        This is a small hack to determine if we need to append an e to the end of the target word because the user selected a silent e response
        Since we build a silentTiles list based on the responses, the indexes in silentTiles that are true are the response tiles that contain the silent e
        Knowing that, we can take a look at the silent tiles list at the index of the response the user selected (since the response tile has the same 'value' regard
        less of when we click it or parse it) and then if the value is true, just append an e to the target word
    */
    if (this.silentTiles[selectedResponse] === true) {
      if (this.correctResponseHasSilentE && this.targetWord[this.targetWord.length - 1] === 'e') {
        // Target word is already showing a silent e on the end so don't add an extra one
        this.responseSilentEIndex = this.targetWord.length - 1;
      }
      else {
        this.responseSilentEIndex = this.targetWord.push('e') - 1;
      }
      this.showTargetSilentE = true;
    }

    // Save the student's response
    let response;
    if (this.responseOptionsSilentE[selectedResponse]) {
      response = this.responseOptions[selectedResponse] + '+e' || "";
    }
    else {
      response = this.responseOptions[selectedResponse] || "";
    }
    this.targetAnswer = (this.trials[this.trialIndex].correct!.resp as string) || "";
    this.dataTracker.targetAnswer = this.targetAnswer;

    let isCorrect = (this.targetAnswer === response);
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.playSoundEffect(isCorrect);
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, response);

    if (isCorrect || !this.isUnit){
      // If the student missed their first try -- count the trial as incorrect
      let isTrialCorrect = isCorrect && !this.incorrectFirstResponse;
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      var responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      if (isTrialCorrect) this.numberOfCorrectTrials++;

      this.trialList.push(responseObject);

      // Perform expected animations and move on to the next trial
      this.taskService.answerTrial(isTrialCorrect) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.afterUpdate();
        }
      });
    }
    else
    {
      // 2 incorrect responses
      if (this.showIncorrectBottom) {
        this.interventionTaskService.trackResponseTrends(isCorrect);
        let responseObject = this.interventionTaskService.createTrialResponseObject(
          isCorrect,
          this.trialIndex,
          this.firstResponseTime,
          this.secondResponseTime,
          trialPoints,
          this.dataTracker,
          selectedResponse
        );
        this.trialList.push(responseObject);
        this.taskService.answerTrial(isCorrect) ;

        // Begin the floating animation
        this.floatIncorrectUp = true;
        this.reusableTimer = window.setTimeout(() => {
          this.showTargetSlash = true;
          this.changeDetector.markForCheck() ;

          // Play "The correct answer is..." followed by resetting the target word, playing word audio, highlighting, etc
          this.reusableTimer = window.setTimeout(() => {
            this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
              complete: () => this.secondResponseIncorrectSequence(),
              error: () => this.secondResponseIncorrectSequence()
            });
            // Perform expected animations and move on to the next trial
            this.reusableTimer = window.setTimeout(() => {
              this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
                complete: () => {
                  this.updateTotalPoints(responseObject.points);
                  this.afterUpdate();
                }
              });
            }, this.interventionTaskService.moveToNextTrialDelay);
          }, this.interventionTaskService.floatUpAnimationDelay);
        }, this.interventionTaskService.secondIncorrectDelay);
      }
      else
      {
        this.incorrectFirstResponse = true;
        this.floatIncorrectUp = true;
        this.showIncorrectBottom = false;
        // If the selected response had a silent-e, adjust formatting for the bottom incorrect row
        this.showBottomRowSilentE = this.showTargetSilentE;
        // Change the bottom word to the selected response
        this.incorrectBottom = this.targetWord.slice();

        // 1 incorrect response
        this.reusableTimer = window.setTimeout(() => {
          this.showTargetSlash = true;
          this.changeDetector.markForCheck() ;

          // Play "Please try again..." and then play the word audio
          this.reusableTimer = window.setTimeout(() => {
            this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
              complete: () => this.firstResponseIncorrectSequence(),
              error: () => this.firstResponseIncorrectSequence()
            });
          }, this.interventionTaskService.floatUpAnimationDelay);
        }, this.interventionTaskService.firstIncorrectDelay);

        this.originalStartTime = this.timerService.startTimer();
      }
    }
  }

  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  afterUpdate() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();

      let newIndex = this.trialIndex + 1;
      if (newIndex < this.trials.length) {
        this.displayTask(newIndex);
      } else {
        this.saveTaskData();
      }
    },this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints, this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error: (err: any) => {
        this.saveDataDialog.showSaveDataDialog() ;
        this.logSaveTaskSentryError('fill-in-blank-intervention', err) ;
      }
    });
  }
}
