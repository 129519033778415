export const THEMES = {
  'elementary': 1,
  'secondary': 2,
}

export interface WorldViewTheme {
  interventionBackgrounds: string[],
  preDiagnosticOverlay: string,
  midDiagnosticOverlay: string,
  postDiagnosticOverlay: string,
}

export interface SideMenuAchievementsTheme {
  background: string,
  levelTab: string,
  noLevelTab: string,
  closed: string,
  weeklyGoalProgressBar: string,
  weeklyGoalGoldStar: string,
  weeklyGoalGrayStar: string,
}

export interface SideMenuLeaderboardTheme {
  background: string,
  open: string,
  closed: string,
  standings: string[],
  currentStandings: string[],
}

export interface SideMenuAvatarTheme {
  background : string,
  open : string,
  closed : string,
  scrollLeft: string,
  scrollRight: string,
  carouselEmpty: string,
  carouselSelected: string,
}

export interface SideMenuTheme {
  achievements: SideMenuAchievementsTheme,
  leaderboard: SideMenuLeaderboardTheme,
  avatar: SideMenuAvatarTheme,
}

export interface TaskButtonTheme {
  taskSelectBtnLComplete: string,
  taskSelectBtnLUp: string,
  taskSelectBtnLGrey: string,
  taskSelectBtnRComplete: string,
  taskSelectBtnRUp: string,
  taskSelectBtnRGrey: string,
  taskSelectPassagesBtnLComplete: string,
  taskSelectPassagesBtnLUp: string,
  taskSelectPassagesBtnLGrey: string,
  taskSelectPassagesBtnRComplete: string,
  taskSelectPassagesBtnRUp: string,
  taskSelectPassagesBtnRGrey: string,
}

export interface InterventionTaskButtonTheme {
  taskSelectBtnComplete: string,
  taskSelectBtnUp: string,
  taskSelectBtnGrey: string,
  taskSelectCompareBtnComplete: string,
  taskSelectCompareBtnUp: string,
  taskSelectCompareBtnGrey: string,
  taskSelectPassagesBtnComplete: string,
  taskSelectPassagesBtnUp: string,
  taskSelectPassagesBtnGrey: string,
  subTaskSelectBtnComplete: string,
  subTaskSelectBtnUp: string,
  subTaskSelectBtnGrey: string,

}

export interface TaskTheme {
  xPositions: number[],
  yPositions: number[],
  backgroundImageA: string,
  taskBackground: string,
  taskSelectBackground: string,
  subtaskSelectBackground: string,
  subtaskSelectBackgroundComplete: string,
  subtaskSelectBtnComplete: string,
  subtaskSelectBtnGrey: string,
  subtaskSelectBtnUp: string,
  taskButtons: any,
  transitionDirection: string,
  taskBarColor: string,
  taskContainerColor: string,
  buttonColor: string,
}

export interface InterventionTaskTheme {
  xPositions: number[],
  yPositions: number[],
  backgroundImageA: string,
  backgroundImageB: string,
  taskBackground: string,
  taskSelectBackground: string,
  subtaskSelectBackground: string,
  subtaskSelectBackgroundComplete: string,
  subtaskSelectBtnComplete: string,
  subtaskSelectBtnGrey: string,
  subtaskSelectBtnUp: string,
  taskButtons: InterventionTaskButtonTheme,
  transitionDirection: string,
  totalTaskPointsBackgroundColor: string,
  totalTaskPointsBorderColor: string,
  taskBarColor: string,
  taskContainerColor: string,
  taskContainerDarkColor: string,
  buttonColor: string,
}

export interface ThemeProperties {
  number?: string,
  name?: string,
  worldView: WorldViewTheme,
  sideMenu: SideMenuTheme,
  levelSelect: TaskTheme | InterventionTaskTheme[],
}
