<div class="try-again-dialog-background" [ngStyle]="{'background': ' ' + taskBarColor + ' '}" *ngIf="ngshowTryAgainDialog"></div>
<div class="try-again-dialog-container" *ngIf="ngshowTryAgainDialog">
  <div class="try-again-dialog-top-container">
      <label class="try-again-dialog-text font-42">Good Try!</label>
      <label class="try-again-dialog-subtext font-27">You'll need to complete this task again.</label>
  </div>
  <div class="try-again-dialog-bottom-container">
    <button class="try-again-dialog-btn" (click)="tryAgainBtnDialogClick()" [disabled]="dialogButtonsDisabled">
        <div class='try-again-icon'></div>
        <span class='try-again-text font-20'>Try Again</span>
    </button>
    <button class="try-later-dialog-btn" (click)="tryLaterBtnDialogClick()" [disabled]="dialogButtonsDisabled">
        <span class='try-later-text font-20'>Try Later</span>
        <div class='try-later-icon'></div>
    </button>
  </div>
</div>