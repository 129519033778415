<div class="dialog-background" *ngIf="ngshowSaveDataDialog"></div>
<div class="dialog-container" *ngIf="ngshowSaveDataDialog">
  <div class="save-data-dialog-top-container">
    <label class="save-data-dialog-text">Unable to save your task information. Please check your internet connection and select the Save button to try again.</label>
  </div>
  <div class="save-data-dialog-bottom-container">
    <button class="save-data-dialog-btn" (click)="logoutBtnDialogClick()" [disabled]="dialogButtonsDisabled">
        <span class='btn-login-text'>Logout</span>
    </button>
    <button class="save-data-dialog-btn" (click)="saveBtnDialogClick()" [disabled]="dialogButtonsDisabled">
        <span class='btn-login-text'>Save</span>
    </button>
  </div>
</div>
