import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WorldNavigationTimerService } from 'src/app/core/services/world-nav-timer.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { SessionTimerService } from 'src/app/core/services/session-timer.service';
import { LoginService } from 'src/app/login/login.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'student-footer',
  templateUrl: './student-footer.component.html',
})
export class StudentFooterComponent implements OnInit, OnDestroy {
  @ViewChild('timerCanvas', { static: true }) timerCanvas!: ElementRef<HTMLCanvasElement>;
  
  @Input('totalSeconds') totalSeconds: number = 30;
  @Input('warningSeconds') warningSeconds: number = 10;
  @Input('timerBackgroundImage') timerBackgroundImage: string | null = null;
  
  showCountdown: boolean = false;
  showLogoutConfirmation: boolean = false;

  secondsLeft: number = this.totalSeconds;

  private animationInterval?: number;
  private logoutSubscription?: Subscription;
  // precision variable can be modified to make the dial more discrete (-) or continuous (+)
  private precision: number = 5;
  private incrementsDuration = Math.pow(0.5, this.precision) * 1000;
  private increments: number = Math.pow(2, this.precision) * this.warningSeconds;
  private degPerIncrement: number = 360/this.increments;
  private currentIncrement: number = 0;
  private worldNavWarnSubscription?: Subscription;
  private worldNavExpiredSubscription?: Subscription;

  constructor(
    private loginService: LoginService,
    private sessionTimerService: SessionTimerService,
    private studentDataService: StudentDataService,
    private navigationTimerService: WorldNavigationTimerService,
  ) { }

  ngOnInit(): void {
    this.increments = Math.pow(2, this.precision) * this.warningSeconds;
    this.degPerIncrement = 360/this.increments;

    // Subscribes to the warning countdown for time being up
    //  Timer never starts and this is never fired off if
    //  the user is a demo user.
    this.worldNavWarnSubscription = this.navigationTimerService.warn.subscribe(warnTime => {
      this.showCountdown = true;
      this.secondsLeft = warnTime;
      let seconds = this.warningSeconds - warnTime;
      this.currentIncrement = seconds * this.incrementsDuration;
      
      //
      // Clears the animation and restarts it each time a new
      // warning time value comes in.
      //
      // This may not be the best implementation and we might
      // just want to set  it at the beginning and not do it
      // again. But the thinking is that it should keep it more
      // in sync this way.
      if (this.animationInterval)
      {
        window.clearInterval(this.animationInterval);
      }
      this.animationInterval = window.setInterval(() => {
        this.renderTime();
        this.currentIncrement++;
      }, this.incrementsDuration);
    });
    
    this.worldNavExpiredSubscription = this.navigationTimerService.expired.subscribe(timerExpired => {
      if (this.animationInterval)
      {
        window.clearInterval(this.animationInterval);
      }
    })
  }

  // Render the timer canvas element based on the
  //  amount of the time that has passed.
  renderTime() {
    let endAngle = -0.5 * Math.PI - (this.degPerIncrement * Math.PI/180 * this.currentIncrement);
    let canvas = this.timerCanvas?.nativeElement;
    let ctx = this.timerCanvas?.nativeElement?.getContext("2d");
    
    if (ctx != null)
    {
      let radius = Math.min(canvas.width, canvas.height) * .90/2 - ctx.lineWidth;
      ctx.strokeStyle = '#4990CE';
      ctx.lineWidth = 40;
  
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (this.currentIncrement < this.increments + 1)
      {
        ctx.beginPath();
        ctx.arc(canvas.width/2, canvas.height/2, radius, 1.5 * Math.PI, endAngle, false);
        ctx.stroke();
      }
    }
  }

  checkForCountdownClock() {
    if (!this.studentDataService.isUserSuperUser())
    {
      this.startCountdown(this.totalSeconds, this.warningSeconds);
    }
  }
  
  startCountdown(totalSeconds: number, warningSeconds: number) {
    this.currentIncrement = 0;
    this.navigationTimerService.start(totalSeconds, warningSeconds);
  }

  cancelCountdown() {
    this.showCountdown = false;
    this.navigationTimerService.clear();
    if (this.animationInterval)
    {
      window.clearInterval(this.animationInterval);
      this.animationInterval = undefined;
    }
  }

  logoutOrShowConfirmationDialogue() {
    this.cancelCountdown();
    if (!this.sessionTimerService.isSessionTimerRunning())
    {
      this.logout();
    }
    else
    {
      this.toggleConfirmation();
    }
  }

  toggleConfirmation() {
    this.showLogoutConfirmation = !this.showLogoutConfirmation;
    if (!this.showLogoutConfirmation && !this.studentDataService.isUserSuperUser())
    {
      // After confirmation, the total seconds should only be as
      //  long as the number of seconds that are given as a warning.
      this.startCountdown(this.warningSeconds, this.warningSeconds);
    }
  }

  logout() {
    this.logoutSubscription = this.loginService.studentLogout().pipe(take(1)).subscribe();
  }
  
  ngOnDestroy() {
    this.worldNavWarnSubscription?.unsubscribe();
    this.worldNavExpiredSubscription?.unsubscribe();
    this.logoutSubscription?.unsubscribe();
    this.cancelCountdown();
  }
}
