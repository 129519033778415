<div class="viewport-1" [ngStyle]="{'background-image':'url(' + getTaskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

  <div
    class="task-screen-element find-the-syllables-container"
    [ngStyle]="{ 'background-image': ' ' + getTaskContainerColor() + ' ' }"
    [ngClass]="(animationComplete) ? [ 'taskShow' ] : [ 'taskHide' ]">
    <div class="testType font-18">{{ getTestType() }}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button [ngClass]="(hideTimer) ? 'replayVideo-no-timerbar' : 'replayVideo'" (click)="replayVideo()" [disabled]="disableAVButtons"></button>
    </div>

    <div [ngClass]="{ 'find-number-syllables-target-area': !isUnit, 'find-number-syllables-target-area-instructional': isUnit }">
      <button class="speaker" (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons" [hidden]="!isSpeakerVisible()"></button>
      <div [ngClass]="(isUnit) ? [ 'find-number-syllables-target-text-instructional font-50' ] : [ 'find-number-syllables-target-text font-42' ]">
        <div [ngClass]="{ 'masked-syllable-target-word': isUnit }" [hidden]="!shouldMask">######</div>
        <!-- If pre/post test: show the target word as normal... no need to worry about syllable parts -->
        <div *ngIf="!isUnit" [hidden]="shouldMask">{{ targetWord }}</div>

        <!-- This initial state is necessary to keep the syllable parts formatted as closely together as possible (the buttons space out the syllable
             container and can lead to very obvious syllable breaks before the student even attempts a response) -->
        <div *ngIf="isUnit && !firstResponseIncorrect">
          <ng-container *ngFor="let syllablePart of targetSyllables; let idx = index">
          <div class="syllable-container-initial">
            <div class="syllable-text syllable-{{ syllablePart.syllableNum }}" [ngClass]="{'colored': showSupportAudioAndHighlights, 'underline': syllablePart.underline}" [hidden]="shouldMask">
              {{ syllablePart.syllable }}
            </div>
          </div>
          </ng-container>
        </div>

        <!-- Once the student has missed a response, add in the support audio btns. Depending on the length of the syllable this can lead to some increased
             spacing between letters, but it at least fits the purpose of making the syllables more obvious to the student -->
        <div *ngIf="isUnit && firstResponseIncorrect" class="syllable-container">
          <ng-container *ngFor="let syllablePart of targetSyllables; let idx = index">
          <div class="syllable-container">
            <button class="syllable-audio-btn-hidden" [disabled]="disableAVButtons" [hidden]="showSupportAudioAndHighlights"></button>
            <button class="syllable-audio-btn" [disabled]="disableAVButtons" [hidden]="!showSupportAudioAndHighlights" (click)="requestSyllableHelp(idx)"></button>
            <div class="syllable-text syllable-{{ syllablePart.syllableNum }}" [ngClass]="{ 'colored': showSupportAudioAndHighlights, 'underline': syllablePart.underline }" [hidden]="shouldMask">
              {{ syllablePart.syllable }}
            </div>
          </div>
          </ng-container>
        </div>
      </div>

      <button
        [hidden]="unmaskedTask"
        [disabled]="disableAgainButton"
        [ngClass]="{ 'again-button-grayed' : disableAgainButton, 'again-button' : !disableAgainButton, 'toggle-again-on' : toggleAgainButton, 'again-button-hidden': hideAgainButton }"
        (click)="displayTrialAgain()"></button>
    </div>

    <div class="full-response-row">
      <button
        [hidden]="auditoryTask || unmaskedTask"
        [disabled]="disableNextButton"
        [ngClass]="{ 'next-button-grayed' : disableNextButton, 'next-button' : !disableNextButton, 'toggle-next-on' : toggleNextButton, 'next-button-hidden': hideNextButton }"
        (click)="displayTrial()"></button>
    </div>

    <div class="syllable-response-row">
      <div class="button-div" *ngFor="let response of responseOptions; let idx = index">
        <button
          [disabled]="disableResponseButtons"
          [ngClass]="{ 'hover': response.highlight, 'syllable-response-correct-grayed': (disableResponseButtons && !isUnit), 'syllable-response-correct': (!disableResponseButtons || isUnit) }"
          [ngStyle]="{'border-color':' ' + getHighlightColor() + ' ', 'background-color': getButtonColor() + ' ', 'color': getTaskBarColor() + ' '}"
          (click)="submitResponse(response) ; removeHoverClass($event)"
          (touchstart)="addHoverClass($event)"
          (touchend)="removeHoverClass($event)"
          (mousemove)="addHoverClass($event)"
          (mouseenter)="addHoverClass($event)"
          (mouseleave)="removeHoverClass($event)">

          {{ response.syllables }}

        </button>
      </div>
    </div>
  </div>

  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
</div>
