import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import * as Sentry from "@sentry/angular";

import { ApplicationStateService } from '../core/services/application-state.service';
import { SSOCredentials } from '../core/models/credentials.model';
import { environment } from '../../environments/environment';
import { StudentData } from '../core/models/student-data.model';
import { StudentDataService } from '../core/services/student-data.service';
import { SessionTimerService } from '../core/services/session-timer.service';
import { Observable, defer, of } from 'rxjs';
import { Router } from '@angular/router';
import { Device, DeviceInfo } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private applicationStateService: ApplicationStateService,
    private sessionTimerService: SessionTimerService,
    private studentDataService: StudentDataService,
  ) { }


  studentLogin(username: string, password: string, ssoInfo?: SSOCredentials) {
    // Clear the session storage for the new session
    this.applicationStateService.clear();
    this.applicationStateService.setUsername(username);
    this.applicationStateService.setPassword(password);

    if (ssoInfo) {
      this.applicationStateService.setSSOInfo(ssoInfo);
    }

    // Convert the promise returned from getDeviceInfo() to an observable with defer()
    return defer(() => this.getDeviceInfo()).pipe(
      switchMap(deviceInfo => {
        let reqOptions = {
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': "*",
            'X-FIL-Version': environment.versionNumber,
            ...(deviceInfo ? { 'FIL-Device-Info': JSON.stringify(deviceInfo) } : {}),
          },
        };

        // Get login data if authenticated, create student session
        return this.httpClient.get(environment.EC2URL + 'v3/student/login', reqOptions)
          .pipe(
            map((res: any) => {
              let loginData = {
                data: res,
                isLoggedIn: true,
              };
              this.onLoginSuccess(res);

              return loginData;
            }),
          );
      })
    );
  }

  // NOTE: If we move to serviceworkers we may just want to pull it out of
  //  here into the service worker.
  checkForUpdate() {
    let now = Date.now();
    let checkForUpdateTime = this.applicationStateService.getCheckForUpdateTime();

    if (checkForUpdateTime == null || now > checkForUpdateTime)
    {
      let nextUpdateTime = new Date(now + 3600000).valueOf();
      this.applicationStateService.setCheckForUpdateTime(nextUpdateTime);

      // Create a request
      let reqOptions = {
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin' : "*",
          'X-FIL-Version' : environment.versionNumber,
        },
      };

      return this.httpClient.get(`${environment.EC2URL}v3/student/checkForUpdate`, reqOptions).pipe(
        switchMap((res) => {
          // No update needed with successful response
          return of(false);
        }),
        catchError((err: any) => {
          // Update needed with error code 426
          return of(err?.status === 426);
        })
      )
    }
    else
    {
      // Not yet time to check, no update needed.
      return of(false);
    }
  }

  studentUsageAlert() {
    // Create a request
    let reqOptions = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin' : "*",
        'X-FIL-Version' : environment.versionNumber,
      },
    };

    let studentData = this.studentDataService.getStudentData();

    return this.httpClient.post(`${environment.EC2URL}v3/student/excessivelogin`, studentData, reqOptions)
  }

  studentLogout(): Observable<null> {
    // If demo user, there is nothing to save so delete all session storage and go
    //   to the login screen.
    if (this.studentDataService.isDemoUser())
    {
      this.demoLogout();
      return of(null);
    }

    // Create request
    let studentData = this.studentDataService.getStudentData();
    let reqOptions = {
      withCredentials: true,
      headers: {
          'Access-Control-Allow-Origin' : "*"
      },
    };

    return this.httpClient.post<null>(environment.EC2URL + 'v3/student/logout', studentData, reqOptions).pipe(
      finalize(() => {
        this.demoLogout();
      })
    );
  }

  demoLogout() {
    this.applicationStateService.clear();
    this.sessionTimerService.cancelTimers();
    this.router.navigateByUrl('/login');
  }

  /**
   * Gets a student's completion status based on the studentData model
   */
  studentCompletionStatus(studentData: StudentData | null): { status: 'incomplete' | 'complete'; message: string } {
    const completed = !this.studentDataService.isDemoUser() && ((studentData?.studentAssessment.isComplete) || this.studentDataService.areAllTasksComplete());
    if (!completed) {
      return {
        status: 'incomplete',
        message: '',
      };
    }

    const completedScreener = this.studentDataService.isScreenerDiagnostic();
    const completionMessage = completedScreener
      // Completed screener:
      ? 'Congratulations! You have completed the WordFlight Screener. Please see your teacher about moving you into the WordFlight System.'
      // Completed system:
      : 'Congratulations! You have completed WordFlight.';

    return {
      status: 'complete',
      message: completionMessage,
    };
  }

  private async getDeviceInfo(): Promise<DeviceInfo | null> {
    try {
      const deviceInfo = await Device.getInfo();
      return deviceInfo;
    } catch (err) {
      console.error(err);
      Sentry.captureException(err, {
        tags: {
          section: 'login-service',
          action: 'get-device-info',
        }
      });
      return null;
    }
  }

  private onLoginSuccess(studentData: StudentData) {
    this.studentDataService.setStudentData(studentData);
    this.studentDataService.initInterventionTaskCompletionCounts(studentData) ;
    this.studentDataService.setIsLoggedIn(true);
    if (this.studentDataService.isDemoUser())
    {
      this.studentDataService.setDemoUserSubscription((studentData.userRole.toUpperCase() === 'ROLE_TEACHER' || studentData.userRole.toUpperCase() === 'ROLE_TEACHER_CURRICULUM' || studentData.userRole.toUpperCase() === 'ROLE_TEACHER_REVIEW') ? studentData.subscriptionType : 2);
    }
    else
    {
      this.studentDataService.setDemoUserSubscription(0);
    }

    let sessionLength = 0;

    if (!this.studentDataService.isDemoUser())
    {
      // Screener students don't have session timers, but do have
      // inactivity timers.
      if (this.studentDataService.isScreenerDiagnostic())
      {
        studentData.studentAssessment.sessionLength = 0;
        this.studentDataService.setStudentData(studentData);
      }

      sessionLength = studentData.studentAssessment.sessionLength;
    }

    // Start the session timers. Demo users default to session length equal to 0 but
    // the student data response does not have a studentAssessment until after they
    // choose the curriculum so we can't count on getting the session length from that.
    this.sessionTimerService.startTimers(sessionLength);

    // Set our user info for Sentry tracking
    Sentry.setUser({
      username: studentData.username,
      role: studentData.userRole,
      extraSupport: studentData.extraSupportNeeded,
    }) ;
  }

}
