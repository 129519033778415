<div class="video-background" [hidden]="!viewInstructionalVideo"></div>
<div class="videogular-container" [hidden]="!viewInstructionalVideo">
  <vg-player
    class="video-container"
    (onPlayerReady)="onPlayerReady($event)"
    (dblclick)="skipVideo($event)"
    *ngIf="videoSource"
  >
    <video
      [src]="videoSource"
      [vgMedia]="$any(videoPlayerElement)"
      #videoPlayerElement
      id="singleVideo"
      preload="auto"
      playsinline
    >
    </video>
  </vg-player>
</div>
