import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { WorldComponent } from './world/world.component';
import { LevelSelectComponent } from './level-select/level-select.component' ;
import { TaskSelectComponent } from './task-select/task-select.component' ;
import { FormatStringPipe } from '../core/pipes/format-string.pipe';
import { SubtaskSelectComponent } from './subtask-select/subtask-select.component' ;

@NgModule({
  declarations: [
    WorldComponent,
    LevelSelectComponent,
    TaskSelectComponent,
    FormatStringPipe,
    SubtaskSelectComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
  ],
})
export class WorldNavigationModule { }