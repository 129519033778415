export class ResponseTile {
  public index: number;
  public text: string;
  public highlight: boolean;
  public isCorrect: boolean;
  public wordFamily: string;

  constructor(index: number, text: string, highlight: boolean, isCorrect: boolean, wordFamily: string) {
    this.index = index;
    this.text = text;
    this.highlight = highlight;
    this.isCorrect = isCorrect;
    this.wordFamily = wordFamily;
  }
}