import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private BETA_TASK_PREFIX = [ 'CTM' ];
  private BETA_SPEECH_TASK_PREFIX = [ 'RTW', 'RTS', 'PAW-', 'PAS-' ] ;
  private trialAnswer: Subject<boolean> = new Subject() ;
  trialAnswer$: Observable<boolean> = this.trialAnswer.asObservable() ;

  constructor() { }

  answerTrial(isCorrect: boolean) {
    this.trialAnswer.next(isCorrect) ;
  }

  isBetaTask(taskId: string | undefined) {
    return (!taskId) ? false : this.BETA_TASK_PREFIX.some((prefix) => taskId.toUpperCase().startsWith(`${prefix}`)) ;
  }

  isBetaSpeechTask(taskId: string | undefined) {
    return (!taskId) ? false : this.BETA_SPEECH_TASK_PREFIX.some((prefix) => taskId.toUpperCase().startsWith(`${prefix}`)) ;
  }
}
