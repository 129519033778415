<button class="side-menu-toggle-btn clear-btn"
    [disabled]="disableSideButton"
    [style.background-image]="'url(' + sideMenuBtnImage + ')'"
    (click)="toggleSideMenu()"
    (touchstart)="highlightButton()"
    (touchend)="unhighlightButton()"
    (mousemove)="highlightButton()"
    (mouseenter)="highlightButton()"
    (mouseleave)="unhighlightButton()"
>
</button>
<div [hidden]="!isSideMenuOpen">
  <div class="side-menu-container"
    [style.background-image]="'url(' + sideMenuTheme.achievements.background + ')'"
    [hidden]="currentTab !== 1"
  >
    <button class="clear-btn leaderboard-unused"
      [style.background-image]="'url(' + sideMenuTheme.leaderboard.closed + ')'"
      *ngIf="showLeaderboard"
      (click)="openLeaderboard()"
    >
    </button>
    <button class="clear-btn avatar-unused"
      [style.background-image]="'url(' + sideMenuTheme.avatar.closed + ')'"
      *ngIf="showAvatarMenu"
      (click)="openAvatarMenu()"
    >
      <img class="current-avatar-tab-image" [src]="usersCurrentAvatar.avatar">
    </button>
    <div class="current-tab" [style.background-image]="'url(' + ((showLevel) ? sideMenuTheme.achievements.levelTab : sideMenuTheme.achievements.noLevelTab) + ')'">
      <p class="weekly-time font-24">{{ weeklyTime }}</p>
      <p class="current-unit-sidebar font-24">{{ currentUnit }}</p>
      <p class="daily-points font-26">{{ dailyPoints | number }}</p>
      <p class="weekly-points font-20">{{ weeklyPoints | number }}</p>
      <p class="total-points font-20">{{ totalPoints | number }}</p>
      <div [hidden]="!showLevel" class="level-progress-container" [style.background-image]="'url(' + sideMenuTheme.achievements.weeklyGoalProgressBar + ')'">
        <div style="height: 100%; width: 91%; margin-left: 7%;">
          <!-- Create an array with empty values with a length that is defined by the number stored in weeklyGoal -->
          <div class="level-goal" *ngFor="let level of [].constructor(weeklyGoal); index as i;">
            <div class="level-star" *ngIf="i < weeklyGoalProgress" [style.background-image]="'url(' + sideMenuTheme.achievements.weeklyGoalGoldStar + ')'"></div>
            <div class="level-star" *ngIf="i >= weeklyGoalProgress" [style.background-image]="'url(' + sideMenuTheme.achievements.weeklyGoalGrayStar + ')'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="side-menu-container"
    [style.background-image]="'url(' + sideMenuTheme.leaderboard.background + ')'"
    [hidden]="currentTab !== 2">
    <button class="clear-btn achievement-unused"
        [style.background-image]="'url(' + sideMenuTheme.achievements.closed + ')'"
        (click)="openAchievements()">
    </button>
    <button class="clear-btn avatar-unused"
        [style.background-image]="'url(' + sideMenuTheme.avatar.closed + ')'"
        *ngIf="showAvatarMenu"
        (click)="openAvatarMenu()">
        <img class="current-avatar-tab-image" [src]="usersCurrentAvatar.avatar">
    </button>
    <div class="current-tab" [style.background-image]="'url(' + sideMenuTheme.leaderboard.open + ')'">
      <p class="team-leaderboard-name font-25">{{ usersTeam?.teamName }}</p>
      <p class="team-leaderboard-points font-20">{{ studentsTeamPoints?.dailyPoints | number }}</p>
      <div *ngIf="isSideMenuOpen" class="team-standing-container">
        <div *ngFor="let teamStanding of teamStandingList"
          class="team-standing"
          [ngStyle]="teamStanding.background"
          [ngClass]="teamStanding.animation">
          <p [hidden]="hideScore(teamStanding.index)"
            class="team-standing-name font-13">{{ teamStanding.teamName }}</p>
          <p [hidden]="hideScore(teamStanding.index)"
            class="team-standing-points font-15">{{ teamStanding.weeklyPoints | number }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="side-menu-container"
    [style.background-image]="'url(' + sideMenuTheme.avatar.background + ')'"
    [hidden]="currentTab !== 3">
    <button class="clear-btn achievement-unused"
      [style.background-image]="'url(' + sideMenuTheme.achievements.closed + ')'"
      (click)="openAchievements()">
    </button>
    <button class="clear-btn leaderboard-unused"
      [style.background-image]="'url(' + sideMenuTheme.leaderboard.closed + ')'"
      *ngIf="showLeaderboard"
      (click)="openLeaderboard()">
    </button>
    <div class="current-tab" [style.background-image]="'url(' + sideMenuTheme.avatar.open + ')'">
      <img class="tab-open-image" [src]="usersCurrentAvatar.avatar">
      <button class="clear-btn select-avatar-btn" (click)="selectAvatar()"></button>
      <!-- Ternary operator on the avatar item path so it doesn't fetch the null asset if it isn't set -->
      <div
        class="avatar-preview"
        [style.background-image]="previewedAvatar?.avatarItem ? 'url(' + previewedAvatar!.avatarItem!.avatar + ')' : ''"
      ></div>
      <div class="avatar-carousel-container">
        <button class="clear-btn" 
        [ngClass]="{'back-avatar-btn-disabled': disableScrollLeft, 'back-avatar-btn': !disableScrollLeft}" 
        (click)="scrollLeft()" 
        [disabled]="disableScrollLeft">
        </button>
        <div class="avatar-carousel">
          <ng-container *ngFor="let item of carouselItemList; index as i">
            <button class="clear-btn empty-carousel-item" [ngClass]="{'selected-carousel-item':item.selected}"
              *ngIf="item.visible"
              [disabled]="item.disabled"
              (click)="previewAvatar(i)">
              <div
                *ngIf="item.avatarItem != null"
                class="avatar-carousel-item"
                [style.background-image]="'url(' + item.avatarItem.avatar + ')'"
              >
              </div>
            </button>
          </ng-container>
        </div>
        <button class="clear-btn" 
        [ngClass]="{'next-avatar-btn-disabled': disableScrollRight, 'next-avatar-btn': !disableScrollRight}" 
        (click)="scrollRight()" 
        [disabled]="disableScrollRight">
        </button>
      </div>
    </div>
    <p [hidden]="!isSideMenuOpen" class="school-name font-11">{{ school }}</p>
  </div>
</div>
