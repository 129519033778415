import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { DiagnosticTrial } from 'src/app/core/models/task.model';
import { AssetPreloaderService } from 'src/app/core/services/asset-preloader.service';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { DiagnosticTaskComponent } from '../diagnostic-task.component';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'app-change-the-blank',
  templateUrl: './change-the-blank.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeTheBlankComponent extends DiagnosticTaskComponent implements OnInit, AfterViewInit {
  trials: DiagnosticTrial[] = this.task.trial;

  // Task specific variables
  response: string = '';
  letter: string = '';
  targetPositionVowel: boolean = false;
  changeAudio: string;
  toMakeAudio: string;
  targetWord: string[] = [];
  blankTile: number = 0;
  addEToEnd: boolean = false;
  removeTargetSilentE: boolean = false;
  keepTargetFromMovingLeft: boolean = false;
  initialWord: string[] = [];
  responseOptions: string[] = [];
  responseOptions1: string[] = [];
  responseOptions2: string[] = [];
  responseOptionsSilentE: boolean[] = [];
  silentTiles: boolean[] = [];

  constructor(
    public router: Router,
    public applicationStateService: ApplicationStateService,
    public studentDataService: StudentDataService,
    public audioPlayerService: AudioPlayerService,
    public timerService: TimerService,
    public shuffleService: ShuffleService,
    public assetPreloaderService: AssetPreloaderService,
    public changeDetector: ChangeDetectorRef,
    private taskService: TaskService,
  ) {
    super(router, applicationStateService, studentDataService, audioPlayerService, timerService, shuffleService, assetPreloaderService);
    // Initialize variables
    this.changeAudio = 'Audio/Help/' + this.task.trialaudiochange + '.mp3';
    this.toMakeAudio = 'Audio/Help/' + this.task.trialaudiotomake + '.mp3';
  }

  ngOnInit(): void {
    // If random trials is set to true on the task object, shuffle the trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe((complete) => {
      if (complete) {
        // set this to tell the trial-counter that animation is complete
        this.animationComplete = true;
        this.displayTaskContainerElements()
        .pipe(first(),
          concatMap(() => {
            if (!this.studentDataService.isDemoUser() && !this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id)) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo()
            }
            else {
              return of({});
            }
          })
        )
        .subscribe({
          complete: () => this.defaultAudioCompleteFunc(),
          error: () => this.defaultAudioCompleteFunc(),
        });
      }
    });
  }

  defaultAudioCompleteFunc = () => {
    this.reusableTimer = window.setTimeout(() => {
      this.displayTask();
    }, 100);
  }

  removeLetterToChange() {
    this.reusableTimer = window.setTimeout(() => {
      this.targetWord[this.blankTile] = '';
      if (this.addEToEnd && this.trials[this.trialIndex]['resp-list']['@position'] === 'vowel' && this.targetWord[this.targetWord.length - 1] === 'e') {
        // If we added an e to the end of the word, remove it when we remove the blank tile if the position property is set to vowel
        this.targetWord.pop();
        this.removeTargetSilentE = true;
        this.targetPositionVowel = true;
      }
      this.disableResponseButtons = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;

      // Start timer after the responses are able to be clicked
      this.startTime = this.timerService.startTimer();
    }, 0);
  }

  playTargetAudio() {
    this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe({
      complete: () => this.removeLetterToChange(),
      error: () => this.removeLetterToChange()
    });
  }

  playToMakeAudio() {
    this.audioPlayerService.play(this.toMakeAudio).subscribe({
      complete: () => this.playTargetAudio(),
      error: () => this.playTargetAudio()
    });
  }

  playInitialAudio() {
    let audioFile = this.trials[this.trialIndex]['initial-audio'] || '';
    this.audioPlayerService.play(audioFile).subscribe({
      complete: () => this.playToMakeAudio(),
      error: () => this.playToMakeAudio()}
    );
  }

  playChangeAudio() {
    this.audioPlayerService.play(this.changeAudio).subscribe({
      complete: () => this.playInitialAudio(),
      error: () => this.playInitialAudio()
    });
  }

  playTrialAudio() {
    this.playChangeAudio();
  }

  // Loop through the trial list and build the target word and responses for each item, and go to the next one after a click even updates the counter
  displayTask() {
    this.playTrialAudio();

    // Flag to let me know if I need to add an e to the end of the target word because of the silent e or not
    this.addEToEnd = false;
    this.keepTargetFromMovingLeft = false;
    this.targetWord = [];
    this.initialWord = [];
    this.responseOptions = [];
    this.responseOptionsSilentE = [];
    this.removeTargetSilentE = false;
    this.targetPositionVowel = false;

    // Shuffle the response list if the randomize flag is set
    if (this.task.randomTrials) {
      this.shuffleService.shuffleArray(this.trials[this.trialIndex]['resp-list'].resp);
    }

    for (let character in this.trials[this.trialIndex].display.tile) {
      // If a tile in the target word contains the silent e syntax, remove the +e and append that to the end, but keep the letter(s) before the +e
      // Then set a flag to tell us to append an e to the end of the target word
      if (this.trials[this.trialIndex].display.tile[character].includes('+')) {
        this.addEToEnd = true;
        this.targetWord.push(this.trials[this.trialIndex].display.tile[character].substring(0, this.trials[this.trialIndex].display.tile[character].indexOf('+')));
        this.initialWord.push(this.trials[this.trialIndex].display.tile[character].substring(0, this.trials[this.trialIndex].display.tile[character].indexOf('+')));
      }
      else {
        this.targetWord.push(this.trials[this.trialIndex].display.tile[character]);
        this.initialWord.push(this.trials[this.trialIndex].display.tile[character]);
      }
    }
    if (this.addEToEnd) {
      this.targetWord.push('e');
      this.initialWord.push('e');
    }


    // Keep track of which tiles have the silent e display
    this.silentTiles = [];

    // Build the response lists
    for (let responses in this.trials[this.trialIndex]['resp-list'].resp) {
      if (this.trials[this.trialIndex]['resp-list'].resp[responses]['#text'].includes('+')) {
        this.silentTiles[responses] = true;
        //var responseToShow = trials[$scope.trialIndex]['resp-list'].resp[responses]['#text'].substring(0, trials[$scope.trialIndex]['resp-list'].resp[responses]['#text'].indexOf('+'));
        this.responseOptions.push(this.trials[this.trialIndex]['resp-list'].resp[responses]['#text'].substring(0, this.trials[this.trialIndex]['resp-list'].resp[responses]['#text'].indexOf('+')));
        this.responseOptionsSilentE[responses] = true;
      }
      else {
        this.silentTiles[responses] = false;
        this.responseOptionsSilentE[responses] = false;
        this.responseOptions.push(this.trials[this.trialIndex]['resp-list'].resp[responses]['#text']);
      }
    }
    this.responseOptions1 = this.responseOptions.slice(0, 4);
    this.responseOptions2 = this.responseOptions.slice(4, 8);

    // Figure out the blank tile position and make it blank in the target word
    this.blankTile = parseInt(this.trials[this.trialIndex].blankTilePos!) - 1;
    this.changeDetector.markForCheck() ;
  }

  // Function for what happens when user clicks on response tile
  submitAnswer(selectedResponse: number) {

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    this.keepTargetFromMovingLeft = false;

    this.disableResponseButtons = true;
    this.disableAVButtons = true;

    // Play neutral audio sound after each response tile click
    this.audioPlayerService.play('Audio/Help/SNDneutral.mp3').subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });

    // Put the data on this tile in the empty tile location in the target word area
    this.targetWord[this.blankTile] = this.responseOptions[selectedResponse];

    /*
        This is a small hack to determine if we need to append an e to the end of the target word because the user selected a silent e response
        Since we build a silentTiles list based on the responses, the indexes in silentTiles that are true are the response tiles that contain the silent e
        Knowing that, we can take a look at the silent tiles list at the index of the response the user selected (since the response tile has the same 'value' regard
        less of when we click it or parse it) and then if the value is true, just append an e to the target word
    */

    if (this.silentTiles[selectedResponse] === true) {
      if (this.targetPositionVowel === true) {
        this.removeTargetSilentE = false;
        this.keepTargetFromMovingLeft = false;
        this.targetWord.push('e');
    }
      else {
        this.keepTargetFromMovingLeft = true;
        this.removeTargetSilentE = false;
        this.targetWord.push('e');
      }
    }
    this.changeDetector.markForCheck() ;

    let selectedResponseText: string;
    // If the user selects a response with a silent e, we want to send the '+e' back as well so we know if they were right or wrong
    if (this.responseOptionsSilentE[selectedResponse]) {
      selectedResponseText = this.responseOptions[selectedResponse] + '+e' || "";
    }
    else {
      selectedResponseText = this.responseOptions[selectedResponse] || "";
    }
    let foil = this.initialWord.toString().replace(/\,/g,"") || "";
    let responsePosition = selectedResponse + 1 || 0;
    let responseType = this.trials[this.trialIndex]['resp-list'].resp[selectedResponse]['@type'] || "";
    let target = this.trials[this.trialIndex].word['#text'] || "";
    let response = this.submitResponse(selectedResponseText, foil, target, responsePosition, responseType);

    // Show the user the response they selected for one second before going to the next trial
    this.reusableTimer = window.setTimeout(() => {
      // Run through the task with the next trial in the curriculum
      this.taskService.answerTrial(response.correct) ;
      this.reusableTimer = window.setTimeout(() => {
        if (this.trialIndex < this.trials.length - 1) {
          this.trialIndex += 1;
          this.displayTask();
        } else {
          let currentDestination = this.studentDataService.getCurrentDestination();

          // Keep track of total points locally
          this.studentDataService.setTotalPoints(currentDestination, this.totalTaskPoints, this.trials.length, this.numberOfCorrectTrials);
          this.saveTrialData();
        }
      }, 1000);
    }, 1000);
  }
}
