import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LevelSelectComponent } from './world-navigation/level-select/level-select.component';
import { LevelSelectResolver } from './world-navigation/level-select/level-select.resolver';
import { LoginComponent } from './login/login/login.component';
import { ChangeTheBlankComponent } from './tasks/change-the-blank/change-the-blank.component';
import { ChangeTheWordInterventionComponent } from './tasks/change-the-word-intervention/change-the-word-intervention.component';
import { FillInBlankComponent } from './tasks/fill-in-blank/fill-in-blank.component';
import { FindThePictureInterventionComponent } from './tasks/find-the-picture-intervention/find-the-picture-intervention.component';
import { FindThePictureComponent } from './tasks/find-the-picture/find-the-picture.component';
import { FindTheRhymeComponent } from './tasks/find-the-rhyme/find-the-rhyme.component';
import { PassagesReadAndListenComponent } from './tasks/passages-read-and-listen/passages-read-and-listen.component';
import { SpellTheWordInterventionComponent } from './tasks/spell-the-word-intervention/spell-the-word-intervention.component';
import { VerifyTheBlankInterventionComponent } from './tasks/verify-the-blank-intervention/verify-the-blank-intervention.component';
import { VerifyTheBlankComponent } from './tasks/verify-the-blank/verify-the-blank.component';
import { TimeupComponent } from './timeup/timeup.component';
import { WorldComponent } from './world-navigation/world/world.component';
import { WorldResolver } from './world-navigation/world/world.resolver';
import { TaskSelectComponent } from './world-navigation/task-select/task-select.component';
import { TaskSelectResolver } from './world-navigation/task-select/task-select.resolver';
import { SpellingInterventionComponent } from './tasks/spelling-intervention/spelling-intervention.component';
import { AnswerTheQuestionInterventionComponent } from './tasks/answer-the-question-intervention/answer-the-question-intervention.component';
import { MakeTheInterventionComponent } from './tasks/make-the-intervention/make-the-intervention.component';
import { FindThePhraseInterventionComponent } from './tasks/find-the-phrase-intervention/find-the-phrase-intervention.component';
import { FillInBlankInterventionComponent } from './tasks/fill-in-blank-intervention/fill-in-blank-intervention.component';
import { FindTheRhymeInterventionComponent } from './tasks/find-the-rhyme-intervention/find-the-rhyme-intervention.component';
import { FindTheWordSyllablesInterventionComponent } from './tasks/find-the-word-syllables-intervention/find-the-word-syllables-intervention.component';
import { FindTheSyllablesInterventionComponent } from './tasks/find-the-syllables-intervention/find-the-syllables-intervention.component';
import { ChangeTheMeaningInterventionComponent } from './tasks/change-the-meaning-intervention/change-the-meaning-intervention.component';
import { AuthGuard } from './core/guards/auth.guard';
import { DemoComponent } from './demo/demo.component';
import { CleverComponent } from './login/clever/clever.component';
import { PassageBrowserBackGuard } from './core/guards/passage-browser-back.guard';
import { ClasslinkComponent } from './login/classlink/classlink.component';
import { ReadTheWordInterventionComponent } from './tasks/read-the-word-intervention/read-the-word-intervention.component';
import { SubtaskSelectComponent } from './world-navigation/subtask-select/subtask-select.component';
import { ReadTheSentenceInterventionComponent } from './tasks/read-the-sentence-intervention/read-the-sentence-intervention.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ AuthGuard ],
    children: [
      // NOTE:
      // All children go through the AuthGuard.  Any route with the
      // data attribute 'needs_auth' set to false bypasses the need
      // to be logged in.
      { path: 'login', component: LoginComponent, data: { needs_auth: false } },
      { path: 'clever/login', component: CleverComponent, data: { needs_auth: false } },
      { path: 'classlink/login', component: ClasslinkComponent, data: { needs_auth: false } },
      { path: 'demo', component: DemoComponent },
      { path: 'world', component: WorldComponent, resolve: { data: WorldResolver } },
      { path: 'levelSelect', component: LevelSelectComponent, resolve: { resolveData: LevelSelectResolver } },
      { path: 'timeUp', component: TimeupComponent },
      { path: 'taskSelect', component: TaskSelectComponent, resolve: { resolveData: TaskSelectResolver } },
      { path: 'subtaskSelect', component: SubtaskSelectComponent },
      { path: 'verifyTheBlank', component: VerifyTheBlankComponent },
      { path: 'changeTheBlank', component: ChangeTheBlankComponent },
      { path: 'fillInBlank', component: FillInBlankComponent },
      { path: 'findThePicture', component: FindThePictureComponent },
      { path: 'findTheRhyme', component: FindTheRhymeComponent },
      { path: 'answerTheQuestionIntervention', component: AnswerTheQuestionInterventionComponent },
      { path: 'verifyTheBlankIntervention', component: VerifyTheBlankInterventionComponent },
      { path: 'changeTheWordIntervention', component: ChangeTheWordInterventionComponent },
      { path: 'findThePictureIntervention', component: FindThePictureInterventionComponent },
      { path: 'passagesReadAndListen', component: PassagesReadAndListenComponent, canDeactivate: [PassageBrowserBackGuard] },
      { path: 'spellTheWordIntervention', component: SpellTheWordInterventionComponent },
      { path: 'spellingIntervention', component: SpellingInterventionComponent },
      { path: 'makeTheIntervention', component: MakeTheInterventionComponent },
      { path: 'findThePhraseIntervention', component: FindThePhraseInterventionComponent },
      { path: 'fillInBlankIntervention', component: FillInBlankInterventionComponent },
      { path: 'findTheRhymeIntervention', component: FindTheRhymeInterventionComponent },
      { path: 'findTheWordSyllablesIntervention', component: FindTheWordSyllablesInterventionComponent },
      { path: 'findTheSyllablesIntervention', component: FindTheSyllablesInterventionComponent },
      { path: 'changeTheMeaningIntervention', component: ChangeTheMeaningInterventionComponent },
      { path: 'readTheWordIntervention', component: ReadTheWordInterventionComponent },
      { path: 'readTheSentenceIntervention', component: ReadTheSentenceInterventionComponent},

      // Redirect to the login screen if there is no path match.
      { path: '**', redirectTo: '/login', pathMatch: 'full' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
